import React, { useState } from 'react';
import Inventory from '../Components/Inventory'; // Ensure the path is correct
import NonInventory from '../Components/NonInventory'; // Ensure the path is correct
import Services from '../Components/Services'; // Ensure the path is correct
import Bundle from '../Components/Bundle'; // Ensure the path is correct

function AddProduct() {
  const [activeTab, setActiveTab] = useState('inventory'); // Default active tab

  // Function to render the active component
  const renderActiveTab = () => {
    switch (activeTab) {
      case 'inventory':
        return <Inventory />; // This will render your Inventory component
      case 'non-inventory':
        return <NonInventory />;
      case 'services':
        return <Services />;
      case 'bundle':
        return <Bundle />;
      default:
        return null;
    }
  };

  return (
    <div>
      {/* Header */}
      <h2 style={styles.heading}>Add Product</h2>
      
      {/* Tabs */}
      <div style={styles.tabContainer}>
        <button
          style={activeTab === 'inventory' ? { ...styles.tab, ...styles.activeTab } : styles.tab}
          onClick={() => setActiveTab('inventory')}
        >
          Inventory
        </button>
        <button
          style={activeTab === 'non-inventory' ? { ...styles.tab, ...styles.activeTab } : styles.tab}
          onClick={() => setActiveTab('non-inventory')}
        >
          Non-Inventory
        </button>
        <button
          style={activeTab === 'services' ? { ...styles.tab, ...styles.activeTab } : styles.tab}
          onClick={() => setActiveTab('services')}
        >
          Services
        </button>
        <button
          style={activeTab === 'bundle' ? { ...styles.tab, ...styles.activeTab } : styles.tab}
          onClick={() => setActiveTab('bundle')}
        >
          Bundle
        </button>
      </div>

      {/* Render the active tab component */}
      <div style={styles.contentContainer}>
        {renderActiveTab()}
      </div>
    </div>
  );
}

// Inline styles for tabs
const styles = {
  heading: {
    color: '#007bff', // Blue color for the heading
    marginBottom: '20px',
    fontWeight: '600',
    textAlign: 'left', // Align the text to the left
  },
  tabContainer: {
    display: 'flex',
    borderBottom: '2px solid #ccc', // Adds a bottom border to create separation
    marginBottom: '20px',
  },
  tab: {
    flex: 1,
    padding: '15px 20px',
    cursor: 'pointer',
    backgroundColor: '#f1f1f1',
    border: '1px solid #ccc',
    borderBottom: 'none',
    borderRadius: '5px 5px 0 0',
    textAlign: 'center',
    transition: 'all 0.3s ease',
    fontSize: '16px',
    fontWeight: '500',
    color: '#555',
  },
  activeTab: {
    backgroundColor: '#ffffff', // White background for the active tab
    color: '#007bff', // Active tab text color
    borderBottom: '2px solid #007bff', // Adds a blue border to highlight the active tab
    fontWeight: '600', // Make the text bolder
  },
 
};

export default AddProduct;
