import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ProfileInfo from './ProfileInfo';
import Projects from './Projects';
import TaskInfo from './TaskInfo';
import LeaveInfo from './LeaveInfo';
import Permission from './Permission'; // Import the Permission component
import './Profile.css'; // Import the CSS file

const Profile = () => {
  const { userId } = useParams(); // Extracting userId from the URL
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No token found');
        setError('No token found');
        return;
      }

      try {
        const response = await fetch(`https://api.myntask.com/auth/employee_info/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setProfileData(data);
      } catch (err) {
        console.error('Error fetching profile data:', err);
        setError('Failed to fetch profile data. Please try again later.');
      }
    };

    if (userId) {
      fetchProfileData();
    }
  }, [userId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleEditClick = () => {
    alert('Edit button clicked!');
  };

  if (error) {
    return <div className="profile-container"><p className="error-message">{error}</p></div>;
  }

  if (!profileData) {
    return <div className="profile-container"><p>Loading...</p></div>;
  }

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1 className="title">Team Member Detail</h1>
      </div>
      
      <Box className="tabs-container" sx={{ borderBottom: 0 }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="profile tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Profile Info" />
          <Tab label="Projects" />
          <Tab label="Tasks" />
          <Tab label="Leaves" />
          <Tab label="Documents" />
          <Tab label="Permission" />
        </Tabs>
      </Box>

      <div className="tab-content">
        {tabIndex === 0 && (
          <ProfileInfo
            info={profileData.info}
            Appreciation={profileData.Appreciation}
            Tickets={profileData.Tickets}
            late_attendance={profileData.late_attendance}
            leave_taken={profileData.leave_taken}
            total_task={profileData.total_task}
            total_project={profileData.total_project}
            handleEditClick={handleEditClick}
            userId={userId} // Pass userId as a prop to ProfileInfo
          />
        )}
        {tabIndex === 1 && (
          <Projects userId={userId} />  
        )}
        {tabIndex === 2 && (
          <TaskInfo userId={userId} />  
        )}
        {tabIndex === 3 && (
          <LeaveInfo userId={userId} />  
        )}
        {tabIndex === 5 && (
          <Permission userId={userId} />  
        )}
      </div>
    </div>
  );
};

export default Profile;
