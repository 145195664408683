import React, { useEffect, useState } from 'react';

function NoticeBoard() {
  const [notices, setNotices] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchNotices = async () => {
      const token = localStorage.getItem('authToken');

      try {
        const response = await fetch('https://api.myntask.com/notice/list', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch notices');
        }

        const data = await response.json();
        setNotices(data);
      } catch (error) {
        console.error('Error fetching notices:', error);
      }
    };

    fetchNotices();
  }, []);

  const handleViewClick = async (id) => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch(`https://api.myntask.com/notice/notice_view/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch notice details');
      }

      const data = await response.json();
      setSelectedNotice(data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching notice details:', error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNotice(null);
  };

  return (
    <div className='main-container'>
       <div className="top-controls">
        <h1 className='title'>Notice Board
          <div className="underline"></div>
        </h1>
      </div>
      <table >
        <thead>
          <tr>
          <th>Heading</th>
            <th >Date</th>     
            <th >Actions</th>
          </tr>
        </thead>
        <tbody>
          {notices.map(notice => (
            <tr key={notice.id}>
               <td>{notice.heading}</td>
              <td style={{textAlign:'center'}}>{notice.date}</td>
              <td style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <button onClick={() => handleViewClick(notice.id)} 
                  style={{color:'#3b82f6',backgroundColor:'transparent'}}>View</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && selectedNotice && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            <h2>{selectedNotice.heading}</h2>
            <p>{selectedNotice.date}</p>
            <p>{selectedNotice.description}</p>
            <button onClick={closeModal} style={closeButtonStyle}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default NoticeBoard;


const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  width: '500px',  // Increase width for better content space
  maxWidth: '90%',
  maxHeight: '80%',  // Restrict max height
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  overflowY: 'auto',  // Add vertical scroll if content overflows
};

const closeButtonStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'block',
  width: '100px',
  margin: '20px auto 0 auto',  // Center the button
};
