// src/components/CurrencySetting.js
import React, { useEffect, useState } from 'react';
import './CurrencySetting.css';
import { Button, Modal, Box, Typography, TextField } from '@mui/material';

const CurrencySetting = () => {
  const [currencySettings, setCurrencySettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [currentSetting, setCurrentSetting] = useState(null);

  useEffect(() => {
    const fetchCurrencySettings = async () => {
      try {
        const response = await fetch('https://api.myntask.com/setting/currency_setting');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCurrencySettings(data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch currency settings.');
        setLoading(false);
      }
    };

    fetchCurrencySettings();
  }, []);

  const handleOpenAdd = () => {
    setCurrentSetting({
      currency_name: '',
      currency_symbol: '',
      currency_code: '',
      exchange_rate: '',
      currency_position: '',
      thousand_separation: '',
      decimal_separation: '',
      number_of_decimal: '',
    });
    setModalOpen(true);
  };

  const handleEdit = (setting) => {
    setCurrentSetting({
      ...setting,
      currency_position: setting.currency_position || '',
      thousand_separation: setting.thousand_separation || '',
      decimal_separation: setting.decimal_separation || '',
      number_of_decimal: setting.number_of_decimal || '',
    });
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://api.myntask.com/setting/delete_currency/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.text();
      console.log(responseData); // Handle success message

      // Fetch the updated currency settings
      const updatedResponse = await fetch('https://api.myntask.com/setting/currency_setting');
      const updatedCurrencySettings = await updatedResponse.json();
      setCurrencySettings(updatedCurrencySettings);
    } catch (error) {
      console.error('Failed to delete currency setting:', error);
      setError('Failed to delete currency setting.');
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentSetting(null);
  };

  const handleSave = async () => {
    const payload = {
      currency_name: currentSetting.currency_name,
      currency_symbol: currentSetting.currency_symbol,
      currency_code: currentSetting.currency_code,
      exchange_rate: currentSetting.exchange_rate,
      usd_price: '',
      is_cryptocurrency: 'no',
      currency_position: currentSetting.currency_position,
      no_of_decimal: currentSetting.number_of_decimal,
      thousand_separator: currentSetting.thousand_separation,
      decimal_separator: currentSetting.decimal_separation,
    };

    try {
      const response = await fetch('https://api.myntask.com/setting/currency_save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.text();
      console.log(responseData); // Handle success message

      // Fetch the updated currency settings
      const updatedResponse = await fetch('https://api.myntask.com/setting/currency_setting');
      const updatedCurrencySettings = await updatedResponse.json();
      setCurrencySettings(updatedCurrencySettings);

      setModalOpen(false);
      setCurrentSetting(null);
    } catch (error) {
      console.error('Failed to save currency setting:', error);
      setError('Failed to save currency setting.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container">
      <Typography variant="h4" className="title">Currency Settings</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenAdd}
        className="add-currency-button"
      >
        Add Currency
      </Button>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Currency Name</th>
              <th>Currency Symbol</th>
              <th>Currency Code</th>
              <th>Exchange Rate</th>
              <th>Currency Position</th>
              <th>Thousand Separations</th>
              <th>Decimal Separation</th>
              <th>Number of Decimal</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currencySettings.map((setting) => (
              <tr key={setting.id}>
                <td>{setting.id}</td>
                <td>{setting.currency_name}</td>
                <td>{setting.currency_symbol}</td>
                <td>{setting.currency_code}</td>
                <td>{setting.exchange_rate}</td>
                <td>{setting.currency_position}</td>
                <td>{setting.thousand_separation}</td>
                <td>{setting.decimal_separation}</td>
                <td>{setting.number_of_decimal}</td>
                <td className="action-buttons">
                  <Button variant="contained" color="primary" onClick={() => handleEdit(setting)}>Edit</Button>
                  <Button variant="contained" color="secondary" onClick={() => handleDelete(setting.id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="edit-modal-title" variant="h6" component="h2">
            {currentSetting?.id ? 'Edit Currency Setting' : 'Add Currency Setting'}
          </Typography>
          {currentSetting && (
            <div>
              <TextField
                margin="normal"
                fullWidth
                label="Currency Name"
                value={currentSetting.currency_name}
                onChange={(e) => setCurrentSetting({ ...currentSetting, currency_name: e.target.value })}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Currency Symbol"
                value={currentSetting.currency_symbol}
                onChange={(e) => setCurrentSetting({ ...currentSetting, currency_symbol: e.target.value })}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Currency Code"
                value={currentSetting.currency_code}
                onChange={(e) => setCurrentSetting({ ...currentSetting, currency_code: e.target.value })}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Exchange Rate"
                value={currentSetting.exchange_rate}
                onChange={(e) => setCurrentSetting({ ...currentSetting, exchange_rate: e.target.value })}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Currency Position"
                value={currentSetting.currency_position}
                onChange={(e) => setCurrentSetting({ ...currentSetting, currency_position: e.target.value })}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Thousand Separations"
                value={currentSetting.thousand_separation}
                onChange={(e) => setCurrentSetting({ ...currentSetting, thousand_separation: e.target.value })}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Decimal Separation"
                value={currentSetting.decimal_separation}
                onChange={(e) => setCurrentSetting({ ...currentSetting, decimal_separation: e.target.value })}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Number of Decimal"
                value={currentSetting.number_of_decimal}
                onChange={(e) => setCurrentSetting({ ...currentSetting, number_of_decimal: e.target.value })}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save
                </Button>
                <Button variant="contained" color="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CurrencySetting;
