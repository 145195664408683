import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import speedLogo from '../assets/speed.png'; // Import the speed logo
import bevelImage from '../assets/bevel.png'; // Import the bevel image for promotion

import './Login.css'; // Import the CSS for styling

const Login = () => {
  const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState(''); // New state for username
  const [companyName, setCompanyName] = useState(''); // New state for company name
  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility


  useEffect(() => {
    // This effect runs on component mount
    const handlePopState = () => {
      // Prevent navigating back to the login page
      window.history.pushState(null, document.title, window.location.href);
    };
  
    // Push an initial state to prevent back navigation
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handlePopState);
  
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);  
  
  const handleLogin = async () => {
    if (!email || !password) {
      setError('Please enter both email and password.');
      return;
    }
    try {
      const response = await fetch('https://api.myntask.com/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Login successful:', data);
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('is_superadmin', data.is_superadmin);
      if (data.profile) {
        localStorage.setItem('userProfile', JSON.stringify(data.profile));
      }
      localStorage.setItem('taskCount', JSON.stringify(data.task_count));
      localStorage.setItem('workFromHome', JSON.stringify(data.work_from_home_today));
      localStorage.setItem('projectCount', JSON.stringify(data.project_count));
      localStorage.setItem('todayJoinings', JSON.stringify(data.today_joinings));
      localStorage.setItem('leaveToday', JSON.stringify(data.leave_today || []));
      localStorage.setItem('birthdays', JSON.stringify(data.birthdays || []));
      localStorage.setItem('probation', JSON.stringify(data.probation_data || []));
      localStorage.setItem('taskList', JSON.stringify(data.task_list || []));
      localStorage.setItem('notice', JSON.stringify(data.notice_list || []));
      localStorage.setItem('expenses', JSON.stringify(data.expenses || []));
      localStorage.setItem('attendanceStatus', JSON.stringify(data.attendance_status));
      if (data.is_superadmin === 1) {
        navigate('/SuperAdmin', { state: { data } });
      } else if (data.is_superadmin === 2) {
        navigate('/dashboardclient', { state: { data } });
      } else {
        navigate('/dashboard', { state: { data } });
      }
      
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Failed to log in. Please check your credentials and try again.');
    }
  };
   
  
  const handleSignUp = () => {
    if (!email || !password || !confirmPassword || !username || !companyName) {
      setError('Please fill in all fields.');
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    // Add the sign-up logic here
    setIsSignUp(false);
  };

  const switchToSignUp = () => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setUsername(''); // Clear the username field
    setCompanyName(''); // Clear the company name field
    setError('');
    setIsSignUp(true);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible); // Toggle visibility state
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="left-side">
          <h1 className="login-title">Login ✌️</h1>
          <p className="login-subtitle">Connect, manage, and grow — all with one CRM.</p>
          <input
            type="email"
            placeholder="Email"
            className="textField"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="passwordFieldContainer">
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Enter your Password"
              className="textField"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span onClick={togglePasswordVisibility} className="eyeIcon">
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {error && <p className="error">{error}</p>}
          <a href="#" className="forgotPasswordLink">Forgot Password?</a>
          <button className="button" onClick={handleLogin}>Login</button>
        </div>
        <div className="right-side">
          <img className="promotion-image-centered" src={bevelImage} alt="Bevel" />
        </div>
      </div>
    </div>
  );
};

export default Login;
