import React from 'react';
import { useLocation } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import './SuperAdmin.css';
import TotalCompany from './../assets/TotalCompany.jpeg';
import Active from './../assets/active.jpeg';
import Inactive from './../assets/inactive.jpeg';
import License from './../assets/license.jpeg';
import Package from './../assets/checklist.jpeg';


// Registering the required ChartJS components
ChartJS.register(Title, Tooltip, Legend, ArcElement);


const SuperAdmin = () => {
  const location = useLocation();
  const { data } = location.state || {};


  console.log('Data received in SuperAdmin:', data);


  if (!data) {
    return (
      <div className="simple-screen">
        <h1>Welcome to SuperAdmin Screen!</h1>
        <p>Loading data...</p>
        <p>This is a basic React component for SuperAdmin.</p>
      </div>
    );
  }


  console.log('super_admin_data:', data.super_admin_data); // Log the structure of super_admin_data


  const renderCompaniesWithUser = (companies) => (
    <div className="table-container">
      <table className="data-table">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Total Users</th>
            <th>Employees</th>
            <th>Clients</th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company, index) => (
            <tr key={index}>
              <td style={{ display: 'flex', alignItems: 'center' }}>
                <img src={company.logo} alt={`${company.name} logo`} style={{ width: '40px', height: '20px', marginRight: '5px' }} />
                <div>{company.name}</div>
              </td>
              <td>{company.total_users}</td>
              <td>{company.employee}</td>
              <td>{company.client}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );


  const renderNewRegisteredCompanies = (companies) => (
    <div className="table-container">
      <table className="data-table">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Package</th>
            <th>Date Registered</th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company, index) => (
            <tr key={index}>
              <td style={{ display: 'flex', alignItems: 'center' }}>
                <img src={company.logo} alt={`${company.name} logo`} style={{ width: '40px', height: '20px', marginRight: '5px' }} />
                <div>{company.name}</div>
              </td>
              <td>{company.package}</td>
              <td>{company.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );


  const renderPackageCompanyCount = (packages) => (
    <div className="table-container">
      <table className="data-table">
        <thead>
          <tr>
            <th>Package Name</th>
            <th>Total Companies</th>
          </tr>
        </thead>
        <tbody>
          {packages.map((packageItem, index) => (
            <tr key={index}>
              <td>{packageItem.name}</td>
              <td>{packageItem.total_companies}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );


  // Prepare data for Pie Chart
  const pieChartData = {
    labels: [
      'Total Companies',
      'Active Companies',
      'Inactive Companies',
      'License Expired',
      'Total Packages'
    ],
    datasets: [{
      data: [
        data.super_admin_data.total_company,
        data.super_admin_data.active_company,
        data.super_admin_data.inactive_company,
        data.super_admin_data.license_expired,
        data.super_admin_data.total_package
      ],
      backgroundColor: ['#dee8e7','#fbedea','#eaf3fc','#E6E6E6','#dc9e91'],
      borderColor: '#fff',
      borderWidth: 1
    }]
  };


  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 10 // Adjust font size for the legend
          }
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.label || '';
            if (context.parsed !== null) {
              label += `: ${context.parsed}%`;
            }
            return label;
          }
        },
        bodyFont: {
          size: 10 // Adjust font size for the tooltip
        }
      }
    }
  };


  const containerColors = ['#dee8e7', '#fbedea', '#eaf3fc', '#E6E6E6', '#fbf9f0'];
  const icons = [TotalCompany, Active, Inactive, License, Package];


  return (
    <div className="simple-screen">
      <h1>Dashboard</h1>
      <div className="container-grid">
        {['Total Company', 'Active Company', 'Inactive Company', 'License Expired', 'Total Package'].map((item, index) => (
          <div className="container-item" key={index} style={{ backgroundColor: containerColors[index], display: 'flex', alignItems: 'center', padding: '10px', borderRadius: '5px' }}>
            <img src={icons[index]} alt={`${item} icon`} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
            <div>
              <p style={{ margin: 0, fontWeight: 'bold' }}>{data.super_admin_data[item.toLowerCase().replace(' ', '_')]}</p>
              <p style={{ margin: 0 }}>{item}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="side-by-side">
        <div className="side-by-side-item">
          <h3>Companies with Users:</h3>
          {renderCompaniesWithUser(data.super_admin_data.companies_with_user)}
        </div>
        <div className="side-by-side-item">
          <h3>New Registered Companies:</h3>
          {renderNewRegisteredCompanies(data.super_admin_data.new_registered_companies)}
        </div>
      </div>
      <div className="side-by-side">
        <div className="side-by-side-item">
          <h3>Package Company Count:</h3>
          {renderPackageCompanyCount(data.super_admin_data.package_company_count)}
        </div>
        <div className="side-by-side-item">
          <h3>Company Statistics:</h3>
          <div className="chart-container">
            <Pie data={pieChartData} options={pieChartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};


export default SuperAdmin;




