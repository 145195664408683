// Hello.js
import React from 'react';

function Permission() {
  return (
    <div>
      Permission
    </div>
  );
}

export default Permission;
