import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Avatar, Button, Box, Typography, Grid, Chip, Tabs, Tab, Paper, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Checkbox } from '@mui/material';

import { toast, ToastContainer } from 'react-toastify'; // For notifications
import 'react-toastify/dist/ReactToastify.css';

const TaskDetail = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const [taskDetails, setTaskDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [files, setFiles] = useState([]);
  const [subtasks, setSubtasks] = useState([]);
  const [comments, setComments] = useState([]); // State for comments
  const [modalOpen, setModalOpen] = useState(false); // For adding a subtask
  const [commentModalOpen, setCommentModalOpen] = useState(false); // For adding a comment
  const [newComment, setNewComment] = useState(''); // State for new comment
  const [members, setMembers] = useState([]); // To store employee members

  // Separate state for the modal input fields
  const [newSubtaskTitle, setNewSubtaskTitle] = useState('');
  const [newSubtaskStartDate, setNewSubtaskStartDate] = useState('');
  const [newSubtaskDueDate, setNewSubtaskDueDate] = useState('');
  const [newSubtaskAssignedTo, setNewSubtaskAssignedTo] = useState('');
  const [newSubtaskDescription, setNewSubtaskDescription] = useState('');

  // Fetch task details, files, subtasks, and comments
  useEffect(() => {
    const fetchTaskDetails = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('authToken');

        // Parallel Fetch Task Details, Files, Subtasks, Comments, and Members
        const [taskRes, fileRes, subtaskRes, commentRes, memberRes] = await Promise.all([
          fetch(`https://api.myntask.com/task/task_view/${taskId}`, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }),
          fetch(`https://api.myntask.com/task/file_list/${taskId}`, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }),
          fetch(`https://api.myntask.com/task/sub_task_list/${taskId}`, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }),
          fetch(`https://api.myntask.com/task/comments/${taskId}`, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }),
          fetch('https://api.myntask.com/employee/member', {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          }),
        ]);

        if (!taskRes.ok || !fileRes.ok || !subtaskRes.ok || !commentRes.ok || !memberRes.ok) {
          throw new Error('Failed to fetch task details or files.');
        }

        const taskData = await taskRes.json();
        const fileData = await fileRes.json();
        const subtaskData = await subtaskRes.json();
        const commentData = await commentRes.json();
        const membersData = await memberRes.json();

        console.log('Comments API response:', commentData); // Added console log for comment API response

        setTaskDetails(taskData[0]);
        setFiles(fileData);
        setSubtasks(subtaskData);
        setComments(commentData);
        setMembers(membersData);
      } catch (error) {
        console.error('Error fetching task details or files:', error);
        setError('Failed to fetch task details or files.');
      } finally {
        setLoading(false);
      }
    };

    if (taskId) {
      fetchTaskDetails();
    }
  }, [taskId]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    clearModalFields(); // Reset modal fields when modal is closed
  };

  const handleCommentModalOpen = () => {
    setCommentModalOpen(true);
  };

  const handleCommentModalClose = () => {
    setCommentModalOpen(false);
    setNewComment(''); // Clear comment field when modal is closed
  };

  const refreshSubtasks = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const subtaskResponse = await fetch(`https://api.myntask.com/task/sub_task_list/${taskId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const subtaskData = await subtaskResponse.json();
      setSubtasks(subtaskData);
    } catch (error) {
      console.error('Error refreshing subtasks:', error);
    }
  };

  const refreshComments = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const commentResponse = await fetch(`https://api.myntask.com/task/comments/${taskId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const commentData = await commentResponse.json();
      setComments(commentData);
    } catch (error) {
      console.error('Error refreshing comments:', error);
    }
  };

  const handleSaveComment = async () => {
    const token = localStorage.getItem('authToken');
    const payload = {
      task_id: taskId,
      comment: newComment,
    };

    try {
      const response = await fetch('https://api.myntask.com/task/comment_save', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error('Failed to save comment');

      toast.success('Comment saved successfully');
      refreshComments(); // Refresh comments after saving
      handleCommentModalClose(); // Close the comment modal
    } catch (error) {
      console.error('Error saving comment:', error);
      toast.error('Failed to save comment');
    }
  };

  const clearModalFields = () => {
    setNewSubtaskTitle('');
    setNewSubtaskStartDate('');
    setNewSubtaskDueDate('');
    setNewSubtaskAssignedTo('');
    setNewSubtaskDescription('');
  };

  const handleSaveSubtask = async () => {
    const token = localStorage.getItem('authToken');
    const payload = {
      task_id: taskId,
      title: newSubtaskTitle,
      start_date: newSubtaskStartDate,
      due_date: newSubtaskDueDate,
      assigned_to: newSubtaskAssignedTo,
      description: newSubtaskDescription,
    };

    try {
      const response = await fetch('https://api.myntask.com/task/sub_task_save', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error('Failed to save subtask');

      toast.success('Subtask saved successfully');
      refreshSubtasks(); // Refresh subtasks after saving
      handleModalClose(); // Close the modal
    } catch (error) {
      console.error('Error saving subtask:', error);
      toast.error('Failed to save subtask');
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return (
      <Box textAlign="center">
        <Typography color="error">{error}</Typography>
        <Button onClick={() => navigate(-1)}>Go Back</Button>
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      <ToastContainer /> {/* Toast notification container */}
      {/* Header Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" sx={styles.header}>
          Task #{taskDetails.code}
        </Typography>
        <Button variant="contained" color="primary" sx={styles.completeButton}>
          Mark As Complete
        </Button>
      </Box>

      <Typography variant="h5" gutterBottom sx={styles.taskTitle}>
        {taskDetails.task}
      </Typography>

      <Grid container spacing={3}>
        {/* Task Details Container */}
        <Grid item xs={12} md={8}>
          <Paper sx={styles.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography sx={styles.label}><strong>Project:</strong> {taskDetails.project_name}</Typography>
                <Typography sx={styles.label}><strong>Priority:</strong> {taskDetails.priority}</Typography>
                <Typography sx={styles.label}><strong>Short Code:</strong> {taskDetails.code}</Typography>
                <Typography sx={styles.label}><strong>Description:</strong> {taskDetails.description || 'N/A'}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Assigned To Section */}
                <Typography sx={styles.label}><strong>Assigned To:</strong></Typography>
                <Box display="flex" mt={1}>
                  {taskDetails.assigned_users.map((user, index) => (
                    <Box key={index} textAlign="center" mr={2}>
                      <Avatar src={user.image} alt={user.name} sx={styles.userAvatar} />
                      <Typography sx={styles.label}>{user.name}</Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Status Box */}
        <Grid item xs={12} md={4}>
          <Paper sx={styles.statusPaper}>
            <Box p={2} textAlign="center">
              <Typography sx={styles.label}><strong>Status:</strong></Typography>
              <Chip label={taskDetails.status} color="primary" />
              <Typography sx={styles.label}>Start Date: {new Date(taskDetails.start_date).toLocaleDateString()}</Typography>
              <Typography sx={{ ...styles.label, color: 'red' }}>Due Date: {new Date(taskDetails.due_date).toLocaleDateString()}</Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Tabs Section */}
      <Box mt={4}>
        <Tabs value={selectedTab} onChange={handleTabChange} sx={styles.tabs}>
          <Tab label="Files" />
          <Tab label="Sub Task" />
          <Tab label="Comment" />
        </Tabs>

        {/* Tab Content */}
        <Paper sx={styles.tabContent}>
          <Box p={3}>
            {selectedTab === 0 && (
              <Box>
                <Typography variant="h6" sx={{ mb: 2 }}>Files</Typography>
                {files.length > 0 ? (
                  files.map((file) => (
                    <Box key={file.id} sx={styles.fileCard}>
                      <Box display="flex" alignItems="center">
                        <Typography>{file.filename}</Typography>
                      </Box>
                      <Button onClick={() => window.open(file.filepath, '_blank')}>View</Button>
                    </Box>
                  ))
                ) : (
                  <Typography>No files available.</Typography>
                )}
              </Box>
            )}
            {selectedTab === 1 && (
              <Box>
                <Typography variant="h6" sx={{ mb: 2 }}>Subtasks</Typography>
                <Button onClick={handleModalOpen} variant="contained" sx={{ mb: 2 }}>
                  Add Subtask
                </Button>
                {subtasks.length > 0 ? (
                  subtasks.map((subtask) => (
                    <Box key={subtask.task_id} sx={styles.subtaskCard} display="flex" alignItems="center">
                      <Checkbox checked={subtask.completed} />
                      <Avatar src={subtask.user_image} sx={styles.subtaskAvatar} />
                      <Box ml={2}>
                        <Typography sx={styles.subtaskTitle}>
                          {subtask.title}
                        </Typography>
                        <Typography sx={styles.subtaskDueDate}>
                          Due: {new Date(subtask.due_date).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography>No subtasks available.</Typography>
                )}
              </Box>
            )}
            {selectedTab === 2 && (
              <Box>
                <Typography variant="h6" sx={{ mb: 2 }}>Comments</Typography>
                <Button onClick={handleCommentModalOpen} variant="contained" sx={{ mb: 2 }}>
                  Add Comment
                </Button>
                {comments.length > 0 ? (
                  comments.map((comment) => (
                    <Box key={comment.comment_id} sx={styles.commentCard}>
                      <Box display="flex" alignItems="center">
                        <Avatar src={comment.user_image} alt={comment.user_name} sx={styles.userAvatar} />
                        <Box ml={2}>
                          <Typography sx={{ fontWeight: 'bold' }}>{comment.user_name}</Typography>
                          <Typography dangerouslySetInnerHTML={{ __html: comment.comment }} />
                        </Box>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography>No comments available.</Typography>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </Box>

      {/* Add Comment Modal */}
      <Dialog open={commentModalOpen} onClose={handleCommentModalClose}>
        <DialogTitle>Add Comment</DialogTitle>
        <DialogContent>
          <TextField
            label="Comment"
            fullWidth
            multiline
            rows={2}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCommentModalClose}>Cancel</Button>
          <Button onClick={handleSaveComment}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Subtask Modal */}
      <Dialog open={modalOpen} onClose={handleModalClose}>
        <DialogTitle>Add New Subtask</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            fullWidth
            value={newSubtaskTitle}
            onChange={(e) => setNewSubtaskTitle(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            value={newSubtaskStartDate}
            onChange={(e) => setNewSubtaskStartDate(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Due Date"
            type="date"
            fullWidth
            value={newSubtaskDueDate}
            onChange={(e) => setNewSubtaskDueDate(e.target.value)}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Assigned To"
            select
            fullWidth
            value={newSubtaskAssignedTo}
            onChange={(e) => setNewSubtaskAssignedTo(e.target.value)}
            margin="normal"
          >
            {members.map((member) => (
              <MenuItem key={member.id} value={member.id}>
                {member.member_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={4}
            value={newSubtaskDescription}
            onChange={(e) => setNewSubtaskDescription(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={handleSaveSubtask}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Styles
const styles = {
  container: {
    padding: '30px',
    maxWidth: '100%',
    backgroundColor: '#f9f9f9',
    color: '#333',
    margin: '0 auto',
  },
  header: {
    fontWeight: 'bold',
    fontSize: '24px',
  },
  taskTitle: {
    fontWeight: '500',
    fontSize: '20px',
    color: '#444',
    marginBottom: '20px',
  },
  completeButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    fontSize: '14px',
    textTransform: 'none',
  },
  paper: {
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    height: 300,
  },
  statusPaper: {
    padding: '10px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
  },
  tabs: {
    borderBottom: '1px solid #ddd',
  },
  tabContent: {
    backgroundColor: '#fff',
    padding: '16px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  label: {
    fontSize: '16px',
    color: '#666',
  },
  fileCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
  },
  userAvatar: {
    width: 30,
    height: 30,
    borderRadius: '50%',
  },
  subtaskCard: {
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
  },
  subtaskAvatar: {
    width: 40,
    height: 40,
  },
  subtaskTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  subtaskDueDate: {
    fontSize: '14px',
    color: '#666',
  },
  commentCard: {
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
  },
};

export default TaskDetail;
