import React from 'react';

const Profile = ({ clientId }) => {
  return (
    <div>
      <h2>Profile</h2>
      <p>Client ID: {clientId}</p>
      {/* Add more profile-related details here */}
    </div>
  );
};

export default Profile;
