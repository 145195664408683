import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './LeadContact.css';


const LeadContact = () => {
 const [leads, setLeads] = useState([]);
 const [totalLeads, setTotalLeads] = useState(0);
 const [activeLeads, setActiveLeads] = useState(0);
 const [inactiveLeads, setInactiveLeads] = useState(0);
 const [error, setError] = useState('');
 const [viewMode, setViewMode] = useState('list');
 const [actionMenuVisible, setActionMenuVisible] = useState(null);
 const [members, setMembers] = useState([]);
 const [selectedMember, setSelectedMember] = useState({});
 const [leadToDelete, setLeadToDelete] = useState(null);
 const navigate = useNavigate();


 useEffect(() => {
   const fetchLeads = async () => {
     const token = localStorage.getItem('authToken');
     if (!token) {
       setError('No token found');
       return;
     }


     try {
       const response = await fetch('https://api.myntask.com/leads/list', {
         method: 'GET',
         headers: {
           'Content-Type': 'application/json',
           Authorization: `Bearer ${token}`,
         },
       });


       if (!response.ok) {
         throw new Error('Failed to fetch leads');
       }


       const data = await response.json();
       console.log('API Response for Leads:', data);
       setLeads(data);
       setTotalLeads(data.length);


       const activeCount = data.filter((lead) => lead.status === 'active').length;
       const inactiveCount = data.filter((lead) => lead.status === 'inactive').length;
       setActiveLeads(activeCount);
       setInactiveLeads(inactiveCount);
     } catch (err) {
       console.error('Error fetching leads:', err);
       setError('Failed to load leads.');
     }
   };


   const fetchMembers = async () => {
     const token = localStorage.getItem('authToken');
     if (!token) {
       setError('No token found');
       return;
     }


     try {
       const response = await fetch('https://api.myntask.com/employee/member', {
         method: 'GET',
         headers: {
           'Content-Type': 'application/json',
           Authorization: `Bearer ${token}`,
         },
       });


       if (!response.ok) {
         throw new Error('Failed to fetch members');
       }


       const data = await response.json();
       console.log('API Response for Members:', data);
       setMembers(data);
     } catch (err) {
       console.error('Error fetching members:', err);
       setError('Failed to load members.');
     }
   };


   fetchLeads();
   fetchMembers();
 }, []);


 const handleMemberChange = async (leadId, memberId) => {
   setSelectedMember((prevSelected) => ({
     ...prevSelected,
     [leadId]: memberId,
   }));


   const requestData = {
     assign_id: parseInt(memberId),
     lead_id: parseInt(leadId),
   };


   console.log('Sending data to lead_assign API:', requestData);


   const token = localStorage.getItem('authToken');
   if (!token) {
     setError('No token found');
     return;
   }


   try {
     const response = await fetch('https://api.myntask.com/leads/lead_assign', {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${token}`,
       },
       body: JSON.stringify(requestData),
     });


     if (!response.ok) {
       throw new Error('Failed to assign member');
     }


     const responseData = await response.json();
     console.log('Response from lead_assign API:', responseData);


     // Display success toast
     toast.success('You assigned successfully!', {
       onClose: () => console.log('Toast closed'),
     });
   } catch (err) {
     console.error('Error assigning member:', err);
     toast.error('Failed to assign member. Please try again.');
   }
 };


 const handleDeleteClick = (leadId) => {
   setLeadToDelete(leadId);
 };


 const confirmDeleteLead = async () => {
   if (!leadToDelete) return;


   const token = localStorage.getItem('authToken');
   if (!token) {
     setError('No token found');
     return;
   }


   try {
     const response = await fetch(`https://api.myntask.com/leads/lead_delete/${leadToDelete}`, {
       method: 'DELETE',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${token}`,
       },
     });


     if (!response.ok) {
       throw new Error('Failed to delete lead');
     }


     setLeads((prevLeads) => prevLeads.filter((lead) => lead.id !== leadToDelete));
     setLeadToDelete(null);
     toast.success('Lead deleted successfully!');
   } catch (err) {
     console.error('Error deleting lead:', err);
     toast.error('Failed to delete lead.');
   }
 };


 const toggleActionMenu = (leadId) => {
   setActionMenuVisible((prev) => (prev === leadId ? null : leadId));
 };


 return (
   <div className="lead-contact-container">
     <ToastContainer autoClose={3000} />
     <div className="stat-card total-leads-card">
       <h2>Total Leads</h2>
       <p className="stat-value">{totalLeads}</p>
     </div>
     <div className="stat-card active-leads-card">
       <h2>Active Leads</h2>
       <p className="stat-value">{activeLeads}</p>
     </div>
     <div className="stat-card inactive-leads-card">
       <h2>Inactive Leads</h2>
       <p className="stat-value">{inactiveLeads}</p>
     </div>


     <div className="top-controls">
       <h1 className="title">Leads</h1>
       <div className="buttons-row">
         {/* <button
           className="toggle-button"
           onClick={() => setViewMode((prevMode) => (prevMode === 'list' ? 'board' : 'list'))}
         >
           {viewMode === 'list' ? 'Board View' : 'List View'}
         </button> */}
         <button className="add-lead-button" onClick={() => navigate('/addLead')}>
           Add Lead
         </button>
       </div>
     </div>


     {error && <p className="error-message">{error}</p>}


     {leads.length > 0 ? (
       <table >
         <thead>
           <tr>
             <th>Company Name</th>
             <th>Client Name</th>
             <th>Client Email</th>
             <th>Contact Name</th>
             <th>Created At</th>
             <th>Assign</th>
             <th>Action</th>
           </tr>
         </thead>
         <tbody>
 {leads.map((lead) => (
   <tr
     key={lead.id}
     style={{
       backgroundColor: lead.assign_to ? '#e0f7fa' : 'transparent', // Highlight row if assign_to is present
     }}
   >
     <td>{lead.company_name || 'N/A'}</td>
     <td  onClick={() => navigate(`/leadProfile/${lead.id}`)}>
       {lead.client_name}
     </td>
     <td>{lead.client_email || 'N/A'}</td>
     <td>{lead.name}</td>
     <td>{lead.created_at}</td>
     <td>
       {lead.assign_to ? (
         <span>
           {members.find((member) => member.id === lead.assign_to)?.member_name || 'Assigned'}
         </span>
       ) : (
         <select
           value={selectedMember[lead.id] || ''}
           onChange={(e) => handleMemberChange(lead.id, e.target.value)}
         >
           <option value="">Select Member</option>
           {members.map((member) => (
             <option key={member.id} value={member.id}>
               {member.member_name}
             </option>
           ))}
         </select>
       )}
     </td>
     <td className="action-column">
       <button
         className="action-button"
         onClick={() => toggleActionMenu(lead.id)}
       >
         ⋮
       </button>
       {actionMenuVisible === lead.id && (
         <div className="action-menu">
   <div onClick={() => navigate(`/EditLeadpage/${lead.id}`)}>Edit</div>


           <div onClick={() => handleDeleteClick(lead.id)}>Delete</div>
           <div onClick={() => navigate(`/leadProfile/${lead.id}`)}>View</div>
         </div>
       )}
     </td>
   </tr>
 ))}
</tbody>


       </table>
     ) : (
       <p className="no-leads-message">No leads available</p>
     )}


     {leadToDelete && (
       <div className="delete-modal">
         <div className="modal-content">
           <p>Are you sure you want to delete this lead?</p>
           <div className="modal-actions">
             <button className="confirm-button" onClick={confirmDeleteLead}>
               Yes
             </button>
             <button className="cancel-button" onClick={() => setLeadToDelete(null)}>
               No
             </button>
           </div>
         </div>
       </div>
     )}
   </div>
 );
};


export default LeadContact;






