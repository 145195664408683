import React, { useState, useEffect } from 'react';
import './AddDealModal.css'; // Add the CSS for updated styles

const AddDealModal = ({ isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    lead_id: '',
    deal_name: '',
    pipeline_stage_id: '',
    lead_pipeline_id: '',
    value: '',
    close_date: '',
    category_id: '',
    agent_id: '',
    deal_watcher: '',
    product_id: '',
  });

  const [leads, setLeads] = useState([]);
  const [members, setMembers] = useState([]);
  const [pipelines, setPipelines] = useState([]);
  const [agents, setAgents] = useState([]);
  const [stages, setStages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async (url, setter) => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setter(data || []);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        setter([]);
      }
    };

    fetchData('https://api.myntask.com/leads/lead_dropdown', setLeads);
    fetchData('https://api.myntask.com/leads/pipeline', setPipelines);
    fetchData('https://api.myntask.com/leads/stages', setStages);
    fetchData('https://api.myntask.com/employee/member', setMembers);
    fetchData('https://api.myntask.com/leads/agent', setAgents);
    fetchData('https://api.myntask.com/leads/category', setCategories);
    fetchData('https://api.myntask.com/purchase_order/product_dropdown', setProducts);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch('https://api.myntask.com/leads/add_deal', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const responseData = await response.json();
        alert('Deal added successfully!');
        onSave(formData);
        onClose();
      } else {
        const errorData = await response.json();
        alert(`Failed to add the deal: ${errorData.message || 'Unknown error'}. Please try again.`);
      }
    } catch (error) {
      alert('Failed to add the deal due to a network error. Please try again.');
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal-content">
        <h1 className="modal-title">New Deal</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            <div className="form-field-group">
              <label className="form-label">Lead ID:</label>
              <select
                name="lead_id"
                value={formData.lead_id}
                onChange={handleChange}
                className="form-input"
                required
              >
                <option value="">Select Lead</option>
                {leads.map((lead) => (
                  <option key={lead.id} value={lead.id}>
                    {lead.client_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-field-group">
              <label className="form-label">Deal Name:</label>
              <input
                type="text"
                name="deal_name"
                value={formData.deal_name}
                onChange={handleChange}
                className="form-input"
                required
              />
            </div>
            <div className="form-field-group">
              <label className="form-label">Pipeline Stage ID:</label>
              <select
                name="pipeline_stage_id"
                value={formData.pipeline_stage_id}
                onChange={handleChange}
                className="form-input"
                required
              >
                <option value="">Select Pipeline Stage</option>
                {stages.map((stage) => (
                  <option key={stage.id} value={stage.id}>
                    {stage.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-field-group">
              <label className="form-label">Lead Pipeline ID:</label>
              <select
                name="lead_pipeline_id"
                value={formData.lead_pipeline_id}
                onChange={handleChange}
                className="form-input"
                required
              >
                <option value="">Select Lead Pipeline</option>
                {pipelines.map((pipeline) => (
                  <option key={pipeline.id} value={pipeline.id}>
                    {pipeline.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-field-group">
              <label className="form-label">Value:</label>
              <input
                type="number"
                name="value"
                value={formData.value}
                onChange={handleChange}
                className="form-input"
                step="0.01"
                required
              />
            </div>
            <div className="form-field-group">
              <label className="form-label">Close Date:</label>
              <input
                type="date"
                name="close_date"
                value={formData.close_date}
                onChange={handleChange}
                className="form-input"
                required
              />
            </div>
            <div className="form-field-group">
              <label className="form-label">Category ID:</label>
              <select
                name="category_id"
                value={formData.category_id}
                onChange={handleChange}
                className="form-input"
                required
              >
                <option value="">Select category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-field-group">
              <label className="form-label">Agent ID:</label>
              <select
                name="agent_id"
                value={formData.agent_id}
                onChange={handleChange}
                className="form-input"
                required
              >
                <option value="">Select agent</option>
                {agents.map((agent) => (
                  <option key={agent.id} value={agent.id}>
                    {agent.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-field-group">
              <label className="form-label">Deal Watcher:</label>
              <select
                name="deal_watcher"
                value={formData.deal_watcher}
                onChange={handleChange}
                className="form-input"
                required
              >
                <option value="">Select Member</option>
                {members.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.member_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-field-group">
              <label className="form-label">Product ID:</label>
              <select
                name="product_id"
                value={formData.product_id}
                onChange={handleChange}
                className="form-input"
              >
                <option value="">Select Product</option>
                {products.map((product) => (
                  <option key={product.id} value={product.id}>
                    {product.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-button-group">
            <button type="submit" className="submit-button">Save</button>
            <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default AddDealModal;
