import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

function Files({ projectId }) {
  const [open, setOpen] = useState(false); // For modal open/close state
  const [uploadedFiles, setUploadedFiles] = useState([]); // State for uploaded files
  const [fileList, setFileList] = useState([]); // State to store the list of files fetched from the API
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term

  // Function to fetch existing files
  const fetchFiles = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found.');
      toast.error('Authentication token is missing.');
      return;
    }

    const url = `https://api.myntask.com/project/files/${projectId}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch files');
      }

      const data = await response.json();
      setFileList(data);
    } catch (error) {
      console.error('Error fetching files:', error);
   
    }
  };

  // Fetch files when the component mounts
  useEffect(() => {
    fetchFiles();
  }, [projectId]);

  // Handle opening and closing the upload modal
  const handleOpenUpload = () => setOpen(true);
  const handleCloseUpload = () => {
    setOpen(false);
    setUploadedFiles([]);
  };

  // Dropzone logic for drag-and-drop file upload
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf, .jpg, .jpeg, .png', // Accepting images and PDFs
    multiple: true, // Allow multiple files
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
    },
  });

  // Handle submission of uploaded files
  const handleSubmit = async () => {
    if (uploadedFiles.length === 0) {
      toast.error('No file selected for upload');
      return;
    }

    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found.');
      toast.error("Authentication token is missing.");
      return;
    }

    // Create FormData and append the file(s) to it
    const formData = new FormData();
    formData.append('project_id', projectId);
    uploadedFiles.forEach((file) => {
      formData.append('projectfiles', file);
    });

    const url = `https://api.myntask.com/project/file_save`; // API URL

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`, // Ensure token is correct and valid
        },
        body: formData,
      });

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Error response:', response.status, response.statusText, errorResponse);
        throw new Error(`Network response was not ok. Status code: ${response.status}`);
      }

      toast.success('Files uploaded successfully!'); // Success notification
      handleCloseUpload(); // Close the modal after successful upload
      // Fetch files again to update the list
      fetchFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Error uploading file. Please try again.');
    }
  };

  // Filtered list of files based on the search term
  const filteredFiles = fileList.filter((file) =>
    file.filename.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box padding={4}>
      <Typography variant="h4" gutterBottom>
        Files
      </Typography>

      {/* Upload and Search Bar */}
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={3}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<InsertDriveFileIcon />}
          onClick={handleOpenUpload}
        >
          Upload Files
        </Button>
        <TextField
          label="Search Files"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '250px' }}
        />
      </Box>

      {/* Upload Modal with Drag-and-Drop */}
      <Dialog open={open} onClose={handleCloseUpload} maxWidth="md" fullWidth>
        <DialogTitle>Upload Files</DialogTitle>
        <DialogContent>
          <Box
            {...getRootProps()}
            style={{
              border: '2px dashed #ccc',
              borderRadius: '8px',
              padding: '20px',
              textAlign: 'center',
              height: '200px',
              cursor: 'pointer',
            }}
          >
            <input {...getInputProps()} />
            <Typography variant="body1">
              Drag & drop files here, or click to select files (PDF, JPG, JPEG, PNG)
            </Typography>
          </Box>

          {/* Display preview of selected images */}
          {uploadedFiles.length > 0 && (
            <Box style={{ marginTop: '20px' }}>
              <Typography variant="body2" gutterBottom>
                Selected files:
              </Typography>
              <Box display="flex" flexWrap="wrap" gap="10px">
                {uploadedFiles.map((file, index) => (
                  <Box
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {file.type.startsWith('image/') && (
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        style={{
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          borderRadius: '8px',
                        }}
                      />
                    )}
                    <Typography variant="body2" style={{ marginTop: '5px' }}>
                      {file.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpload}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      {/* Render the list of existing files */}
      <Grid container spacing={3} style={{ marginTop: '30px' }}>
        {filteredFiles.length > 0 ? (
          filteredFiles.map((file, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                style={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                  height: '250px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  padding: '15px',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <Box
                  style={{
                    height: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '10px',
                    backgroundColor: '#e0e0e0',
                    borderRadius: '8px',
                  }}
                >
                  <FolderIcon style={{ fontSize: '70px', color: '#3f51b5' }} />
                </Box>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      textAlign: 'center',
                      wordBreak: 'break-word',
                    }}
                  >
                    {file.filename}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    href={file.filepath}
                    target="_blank"
                    fullWidth
                  >
                    View File
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
            width="100%"
            marginTop="20px"
          >
            <Typography variant="h6" color="textSecondary">
              No files are available
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
}

export default Files;
