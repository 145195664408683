import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../Components/Main.css';
import { Pagination } from '@mui/material';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import { BiSortAlt2 } from "react-icons/bi";
import Swal from 'sweetalert2';


const Expense = () => {
 const [expenses, setExpenses] = useState([]);
 const [filteredExpenses, setFilteredExpenses] = useState([]);
 const [error, setError] = useState('');
 const [searchTerm, setSearchTerm] = useState('');
 const [currentPage, setCurrentPage] = useState(1);
 const [statusOptions, setStatusOptions] = useState([]);
 const [selectedStatuses, setSelectedStatuses] = useState({});
 const [dropdownOpenIndex, setDropdownOpenIndex] = useState(null); // To track the open dropdown
 const [selectedExpenseIds, setSelectedExpenseIds] = useState([]); // Track selected expenses
 const [bulkAction, setBulkAction] = useState(''); // Track bulk action (Edit or Delete)
 const [permissions, setPermissions] = useState({}); // Track permissions from API response
 const [startDate, setStartDate] = useState('');
 const [endDate, setEndDate] = useState('');
 const [statusFilter, setStatusFilter] = useState('');
 const [employees, setEmployees] = useState([]);
 const [projects, setProjects] = useState([]);
 const [employeeId, setEmployeeId] = useState('');
 const [projectId, setProjectId] = useState('');
 const [sortField, setSortField] = useState(""); // Field to sort
 const [sortOrder, setSortOrder] = useState("asc"); // Sort order 'asc' or 'desc'
 const [sortedExpenses, setSortedExpenses] = useState([]); // Define sortedExpenses
 const [isPaidModalOpen, setIsPaidModalOpen] = useState(false); // Modal state
 const [selectedExpenseForPaid, setSelectedExpenseForPaid] = useState(null); // Store the selected expense for Paid
 const [paymentMethod, setPaymentMethod] = useState('cash');
 const [vatAmount, setVatAmount] = useState(0);
 const [vatRate, setVatRate] = useState(0);
 const [receipt, setReceipt] = useState(null);


 const PAGE_SIZE = 20;
 const navigate = useNavigate(); // Initialize the useNavigate hook


 // Fetch expenses function
 const fetchExpenses = async () => {
   const token = localStorage.getItem('authToken');
   if (!token) {
     setError('No token found');
     return;
   }


   try {
     // Fetch the list of employees
     const employeeResponse = await fetch('https://api.myntask.com/employee/member', {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${token}`,
       },
     });


     if (!employeeResponse.ok) {
       throw new Error('Failed to fetch employees');
     }


     const employees = await employeeResponse.json();
     setEmployees(employees); // Set state for employees


     // Fetch the list of projects
     const projectResponse = await fetch('https://api.myntask.com/task/project_list', {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${token}`,
       },
     });


     if (!projectResponse.ok) {
       throw new Error('Failed to fetch projects');
     }


     const projects = await projectResponse.json();
     setProjects(projects); // Set state for projects


     // Construct the query string with all filter parameters
     const queryParams = new URLSearchParams({
       start_date: startDate || '', // Use an empty string if startDate is not set
       end_date: endDate || '',     // Use an empty string if endDate is not set
       status: statusFilter || '',  // Use an empty string if statusFilter is not set
       employee_id: employeeId || '', // Use an empty string if employeeId is not set
       project_id: projectId || '',  // Use an empty string if projectId is not set
     }).toString();


     const response = await fetch(`https://api.myntask.com/expenses/list?${queryParams}`, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${token}`,
       },
     });


     if (!response.ok) {
       throw new Error('Failed to fetch expenses');
     }


     const data = await response.json();
     setExpenses(data.data);           // Set state for expenses data
     setFilteredExpenses(data.data);   // Set state for filtered expenses
     setPermissions(data.permission);  // Set state for permissions


     console.log('filtered data is', filteredExpenses);
   } catch (err) {
     console.error('Error fetching expenses:', err);
     setError('Failed to load expenses.');
   }
 };


 // Call the fetchExpenses function inside a useEffect when filters change
 useEffect(() => {
   fetchExpenses();
 }, [statusFilter, startDate, endDate, employeeId, projectId, currentPage]); // Update dependencies


 const fetchStatusOptions = async () => {
   const token = localStorage.getItem('authToken');
   if (!token) {
     setError('No token found');
     return;
   }


   try {
     const response = await fetch('https://api.myntask.com/expenses/status', {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${token}`,
       },
     });


     if (!response.ok) {
       throw new Error('Failed to fetch status options');
     }


     const data = await response.json();
     setStatusOptions(data);
   } catch (err) {
     console.error('Error fetching status options:', err);
     setError('Failed to load status options.');
   }
 };


 useEffect(() => {
   fetchExpenses();
   fetchStatusOptions();
 }, []);


 const applyFilters = () => {
   fetchExpenses(); // Re-fetch expenses with the new filter values
 };


 const handleSearchChange = (e) => {
   setSearchTerm(e.target.value);
   setCurrentPage(1); // Reset to first page on search
 };


 // Sorting function
 const handleSort = (field) => {
   const newSortOrder = sortField === field && sortOrder === "asc" ? "desc" : "asc";
   setSortField(field);
   setSortOrder(newSortOrder);
 };


 // Sorting the expenses
 useEffect(() => {
   const sorted = [...filteredExpenses].sort((a, b) => {
     let fieldA = a[sortField] || "";
     let fieldB = b[sortField] || "";


     if (sortField === 'price') {
       fieldA = parseFloat(fieldA.replace(/[^0-9.-]+/g, ""));
       fieldB = parseFloat(fieldB.replace(/[^0-9.-]+/g, ""));
     }


     if (fieldA < fieldB) return sortOrder === "asc" ? -1 : 1;
     if (fieldA > fieldB) return sortOrder === "asc" ? 1 : -1;
     return 0;
   });


   setSortedExpenses(sorted);
 }, [filteredExpenses, sortField, sortOrder]);


 // Filtering the expenses by search term
 useEffect(() => {
   if (searchTerm) {
     setFilteredExpenses(
       expenses.filter((item) =>
         (item.item_name && item.item_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
         (item.price && item.price.toString().includes(searchTerm)) ||
         (item.purchase_from && item.purchase_from.toLowerCase().includes(searchTerm.toLowerCase())) ||
         (item.project_name && item.project_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
         (item.purchase_date && item.purchase_date.toLowerCase().includes(searchTerm.toLowerCase())) ||
         (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
         (item.status && item.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
         (item.designation_name && item.designation_name.toLowerCase().includes(searchTerm.toLowerCase()))
       )
     );
   } else {
     setFilteredExpenses(expenses);
     console.log('filtered search data is', filteredExpenses);
   }
 }, [searchTerm, expenses]);


 // Pagination logic
 const handlePageChange = (event, value) => {
   setCurrentPage(value);
 };


 const totalPages = Math.ceil(sortedExpenses.length / PAGE_SIZE);
 const paginatedExpenses = sortedExpenses.slice(
   (currentPage - 1) * PAGE_SIZE,
   currentPage * PAGE_SIZE
 );


 const handleStatusChange = async (expenseId, newStatus) => {
  const token = localStorage.getItem('authToken');

  console.log('expense id is', expenseId);

  if (!token) {
    setError('No token found');
    Swal.fire('Error', 'No token found', 'error');
    return;
  }

  try {
    const requestBody = {
      expense_id: expenseId,
      status: newStatus,
    };

    const response = await fetch('https://api.myntask.com/expenses/status_update', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error('Failed to update status');
    }

    // Show success alert
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Status updated successfully',
      timer: 2000,
      showConfirmButton: false,
    });

    fetchExpenses(); // Re-fetch the expenses after a successful update
  } catch (err) {
    console.error('Error updating status:', err);
    setError('Failed to update status.');
    
    // Show error alert
    Swal.fire('Error', 'Failed to update status', 'error');
  }
};



 const handlePaidClick = (expenseId) => {
   setSelectedExpenseForPaid(expenseId); // Store the selected expense
   setIsPaidModalOpen(true); // Open the modal
 };


 const handleClosePaidModal = () => {
   setIsPaidModalOpen(false); // Close the modal
 };


 const handleReceiptChange = (e) => {
   setReceipt(e.target.files[0]); // Store uploaded receipt file
 };


 const handleConfirmPaid = async () => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    Swal.fire('Error', 'No token found', 'error');
    return;
  }

  try {
    const formData = new FormData();
    formData.append('expense_id', selectedExpenseForPaid);
    formData.append('payment_method', paymentMethod);
    formData.append('vat_amount', vatAmount);
    formData.append('vat_rate', vatRate);
    formData.append('status', 'paid');
    
    if (receipt) {
      formData.append('receipt', receipt);
    }

    const response = await fetch('https://api.myntask.com/expenses/expense_paid', {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to mark as paid');
    }

    // Show success alert
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Marked as paid successfully',
      timer: 2000,
      showConfirmButton: false,
    });

    fetchExpenses(); // Re-fetch the expenses after marking as paid
  } catch (err) {
    // Show error alert
    Swal.fire('Error', 'Failed to mark as paid', 'error');
  } finally {
    setIsPaidModalOpen(false); // Close the modal
  }
};



 const handleDeleteExpense = async (expenseId) => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    setError('No token found');
    Swal.fire('Error', 'No token found', 'error');
    return;
  }

  // Ask for confirmation using SweetAlert before deleting
  const confirmDelete = await Swal.fire({
    title: 'Are you sure?',
    text: 'Do you really want to delete this expense?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel',
    reverseButtons: true
  });

  // If user cancels, exit the function
  if (!confirmDelete.isConfirmed) return;

  try {
    const response = await fetch('https://api.myntask.com/expenses/delete', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ expense_id: expenseId }),
    });

    if (!response.ok) {
      throw new Error('Failed to delete expense');
    }

    // Update the expense lists
    setExpenses((prevExpenses) => prevExpenses.filter((expense) => expense.expenses_id !== expenseId));
    setFilteredExpenses((prevFiltered) => prevFiltered.filter((expense) => expense.expenses_id !== expenseId));

    // Show success alert
    Swal.fire('Deleted!', 'Expense has been deleted successfully.', 'success');
  } catch (err) {
    console.error('Error deleting expense:', err);
    setError('Failed to delete expense.');
    Swal.fire('Error', 'Failed to delete expense. Please try again.', 'error');
  }
};


 // Handling changes to the checkbox selection
 const handleCheckboxChange = (expenseId) => {
   setSelectedExpenseIds((prevSelected) =>
     prevSelected.includes(expenseId)
       ? prevSelected.filter((id) => id !== expenseId)
       : [...prevSelected, expenseId]
   );
 };


 // Handle change in bulk action selection
 const handleBulkActionChange = (event) => {
   const newAction = event.target.value;
   console.log('Selected bulk action:', newAction); // Debugging statement
   setBulkAction(newAction);
 };


 // Apply selected bulk action to all selected expense IDs
 const handleApplyBulkAction = () => {
   console.log('Bulk action:', bulkAction); // Debugging statement
   console.log('Selected expense IDs:', selectedExpenseIds); // Debugging statement


   if (!bulkAction) {
     alert('Please select an action to apply.');
     return;
   }


   selectedExpenseIds.forEach((expenseId) => {
     handleActionClick(bulkAction.toLowerCase(), expenseId);
   });
 };


 // Function to handle individual actions
 const handleActionClick = (action, expenseId) => {
   console.log(`Handling action: ${action} for expense ID: ${expenseId}`); // Debugging statement


   if (action === 'view') {
     navigate(`/view-expense/${expenseId}`);
   } else if (action === 'edit') {
     navigate(`/edit-expense/${expenseId}`);
   } else if (action === 'delete') {
     handleDeleteExpense(expenseId);
   }
 };


 // Toggle dropdown for individual actions
 const toggleDropdown = (index) => {
   console.log('Toggling dropdown for index:', index); // Debugging statement
   setDropdownOpenIndex((prevIndex) => (prevIndex === index ? null : index));
 };


 const statusColors = {
   'pending': 'orange',
   'approved': 'blue',
   'rejected': 'red',
   'paid': 'green', // Example color for 'paid'
   // Add more statuses and colors as needed
 };


 const handleItemNameClick = (expenseId) => {
   navigate(`/view-expense/${expenseId}`);
 };


 // Function to convert table data to CSV format
 const convertToCSV = (data) => {
   const headers = [
     'Employee Name',
     'Item Name',
     'Price',
     'Purchase From',
     'Purchase Date',
     'Project',
     'Status',
   ];


   const rows = data.map((item) => [
     item.name,
     item.item_name,
     item.price,
     item.purchase_from,
     item.purchase_date,
     item.project_name,
     item.status,
   ]);


   const csvContent = [
     headers.join(','), // Add headers
     ...rows.map((row) => row.join(',')), // Add rows
   ].join('\n'); // Join each row with newline character


   return csvContent;
 };


 // Function to trigger CSV download
 const handleExport = () => {
   const csvData = convertToCSV(filteredExpenses);
   const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
   const link = document.createElement('a');
   link.href = URL.createObjectURL(blob);
   link.download = 'expenses.csv';
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link); // Remove the link element after the download
 };


 return (
   <div className='main-container'>
     <ToastContainer />
     <div className="top-controls">
       <h1 className='title'>Expense List
         <div className="underline"></div>
       </h1>
       <div className="buttons-row">
         {selectedExpenseIds.length > 0 && (
           <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
             <select onChange={handleBulkActionChange} value={bulkAction} className="color-button">
               <option value="">Select Action</option>
               {permissions.edit_expenses === "all" && <option value="edit">Edit</option>}
               {permissions.delete_expenses === "all" && <option value="delete">Delete</option>}
             </select>
             <button onClick={handleApplyBulkAction} className="color-button">
               Apply
             </button>
           </div>
         )}
         {permissions.add_expenses === "all" && (
           <button className="color-button" onClick={() => navigate('/add-expense')}>
             Add Expense
           </button>
         )}
       </div>
     </div>


     <div className="top-controls">
       <div className="filters-and-search">
         <button className="border-button" onClick={handleExport}>
           Export
         </button>
         <input
           type="date"
           value={startDate}
           className="border-button"
           onChange={(e) => {
             setStartDate(e.target.value);
             applyFilters();
           }}
         />
         <input
           type="date"
           value={endDate}
           onChange={(e) => {
             setEndDate(e.target.value);
             applyFilters();
           }}
           className="border-button"
         />
         <input
           type="text"
           placeholder="Search..."
           value={searchTerm}
           onChange={handleSearchChange}
           className="searchbar"
         />
       </div>
     </div>


     {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}


     <table>
       <thead>
         <tr>
           <th>Select</th>
           <th>
             <div>
               <select
                 className="filter-button"
                 onChange={(e) => setEmployeeId(e.target.value)} // Update state for employee filter
                 value={employeeId}
               >
                 <option value="">Employees</option>
                 {employees.map((employee) => (
                   <option key={employee.id} value={employee.id}>
                     {employee.member_name}
                   </option>
                 ))}
               </select>
             </div>
           </th>
           <th >
             Item Name
             <BiSortAlt2
               style={{ cursor: 'pointer', marginLeft: '2px' }}
               onClick={() => handleSort('item_name')}
             />
           </th>
           <th onClick={() => handleSort('price')}>
             Amount
             <BiSortAlt2 style={{ cursor: 'pointer', marginLeft: '2px' }} />
           </th>
           <th>
             Purchase From
             <BiSortAlt2 style={{ cursor: 'pointer', marginLeft: '2px' }} />
           </th>
           <th>
             Purchased on
             <BiSortAlt2 style={{ cursor: 'pointer', marginLeft: '2px' }} />
           </th>
           <th>
             <div>
               <select
                 className="filter-button"
                 onChange={(e) => setProjectId(e.target.value)} // Update state for project filter
                 value={projectId}
               >
                 <option value=""> Projects</option>
                 {projects.map((project) => (
                   <option key={project.id} value={project.id}>
                     {project.project_name}
                   </option>
                 ))}
               </select>
             </div>
           </th>
           <th>
             <div>
               <select
                 className="filter-button"
                 onChange={(e) => setStatusFilter(e.target.value)} // Directly update state
                 value={statusFilter}
               >
                 <option value="">Status</option>
                 {statusOptions.map((statusOption) => (
                   <option key={statusOption} value={statusOption}>
                     {statusOption.charAt(0).toUpperCase() + statusOption.slice(1)}
                   </option>
                 ))}
               </select>
             </div>
           </th>
           <th>Action</th>
         </tr>
       </thead>
       <tbody>
         {paginatedExpenses.map((item, index) => (
           <tr key={item.expenses_id}>
             <td>
               <input
                 type="checkbox"
                 checked={selectedExpenseIds.includes(item.expenses_id)}
                 onChange={() => handleCheckboxChange(item.expenses_id)}
               />
             </td>
             <td>
               <div style={{ display: 'flex', alignItems: 'center' }}>
                 <img
                   src={item.user_avatar || 'default-image-url.jpg'}
                   alt={item.name || 'user'}
                   className="image"
                 />
                 <div>
                   <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate(`/profile/${item.user_id}`)}>
                     {item.name || 'N/A'}
                   </span>
                   <p style={{ margin: 0, fontSize: '12px', color: '#777' }}>
                     {item.designation_name || 'N/A'}
                   </p>
                 </div>
               </div>
             </td>
             <td onClick={() => handleItemNameClick(item.expenses_id)} style={{ cursor: 'pointer', fontWeight: '600' }}>
               {item.item_name}
             </td>
             <td>{item.price}</td>
             <td>{item.purchase_from}</td>
             <td>{item.purchase_date}</td>
             <td onClick={() => navigate(`/Projectdashboard/${item.project_id}`)} style={{ cursor: 'pointer' }}>
               {item.project_name}
             </td>
             <td>
               <div style={{ display: 'flex', alignItems: 'center' }}>
                 <span className="status-dot" style={{ backgroundColor: statusColors[item.status] }}></span>
                 <select
                   value={item.status} // Directly use the current status of the item
                   onChange={(e) => handleStatusChange(item.expenses_id, e.target.value)} // Pass expense_id and new status
                   className="status-dropdown"
                 >
                   {statusOptions.map((statusOption, statusIndex) => (
                     <option key={statusIndex} value={statusOption}>
                       {statusOption.charAt(0).toUpperCase() + statusOption.slice(1)}
                     </option>
                   ))}
                 </select>
               </div>
             </td>
             <td>
               <div className="action-container">
                 <div className="action-dots" onClick={() => toggleDropdown(index)}>
                   <GridMoreVertIcon />
                 </div>
                 {dropdownOpenIndex === index && (
                   <div className="action-menu">
                     <div className="action-item" onClick={() => handleActionClick('view', item.expenses_id)}>View</div>
                     {permissions.edit_expenses === "all" && (
                       <div className="action-item" onClick={() => handleActionClick('edit', item.expenses_id)}>Edit</div>
                     )}
                     {permissions.delete_expenses === "all" && (
                       <div className="action-item" onClick={() => handleActionClick('delete', item.expenses_id)}>Delete</div>
                     )}
                     {permissions.paid_expenses === "all" && (
                       <div className="action-item" onClick={() => handlePaidClick(item.expenses_id)}>+ Paid</div>
                     )}
                   </div>
                 )}
               </div>
             </td>
           </tr>
         ))}
       </tbody>
     </table>


     <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
       <Pagination
         count={totalPages}
         page={currentPage}
         onChange={handlePageChange}
         color="primary"
         shape="rounded"
       />
     </div>


     {isPaidModalOpen && (
       <div style={{
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0,0,0,0.5)',
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         zIndex: 1000
       }}>
         <div style={{
           backgroundColor: '#fff',
           padding: '20px',
           borderRadius: '10px',
           width: '400px',
           textAlign: 'center',
           boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
         }}>
           <h2>Confirm Payment</h2>
           <p>Are you sure you want to mark this expense as paid?</p>


           <div style={{ marginBottom: '10px' }}>
             <label>Payment Method:</label>
             <select
               value={paymentMethod}
               onChange={(e) => setPaymentMethod(e.target.value)}
               style={{ marginLeft: '10px', padding: '5px' }}
             >
               <option value="cash">Cash</option>
               <option value="credit">Credit</option>
               <option value="bank_transfer">Bank Transfer</option>
             </select>
           </div>


           <div style={{ marginBottom: '10px' }}>
             <label>VAT Amount:</label>
             <input
               type="number"
               value={vatAmount}
               onChange={(e) => setVatAmount(e.target.value)}
               style={{ marginLeft: '10px', padding: '5px', width: '80px' }}
             />
           </div>


           <div style={{ marginBottom: '10px' }}>
             <label>VAT Rate:</label>
             <input
               type="number"
               value={vatRate}
               onChange={(e) => setVatRate(e.target.value)}
               style={{ marginLeft: '10px', padding: '5px', width: '80px' }}
             />
           </div>


           <div style={{ marginBottom: '10px' }}>
             <label>Upload Receipt:</label>
             <input
               type="file"
               onChange={handleReceiptChange}
               style={{ marginLeft: '10px' }}
             />
           </div>


           <button
             onClick={handleConfirmPaid}
             style={{
               backgroundColor: '#4caf50',
               color: '#fff',
               padding: '10px 20px',
               border: 'none',
               borderRadius: '5px',
               cursor: 'pointer',
               marginRight: '10px'
             }}
           >
             Yes, Mark as Paid
           </button>
           <button
             onClick={handleClosePaidModal}
             style={{
               backgroundColor: '#f44336',
               color: '#fff',
               padding: '10px 20px',
               border: 'none',
               borderRadius: '5px',
               cursor: 'pointer'
             }}
           >
             Cancel
           </button>
         </div>
       </div>
     )}
   </div>
 );
};


export default Expense;





