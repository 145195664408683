





     // src/VendorForm.js
     import React, { useState } from 'react';
     import { ToastContainer, toast } from 'react-toastify';
     import 'react-toastify/dist/ReactToastify.css';
     import './VendorForm.css'; // Import the CSS file
     import { TiSocialLinkedinCircular, TiSocialTwitter } from "react-icons/ti";
     import { SlSocialFacebook, SlSocialSkype } from "react-icons/sl";
     import { CgWebsite } from "react-icons/cg";
     
     
     
     
     
     
     const VendorForm = () => {
      const [newVendor, setNewVendor] = useState({
        name: '',
        print_name: '',
        identification: '',
        code: '',
        vendor_common: false,
        firm_status: '',
        territory: '',
        vendor_category: '',
        contact_person: '',
        image: '',
        gst: '',
        registration_date: '',
        cin: '',
        pan: '',
        distance: 0,
        gst_category: '',
        gst_suspend: false,
        goods_service: '',
        tds_on_gst: false,
        tds_applicable: false,
        billing_address: '',
        billing_country: 'India',
        billing_state: '',
        billing_city: '',
        billing_pin_code: '',
        billing_phone: '',
        billing_email: '',
        billing_longitude: '',
        billing_latitude: '',
        shipping_address: '',
        shipping_country: 'India',
        shipping_state: '',
        shipping_city: '',
        shipping_pin_code: '',
        shipping_phone: '',
        shipping_email: '',
        shipping_longitude: '',
        shipping_latitude: '',
        website: '',
        facebook: '',
        skype: '',
        twitter: '',
        linkedin: '',
        payment_term: '',
        price_category: '',
        credit_limit: 0,
        max_credit_days: 0,
        interest_rate_yearly: 0,
        rtgs_ifsc_code: '',
        account_number: '',
        bank_name: '',
        branch: '',
        attachment: '',
      });
     
     
     
     
      // Function to copy billing information to shipping information
      const copyFromBilling = () => {
        setNewVendor({
          ...newVendor,
          shipping_address: newVendor.billing_address,
          shipping_country: newVendor.billing_country,
          shipping_state: newVendor.billing_state,
          shipping_city: newVendor.billing_city,
          shipping_pin_code: newVendor.billing_pin_code,
          shipping_phone: newVendor.billing_phone,
          shipping_email: newVendor.billing_email,
          shipping_longitude: newVendor.billing_longitude,
          shipping_latitude: newVendor.billing_latitude,
        });
      };
     
     
     
     
      // Function to handle form submission
      const saveVendor = async () => {
        const token = localStorage.getItem('authToken');
     
     
     
     
        if (!token) {
          toast.error('You must be logged in to save the vendor.');
          return;
        }
     
     
     
     
        // Prepare the data to match the API specification
        const payload = {
          name: newVendor.name || '',
          code: newVendor.code || '',
          print_name: newVendor.print_name || '',
          firm_type: newVendor.firm_status || '', // Assuming firm_status represents firm_type
          territory: newVendor.territory || '',
          contact_person: newVendor.contact_person || '',
          billing_address: newVendor.billing_address || '',
          billing_country: newVendor.billing_country || '',
          billing_state: newVendor.billing_state || '',
          billing_city: newVendor.billing_city || '',
          billing_pin: newVendor.billing_pin_code || '',
          billing_phone: newVendor.billing_phone || '',
          billing_email: newVendor.billing_email || '',
          billing_longitude: newVendor.billing_longitude || '',
          billing_latitude: newVendor.billing_latitude || '',
          shipping_address_same: true, // Hardcoded as per the example
          shipping_address: newVendor.shipping_address || '',
          shipping_country: newVendor.shipping_country || '',
          shipping_state: newVendor.shipping_state || '',
          shipping_city: newVendor.shipping_city || '',
          shipping_pin: newVendor.shipping_pin_code || '',
          shipping_phone: newVendor.shipping_phone || '',
          shipping_email: newVendor.shipping_email || '',
          shipping_longitude: newVendor.shipping_longitude || '',
          shipping_latitude: newVendor.shipping_latitude || '',
          gst: newVendor.gst || '',
          registration_date: newVendor.registration_date || '',
          cin: newVendor.cin || '',
          pan: newVendor.pan || '',
          goods_service: newVendor.goods_service || '',
          vendor_common_for_sales_purchase: newVendor.vendor_common ? 1 : 0,
          payment_term: newVendor.payment_term || '',
          price_category: newVendor.price_category || '',
          sales_executive: newVendor.sales_executive || '',
          agent: newVendor.agent || '',
          transporter: newVendor.transporter || '',
          credit_limit: newVendor.credit_limit || '',
          bank_name: newVendor.bank_name || '',
          bank_branch: newVendor.branch || '',
          bank_account_no: newVendor.account_number || '',
          bank_rtgs_no: newVendor.rtgs_ifsc_code || '',
          party_category: newVendor.party_category || '',
          website: newVendor.website || '',
          facebook: newVendor.facebook || '',
          skype: newVendor.skype || '',
          twitter: newVendor.twitter || '',
          linkedin: newVendor.linkedin || '',
          allow_mobile_access: newVendor.allow_mobile_access || true,
          mobile_access_password: newVendor.mobile_access_password || 'password123',
          customer_on_watch: newVendor.customer_on_watch || false,
          is_common: newVendor.is_common || false,
          root_map: newVendor.root_map || '',
          gst_category: newVendor.gst_category || '',
          gst_suspend: newVendor.gst_suspend ? 1 : 0,
          distance: newVendor.distance || 0,
          tds_on_gst_applicable: newVendor.tds_on_gst ? 1 : 0,
          tds_applicable: newVendor.tds_applicable ? 1 : 0,
          agent_broker: newVendor.agent_broker || null,
          max_credit_days: newVendor.max_credit_days || 0,
          interest_rate_yearly: newVendor.interest_rate_yearly || 0,
        };
     
     
     
     
        // Log the token and payload to the console
        console.log('Token:', token);
        console.log('Payload:', payload);
     
     
     
     
        try {
          const response = await fetch('https://api.myntask.com/vendor/save', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          });
     
     
     
     
          // Log the full response to the console
          console.log('Response:', response);
     
     
     
     
          if (!response.ok) {
            const errorText = await response.text();
            console.error('Response Error Text:', errorText);
            throw new Error('Network response was not ok');
          }
     
     
     
     
          const data = await response.json();
          console.log('Vendor saved:', data);
          toast.success('Vendor saved successfully!');
        } catch (error) {
          console.error('Error saving vendor:', error);
          toast.error('Failed to save vendor. Please try again.');
        }
      };
     
     
      const clearForm = () => {
         setNewVendor({
           name: '',
           print_name: '',
           identification: '',
           code: '',
           vendor_common: false,
           firm_status: '',
           territory: '',
           vendor_category: '',
           contact_person: '',
           image: '',
           gst: '',
           registration_date: '',
           cin: '',
           pan: '',
           distance: 0,
           gst_category: '',
           gst_suspend: false,
           goods_service: '',
           tds_on_gst: false,
           tds_applicable: false,
           billing_address: '',
           billing_country: 'India', // Keep the default value if needed
           billing_state: '',
           billing_city: '',
           billing_pin_code: '',
           billing_phone: '',
           billing_email: '',
           billing_longitude: '',
           billing_latitude: '',
           shipping_address: '',
           shipping_country: 'India', // Keep the default value if needed
           shipping_state: '',
           shipping_city: '',
           shipping_pin_code: '',
           shipping_phone: '',
           shipping_email: '',
           shipping_longitude: '',
           shipping_latitude: '',
           website: '',
           facebook: '',
           skype: '',
           twitter: '',
           linkedin: '',
           payment_term: '',
           price_category: '',
           credit_limit: 0,
           max_credit_days: 0,
           interest_rate_yearly: 0,
           rtgs_ifsc_code: '',
           account_number: '',
           bank_name: '',
           branch: '',
           attachment: '',
         });
       };
       
     
     
      return (
        <div className="main-container">
          <div className="top-controls">
             <h1 className='title'>Vendor Form
               <div className="underline"></div>
             </h1>
           </div>
           {/* Basic Section */}
           <div className="section">
       <h2 className="section-title">Basic</h2>
       <hr className="section-divider" />
       <div className="field-container">
       <div className="field-column">
       <label>
       Name
       <input
         type="text"
         className='inputbox'
         value={newVendor.name || ''}
         onChange={(e) => setNewVendor({ ...newVendor, name: e.target.value })}
         required
       />
     </label>
     <label>
       Print Name
       <input
         type="text"
         className='inputbox'
         value={newVendor.print_name || ''}
         onChange={(e) => setNewVendor({ ...newVendor, print_name: e.target.value })}
         required
       />
     </label>
       <label>
         Identification
         <input
           type="text"
           className='inputbox'
           value={newVendor.identification || ''}
           onChange={(e) => setNewVendor({ ...newVendor, identification: e.target.value })}
         />
       </label>
      
       <label>
         Code
         <input
           type="text"
           className='inputbox'
           value={newVendor.code || ''}
           onChange={(e) => setNewVendor({ ...newVendor, code: e.target.value })}
         />
       </label>
      
       <label>
         Vendor Category
         <input
           type="text"
           className='inputbox'
           value={newVendor.vendor_category || ''}
           onChange={(e) => setNewVendor({ ...newVendor, vendor_category: e.target.value })}
         />
       </label>
       <label>
         Firm Status
         <input
           type="text"
           className='inputbox'
           value={newVendor.firm_status || ''}
           onChange={(e) => setNewVendor({ ...newVendor, firm_status: e.target.value })}
         />
       </label>
       <label>
         Contact Person
         <input
           type="text"
           className='inputbox'
           value={newVendor.contact_person || ''}
           onChange={(e) => setNewVendor({ ...newVendor, contact_person: e.target.value })}
         />
       </label>
     </div>
     
     
         <div className="field-column">
         <div style={{margin:'30px 0px'}}>
       <label>
         <input
           type="checkbox"
           style={{marginRight:'10px'}}
           checked={newVendor.vendor_common || false}
           onChange={(e) => setNewVendor({ ...newVendor, vendor_common: e.target.checked })}
           
         />
         Vendor Common for Sales Purchase
       </label>
       </div>
      
       <label>
         Territory
         <input
           type="text"
           className='inputbox'
           value={newVendor.territory || ''}
           onChange={(e) => setNewVendor({ ...newVendor, territory: e.target.value })}
         />
       </label>
           <div style={{
             width: '150px',
             height: '150px',
             backgroundColor: '#e0e0e0',
             margin: '15px 0px',
             borderRadius: '5px',
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             position: 'relative', // Added for positioning the close button
             marginTop:'30px'
           }}>
             <img
               src={newVendor.image || 'path/to/default-image.png'}
               alt="Vendor"
               style={{ maxWidth: '100%', maxHeight: '100%' }}
             />
             <button
               style={{
                 position: 'absolute',
                 top: '5px',
                 right: '5px',
                 backgroundColor: '#fff',
                 border: 'none',
                 cursor: 'pointer',
                 padding: '2px 4px',
                 borderRadius: '3px',
               }}
               onClick={() => setNewVendor({ ...newVendor, image: null })} // Clear image on click
             >
               ✕
             </button>
           </div>
           <label >
             <button className='color-button'>
               Browse
             </button>
             <input
               type="file"
               style={{ display: 'none' }}
               onChange={(e) => {
                 const file = e.target.files[0];
                 if (file) {
                   const reader = new FileReader();
                   reader.onload = () => setNewVendor({ ...newVendor, image: reader.result });
                   reader.readAsDataURL(file);
                 }
               }}
             />
           </label>
         </div>
       </div>
     </div>
     
     
           {/* Statutory Section */}
           <div className="section">
       <h2 className="section-title">Statutory</h2>
       <hr className="section-divider" />
       <div className="field-container">
         <div className="field-column">
           <label>GST</label>
           <input
             type="text"
             className='inputbox'
             value={newVendor.gst || ''}
             onChange={(e) => setNewVendor({ ...newVendor, gst: e.target.value })}
           />
     
     
           <label>Registration Date</label>
           <input
             type="date"
             className='inputbox'
             value={newVendor.registration_date || ''}
             onChange={(e) => setNewVendor({ ...newVendor, registration_date: e.target.value })}
           />
     
     
           <label>CIN</label>
           <input
             type="text"
             className='inputbox'
             value={newVendor.cin || ''}
             onChange={(e) => setNewVendor({ ...newVendor, cin: e.target.value })}
           />
     
     
           <label>PAN</label>
           <input
             type="text"
             className='inputbox'
             value={newVendor.pan || ''}
             onChange={(e) => setNewVendor({ ...newVendor, pan: e.target.value })}
           />
     
     
           <label>Distance</label>
           <input
             type="number"
             className='inputbox'
             value={newVendor.distance || ''}
             onChange={(e) => setNewVendor({ ...newVendor, distance: e.target.value })}
           />
         </div>
     
     
         <div className="field-column">
           <label>GST Category</label>
           <select
             className='inputbox'
             value={newVendor.gst_category || ''}
             onChange={(e) => setNewVendor({ ...newVendor, gst_category: e.target.value })}
           >
             <option value="">Select GST Category</option>
             <option value="Registered">Registered</option>
             <option value="Unregistered">Unregistered</option>
           </select>
     
     
           <div style={{margin:'30px 0px'}}>
           <label>
             <input
               type="checkbox"
           style={{marginRight:'10px'}}
               checked={newVendor.gst_suspend || false}
               onChange={(e) => setNewVendor({ ...newVendor, gst_suspend: e.target.checked })}
             />
             GST Suspend
           </label>
           </div>
     
     
           <label>Goods Service</label>
           <select
             className='inputbox'
             value={newVendor.goods_service || ''}
             onChange={(e) => setNewVendor({ ...newVendor, goods_service: e.target.value })}
           >
             <option value="">Select Goods/Service</option>
             <option value="Goods">Goods</option>
             <option value="Service">Service</option>
           </select>
     
     
           <div style={{margin:'30px 0px'}}>
           <label>
             <input
               type="checkbox"
           style={{marginRight:'10px'}}
               checked={newVendor.tds_on_gst || false}
               onChange={(e) => setNewVendor({ ...newVendor, tds_on_gst: e.target.checked })}
             />
             TDS On GST Applicable
           </label>
           </div>
     
     
           <div style={{margin:'30px 0px'}}>
           <label>
             <input
               type="checkbox"
           style={{marginRight:'10px'}}
               checked={newVendor.tds_applicable || false}
               onChange={(e) => setNewVendor({ ...newVendor, tds_applicable: e.target.checked })}
             />
             TDS Applicable
           </label>
           </div>
         </div>
       </div>
     </div>
     
     
     <div className="section-container">
       {/* Billing Information Section */}
       <div className="section1">
       <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
         <h2 className="section-title">Billing Information</h2>
       </div>
         <hr className="section-divider" />
         <div className="field-container1">
           <div className="field-column1">
             <label>Address</label>
             <input
               type="text"
               className="input1"
               value={newVendor.billing_address || ''}
               onChange={(e) => setNewVendor({ ...newVendor, billing_address: e.target.value })}
             />
     
     
             <label>Country</label>
             <input
               type="text"
               className="input1"
               value={newVendor.billing_country || 'India'}
               onChange={(e) => setNewVendor({ ...newVendor, billing_country: e.target.value })}
             />
     
     
             <label>State</label>
             <input
               type="text"
               className="input1"
               value={newVendor.billing_state || ''}
               onChange={(e) => setNewVendor({ ...newVendor, billing_state: e.target.value })}
               required
             />
     
     
             <label>City</label>
             <input
               type="text"
               className="input1"
               value={newVendor.billing_city || ''}
               onChange={(e) => setNewVendor({ ...newVendor, billing_city: e.target.value })}
               required
             />
     
     
             <label>Pin Code</label>
             <input
               type="text"
               className="input1"
               value={newVendor.billing_pin_code || ''}
               onChange={(e) => setNewVendor({ ...newVendor, billing_pin_code: e.target.value })}
             />
     
     
             <label>Phone</label>
             <input
               type="text"
               className="input1"
               value={newVendor.billing_phone || ''}
               onChange={(e) => setNewVendor({ ...newVendor, billing_phone: e.target.value })}
             />
     
     
             <label>Email</label>
             <input
               type="email"
               className="input1"
               value={newVendor.billing_email || ''}
               onChange={(e) => setNewVendor({ ...newVendor, billing_email: e.target.value })}
             />
     
     
             <label>Longitude</label>
             <input
               type="text"
               className="input1"
               value={newVendor.billing_longitude || ''}
               onChange={(e) => setNewVendor({ ...newVendor, billing_longitude: e.target.value })}
             />
     
     
             <label>Latitude</label>
             <input
               type="text"
               className="input1"
               value={newVendor.billing_latitude || ''}
               onChange={(e) => setNewVendor({ ...newVendor, billing_latitude: e.target.value })}
             />
           </div>
         </div>
       </div>
     
     
       {/* Shipping Information Section */}
       <div className="section1">
       <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
       <h2 className="section-title">Shipping Information</h2>
       <button className='copy-button' onClick={copyFromBilling}>
         Copy From Billing
       </button>
           </div>
         
         <hr className="section-divider" />
         <div className="field-container1">
           <div className="field-column1">
             <label>Address</label>
             <input
               type="text"
               className="input1"
               value={newVendor.shipping_address || ''}
               onChange={(e) => setNewVendor({ ...newVendor, shipping_address: e.target.value })}
             />
     
     
             <label>Country</label>
             <input
               type="text"
               className="input1"
               value={newVendor.shipping_country || 'India'}
               onChange={(e) => setNewVendor({ ...newVendor, shipping_country: e.target.value })}
             />
     
     
             <label>State</label>
             <input
               type="text"
               className="input1"
               value={newVendor.shipping_state || ''}
               onChange={(e) => setNewVendor({ ...newVendor, shipping_state: e.target.value })}
             />
     
     
             <label>City</label>
             <input
               type="text"
               className="input1"
               value={newVendor.shipping_city || ''}
               onChange={(e) => setNewVendor({ ...newVendor, shipping_city: e.target.value })}
             />
     
     
             <label>Pin Code</label>
             <input
               type="text"
               className="input1"
               value={newVendor.shipping_pin_code || ''}
               onChange={(e) => setNewVendor({ ...newVendor, shipping_pin_code: e.target.value })}
             />
     
     
             <label>Phone</label>
             <input
               type="text"
               className="input1"
               value={newVendor.shipping_phone || ''}
               onChange={(e) => setNewVendor({ ...newVendor, shipping_phone: e.target.value })}
             />
     
     
             <label>Email</label>
             <input
               type="email"
               className="input1"
               value={newVendor.shipping_email || ''}
               onChange={(e) => setNewVendor({ ...newVendor, shipping_email: e.target.value })}
             />
     
     
             <label>Longitude</label>
             <input
               type="text"
               className="input1"
               value={newVendor.shipping_longitude || ''}
               onChange={(e) => setNewVendor({ ...newVendor, shipping_longitude: e.target.value })}
             />
     
     
             <label>Latitude</label>
             <input
               type="text"
               className="input1"
               value={newVendor.shipping_latitude || ''}
               onChange={(e) => setNewVendor({ ...newVendor, shipping_latitude: e.target.value })}
             />
     
     
            
           </div>
         </div>
       </div>
     </div>
     
     
     
     
           {/* Social Profile Section */}
           <div className="section">
       <h2 className="section-title">Social Profile</h2>
       <hr className="section-divider" />
       <div className="field-container">
         <div className="field-column">
           <label>
             <CgWebsite /> Website
           </label>
           <input
             className='inputbox'
             value={newVendor.website || ''}
             onChange={(e) => setNewVendor({ ...newVendor, website: e.target.value })}
           />
     
     
           <label>
             <SlSocialFacebook /> Facebook
           </label>
           <input
             className='inputbox'
             value={newVendor.facebook || ''}
             onChange={(e) => setNewVendor({ ...newVendor, facebook: e.target.value })}
           />
     
     
           <label>
             <SlSocialSkype /> Skype
           </label>
           <input
             type="text"
             className='inputbox'
             value={newVendor.skype || ''}
             onChange={(e) => setNewVendor({ ...newVendor, skype: e.target.value })}
           />
         </div>
         <div className="field-column">
           <label>
             <TiSocialTwitter /> Twitter
           </label>
           <input
             type="text"
             className='inputbox'
             value={newVendor.twitter || ''}
             onChange={(e) => setNewVendor({ ...newVendor, twitter: e.target.value })}
           />
     
     
           <label>
             <TiSocialLinkedinCircular /> LinkedIn
           </label>
           <input
             type="text"
             className='inputbox'
             value={newVendor.linkedin || ''}
             onChange={(e) => setNewVendor({ ...newVendor, linkedin: e.target.value })}
           />
         </div>
       </div>
     </div>
     
     
           {/* Defaults Section */}
           <div className="section">
       <h2 className="section-title">Defaults</h2>
       <hr className="section-divider" />
       <div className="field-container">
       <div className="field-column">
         <label>Payment Term</label>
         <select
           className='inputbox'
           value={newVendor.payment_term || ''}
           onChange={(e) => setNewVendor({ ...newVendor, payment_term: e.target.value })}
         >
           <option value="">Select Payment Term</option>
           <option value="30 Days">30 Days</option>
           <option value="60 Days">60 Days</option>
         </select>
     
     
         <label>Price Category</label>
         <select
           className='inputbox'
           value={newVendor.price_category || ''}
           onChange={(e) => setNewVendor({ ...newVendor, price_category: e.target.value })}
         >
           <option value="">Select Price Category</option>
           <option value="Retail">Retail</option>
           <option value="Wholesale">Wholesale</option>
         </select>
     
     
         <label>Credit Limit</label>
         <input
           type="number"
           className='inputbox'
           value={newVendor.credit_limit || ''}
           onChange={(e) => setNewVendor({ ...newVendor, credit_limit: e.target.value })}
         />
     </div>
     <div className="field-column">
         <label>Max Credit Days</label>
         <input
           type="number"
           className='inputbox'
           value={newVendor.max_credit_days || ''}
           onChange={(e) => setNewVendor({ ...newVendor, max_credit_days: e.target.value })}
         />
     
     
         <label>Interest Rate Yearly</label>
         <input
           type="number"
           className='inputbox'
           value={newVendor.interest_rate_yearly || ''}
           onChange={(e) => setNewVendor({ ...newVendor, interest_rate_yearly: e.target.value })}
         />
         </div>  
       </div>
     </div>
     
     
           {/* Bank Details Section */}
           <div className="section">
       <h2 className="section-title">Bank Details</h2>
       <hr className="section-divider" />
       <div className="field-container">
       <div className="field-column">
         <label>RTGS/IFSC Code</label>
         <input
           type="text"
           className='inputbox'
           value={newVendor.rtgs_ifsc_code || ''}
           onChange={(e) => setNewVendor({ ...newVendor, rtgs_ifsc_code: e.target.value })}
         />
     
     
         <label>Account Number</label>
         <input
           type="text"
           className='inputbox'
           value={newVendor.account_number || ''}
           onChange={(e) => setNewVendor({ ...newVendor, account_number: e.target.value })}
         />
     </div>
     <div className="field-column">
         <label>Bank Name</label>
         <input
           type="text"
           className='inputbox'
           value={newVendor.bank_name || ''}
           onChange={(e) => setNewVendor({ ...newVendor, bank_name: e.target.value })}
         />
     
     
         <label>Branch</label>
         <input
           type="text"
           className='inputbox'
           value={newVendor.branch || ''}
           onChange={(e) => setNewVendor({ ...newVendor, branch: e.target.value })}
         />
       </div>
       </div>
     </div>
     
     
           {/* Attachment Section */}
           <div className="section">
       <h2 className="section-title">Attachment</h2>
       <hr className="section-divider" />
       <div className="field-container" style={{ flexDirection: 'column' }}>
         <label className="browse-button" style={{ cursor: 'pointer' }}>
           Select file
           <input
             type="file"
             hidden
             onChange={(e) => {
               const file = e.target.files[0];
               if (file) {
                 const reader = new FileReader();
                 reader.onload = () => setNewVendor({ ...newVendor, attachment: reader.result });
                 reader.readAsDataURL(file);
               }
             }}
           />
         </label>
         <span className="caption">Attachment size should not exceed 2MB.</span>
       </div>
     </div>
     
     
           {/* Action Buttons */}
           <div className="button-box">
       <button
         className="color-button"
         onClick={saveVendor}
     
     
       >
         Save
       </button>
       <button
         className="border-button"
       >
         Clear
       </button>
       <button
        className="border-button" onClick={clearForm}
       >
         Print
       </button>
       <button
         className="border-button"
       >
         Delete
       </button>
       <button
        className="border-button"
       >
         Exit
       </button>
     </div>
     
     
           <ToastContainer />
        </div>
      );
     
     
     
     
     };
     
     
     
     
     export default VendorForm;
      
     
     
     
     
       
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     
     