import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
 Box,
 Toolbar,
 Drawer,
 List,
 ListItem,
 ListItemText,
 IconButton,
 Divider,
 Collapse,
 ListItemIcon,
 Badge,
 Popover,
 Typography,
 Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import {
 FaTrello,
 FaCreditCard,
 FaCalendarDay,
 FaPowerOff,
} from 'react-icons/fa';
import { FaUsers, FaCogs, FaClipboardList, FaBriefcase, FaFileInvoice, FaEnvelope, FaTools } from 'react-icons/fa';
import { VscTasklist } from "react-icons/vsc";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import myntask from './../assets/black-white.png';


const drawerWidth = 230;


const DrawerHeader = styled('div')(({ theme }) => ({
 display: 'flex',
 alignItems: 'center',
 padding: theme.spacing(0, 1),
 ...theme.mixins.toolbar,
 justifyContent: 'flex-end',
}));


const Layout = ({ children }) => {
 const navigate = useNavigate();
 const [menuItems, setMenuItems] = useState([]);
 const [currentTime, setCurrentTime] = useState('');
 const [currentDay, setCurrentDay] = useState('');
 const [mobileOpen, setMobileOpen] = useState(false);
 const [userRole, setUserRole] = useState('');
 const [userProfile, setUserProfile] = useState({});
 const [activeItem, setActiveItem] = useState(null);
 const [openDropdown, setOpenDropdown] = useState(null);
 const [anchorEl, setAnchorEl] = useState(null);
 const [notifications, setNotifications] = useState([]);
 const [notificationCount, setNotificationCount] = useState(0);
 const [error, setError] = useState(null);



 const handleIconClick = (event) => {
   setAnchorEl(event.currentTarget);
 };




 // Function to handle closing of the popover
 const handleClose = () => {
   setAnchorEl(null);
 };




 // Check if the popover is open
 const isPopoverOpen = Boolean(anchorEl);
 
  let logoutTimeout;


const fetchNotifications = async () => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    setError('No token found. Please log in.');
    return;
  }




  try {
    const response = await fetch('https://api.myntask.com/notification/list', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });




    if (!response.ok) {
      throw new Error('Failed to fetch notifications');
    }




    const data = await response.json();
   
    // console.log('API Response notifications:', data);
    // console.log('Notification Count:', data.notificationCount);




    if (data.notifications && Array.isArray(data.notifications)) {
      setNotifications(data.notifications);
      setNotificationCount(data.notificationCount); // Update the notification count
    } else {
      setNotifications([]);
      setNotificationCount(0); // Set count to 0 if no notifications are found
    }
  } catch (error) {
    console.error('Error fetching notifications:', error);
    setError(error.message);
  }
};




const handleNotificationClick = (notification) => {
  // Get token from local storage
  const token = localStorage.getItem('authToken'); // Replace 'authToken' with the actual key if needed


  // Send PUT request to update notification status
  fetch('https://api.myntask.com/notification/status_update', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`, // Add token to Authorization header
    },
    body: JSON.stringify({ notification_id: notification.notification_id }), // Send notification ID
  })
  .then((response) => {
    if (response.ok) {
      console.log('Notification status updated successfully.');
     
      // Fetch updated notifications list after successful status update
      fetchNotifications();
    } else {
      console.error('Failed to update notification status.');
    }
  })
  .catch((error) => {
    console.error('Error updating notification status:', error);
  });


  // Navigate to the appropriate page based on the notification type
  if (notification.type === 'expense') {
    console.log(`Navigating to ViewExpense with ID: ${notification.reference_id}`);
    navigate(`/view-expense/${notification.reference_id}`); // Navigate to expense detail page
  } else if (notification.type === 'task') {
    console.log(`Navigating to TaskDetail with ID: ${notification.reference_id}`);
    navigate(`/task-detail/${notification.reference_id}`); // Navigate to task detail page
  }


  handleClose(); // Close the popover after navigating
};




// Auto-refresh notifications every 5 seconds
useEffect(() => {
  fetchNotifications(); // Fetch when the component first loads




  const intervalId = setInterval(() => {
    fetchNotifications();
  }, 2000); // Refresh every 5 seconds




  return () => clearInterval(intervalId); // Clean up interval on component unmount
}, []);






 useEffect(() => {
   const fetchMenuItems = async () => {
     const token = localStorage.getItem('authToken');
     console.log('Retrieved Token:', token);
     if (!token) {
       console.error('No token found in localStorage');
       return;
     }


     try {
       const response = await fetch('https://api.myntask.com/home/drawer_list', {
         method: 'GET',
         headers: {
           Authorization: `Bearer ${token}`,
           'Content-Type': 'application/json',
         },
       });


       const data = await response.json();
       console.log('API Response Data:', JSON.stringify(data, null, 2));
       setMenuItems(data);
     } catch (error) {
       console.error('Error fetching menu items:', error);
     }
   };


   fetchMenuItems();
 }, []);


 const handleDrawerToggle = () => {
   setMobileOpen(!mobileOpen);
 };


 const handleClick = (path) => {
   setActiveItem(path);
   navigate(path);
 };




  useEffect(() => {
    const updateCurrentTime = () => {
      const now = new Date();
      const time = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const day = now.toLocaleDateString([], { weekday: 'long', month: 'long', day: 'numeric' });
      setCurrentTime(time);
      setCurrentDay(day);
    };








    updateCurrentTime();
    const timer = setInterval(updateCurrentTime, 1000);








    return () => clearInterval(timer);
  }, []);




  // Function to handle "View All" click
  const handleViewAll = () => {
    handleClose(); // Close the popover before navigating
    navigate('/NotificationScreen'); // Navigate to NotificationScreen route
  };


  useEffect(() => {
    // Fetch user role from localStorage
    const role = localStorage.getItem('is_superadmin');
    setUserRole(role);




    const storedProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
    setUserProfile(storedProfile);
   
  }, []);




  useEffect(() => {
    const handleUserActivity = () => {
      // Clear existing timeout if user is active
      clearTimeout(logoutTimeout);


      // Set a new timeout for 1 hour (3600000 ms)
      logoutTimeout = setTimeout(() => {
        handleAutomaticLogout(); // Trigger automatic logout
      }, 3600000); // 1 hour
    };


    // Listen for user activity events
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
   
    // Clean up event listeners on unmount
    return () => {
      clearTimeout(logoutTimeout);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
    };
  }, []);


  const handleAutomaticLogout = () => {
    // Automatically log the user out without confirmation
    localStorage.clear();
    navigate('/'); // Redirect to the login page
  };
 
  const handleLogout = () => {
    // Show a confirmation dialog to the user
    const confirmLogout = window.confirm('Do you really want to log out?');




    // Check if the user clicked "Yes"
    if (confirmLogout) {
      // Clear all data from local storage
      localStorage.clear();




      // Redirect to the login page
      navigate('/');
    }
    // If the user clicked "No", do nothing (i.e., do not log out)
  };




 const handleDropdownClick = (menuItem) => {
   setOpenDropdown(openDropdown === menuItem ? null : menuItem);
 };

 const getMenuIcon = (menuItem) => {
  switch (menuItem) {
    case 'Dashboard':
      return <FaTrello />;
    case 'Advanced Dashboard':
      return <FaCreditCard />;
    case 'CRM':
      return <FaUsers />;
    case 'HR':
      return <FaBriefcase />;
    case 'Project Management':
      return <VscTasklist />;
    case 'Purchase':
      return <FaFileInvoice />;
    case 'Reports':
      return <FaClipboardList />;
    case 'Payroll':
      return <FaCogs />;
    case 'Settings':
      return <FaTools />;
    case 'Message':
      return <FaEnvelope />;
    case 'Asset':
      return <FaBriefcase />;
    case 'Daily Update':
      return <FaCalendarDay />;
    default:
      return <FaCalendarDay />;
  }
};


 const renderMenuItems = (items) =>
   items.map((item) => (
     <React.Fragment key={item.menu_item}>
       <ListItem
         button
         onClick={() =>
           item.sub_menu_item
             ? handleDropdownClick(item.menu_item)
             : handleClick(`/${item.menu_item.toLowerCase().replace(/\s+/g, '-')}`)
         }
         sx={{
           backgroundColor: activeItem === `/${item.menu_item.toLowerCase().replace(/\s+/g, '-')}` ? '#3b82f6' : 'transparent',
           color: activeItem === `/${item.menu_item.toLowerCase().replace(/\s+/g, '-')}` ? '#fff' : 'inherit',
           borderRadius: '10px',
           padding: '5px 8px',
           marginBottom: '5px',
           '& .MuiListItemIcon-root': {
             color: activeItem === `/${item.menu_item.toLowerCase().replace(/\s+/g, '-')}` ? '#fff' : 'inherit',
           },
           '&:hover': {
             backgroundColor: '#3b82f6',
             color: '#fff',
             '& .MuiListItemIcon-root': {
               color: '#fff',
             },
           },
         }}
       >
         <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
    {getMenuIcon(item.menu_item)}
  </ListItemIcon>
         <ListItemText primary={item.menu_item} />
         {item.sub_menu_item ? (openDropdown === item.menu_item ? <ExpandLess /> : <ExpandMore />) : null}
       </ListItem>
       {item.sub_menu_item && (
         <Collapse in={openDropdown === item.menu_item} timeout="auto" unmountOnExit>
           <List component="div" disablePadding>
             {item.sub_menu_item.map((subItem) => (
               <ListItem
                 key={subItem.menu_item}
                 button
                 onClick={() => handleClick(`/${subItem.menu_item.toLowerCase().replace(/\s+/g, '-')}`)}
                 sx={{ pl: 4, backgroundColor: activeItem === `/${subItem.menu_item.toLowerCase().replace(/\s+/g, '-')}` ? '#3b82f6' : 'transparent' }}
               >
                 <ListItemText primary={subItem.menu_item} />
               </ListItem>
             ))}
           </List>
         </Collapse>
       )}
     </React.Fragment>
   ));


 const drawer = (
   <div>
     <DrawerHeader sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px' }}>
       <img src={myntask} alt="Myntask Logo" style={{ width: '90%', height: '64px', alignSelf: 'center' }} />
     </DrawerHeader>
     <Divider />
     <List>{renderMenuItems(menuItems)}</List>
   </div>
 );


 const superAdminDrawer = (
   <div>
     <DrawerHeader sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
       <Typography sx={{ alignSelf: 'center' }}>Myntask</Typography>
     </DrawerHeader>
     <Divider />
     <List>{renderMenuItems(menuItems)}</List>
   </div>
 );


 const renderDrawer = () => (userRole === '1' ? superAdminDrawer : drawer);


 return (
   <Box sx={{ display: 'flex', minHeight: '100vh' }}>
     <ToastContainer />
     <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
       <Drawer
         variant="temporary"
         open={mobileOpen}
         onClose={handleDrawerToggle}
         ModalProps={{ keepMounted: true }}
         sx={{
           display: { xs: 'block', sm: 'none' },
           '& .MuiDrawer-paper': {
             boxSizing: 'border-box',
             width: drawerWidth,
             backgroundColor: '#fff',
             display: 'flex',
             flexDirection: 'column',
             justifyContent: 'space-between',
             p: 0,
           },
         }}
       >
         <Box sx={{ flex: 1, overflowY: 'auto', p: '15px' }}>{renderDrawer()}</Box>
         <Box sx={{ p: 2, backgroundColor: '#fff', textAlign: 'center' }}>
           <Typography variant="body2">Footer Content</Typography>
         </Box>
       </Drawer>


       <Drawer
         variant="permanent"
         sx={{
           display: { xs: 'none', sm: 'block' },
           '& .MuiDrawer-paper': {
             boxSizing: 'border-box',
             width: drawerWidth,
             backgroundColor: '#fff',
             display: 'flex',
             flexDirection: 'column',
             justifyContent: 'space-between',
             p: '5px',
           },
         }}
         open
       >
         <Box sx={{ flex: 1, overflowY: 'auto' }}>{renderDrawer()}</Box>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
             <div style={{ textAlign: 'left', marginRight: '6px' }}>
               <h2 style={{ margin: 0, fontSize: '12px' }}>{(userProfile.company_name || '').split(' ').slice(0, 3).join(' ')}</h2>
               <p style={{ margin: '5px 0px', fontSize: '12px', color: '#666' }}>
                 <span style={{ display: 'inline-block', width: '8px', height: '8px', backgroundColor: 'green', borderRadius: '50%', marginRight: '6px' }}></span>
                 {userProfile.user_name}
               </p>
             </div>
             {userProfile.company_logo && <img style={{ width: '40px', height: '30px', objectFit: 'contain' }} src={userProfile.company_logo} alt={userProfile.company_name} />}
           </div>
         </Box>
       </Drawer>
     </Box>


     <Box component="main" sx={{ flex: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, p: 1, overflowY: 'auto', height: '100%', minHeight: '100vh' }}>
       <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', p: 0 }}>
         <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'block', xs: 'block' } }}>
           <MenuIcon />
         </IconButton>
         <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={containerStyle}>
          <div style={timeStyle}>{currentTime}</div>
          <div style={dayStyle}>{currentDay}</div>
        </div>


           <IconButton color="inherit" onClick={handleLogout}>
             <FaPowerOff />
           </IconButton>
           <IconButton onClick={handleIconClick}>
             <Badge badgeContent={notificationCount} color="error">
               <NotificationsIcon />
             </Badge>
           </IconButton>
           
      {/* Popover for Notifications */}
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: '350px',
            maxHeight: '56vh',
            overflowY: 'auto',
            borderRadius: '10px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
            backgroundColor: '#f9f9f9',
          },
        }}
      >
        <Box padding="12px">
          {/* Header with "Notifications" title and "View All" button */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="8px"
          >
            <Typography
              variant="h6"
              component="h2"
              style={{ fontWeight: 'bold', fontSize: '1rem' }}
            >
              Notifications
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleViewAll} // Navigate to NotificationScreen on click
              style={{
                minWidth: '60px',
                padding: '2px 6px',
                fontSize: '0.7rem',
              }}
            >
              View All
            </Button>
          </Box>




          {error ? (
            <Typography color="error" style={{ fontSize: '0.8rem' }}>{error}</Typography>
          ) : (
            <Box>
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <Box
                    key={notification.notification_id}
                    style={{
                      borderBottom: '1px solid #ddd',
                      padding: '6px 0',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#ffffff',
                      borderRadius: '5px',
                      marginBottom: '5px',
                    }}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    <img
                      src={notification.user_image}
                      alt="User Avatar"
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        marginRight: '8px',
                      }}
                    />
                    <div>
                      <Typography variant="body2" style={{ fontSize: '0.8rem' }}>
                        <strong>{notification.heading}</strong>
                      </Typography>
                      <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.7rem' }}>
                        {notification.message}
                      </Typography>
                      <Typography variant="caption" color="textSecondary" style={{ fontSize: '0.6rem' }}>
                        {notification.time_ago}
                      </Typography>
                    </div>
                  </Box>
                ))
              ) : (
                <Typography style={{ fontSize: '0.8rem' }}>No notifications found.</Typography>
              )}
            </Box>
          )}
        </Box>
      </Popover>
           <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => handleClick('/profileData')}
        >
          <img
            src={userProfile.image || '/default-avatar.png'}
            alt="User Avatar"
            style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '50%' }}
          />
          <div style={{ marginLeft: '10px' }}>
            <span style={{ fontSize: '14px', fontWeight: '700' }}>{userProfile.user_name || 'User Name'}</span>
          </div>
        </div>


         </div>
       </Toolbar>
       {children}
     </Box>
   </Box>
 );
};






const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  color: '#333',
  padding: '10px',
};


const timeStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
};

const dayStyle = {
  fontSize: '12px',
};




export default Layout;




