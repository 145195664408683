import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from "react-icons/md";
import './../Screens/AddEmployee.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';


const AddTaskModal = () => {
  const navigate = useNavigate();


  const [taskData, setTaskData] = useState({
    title: '',
    task_category_id: '',
    project_id: '',
    start_date: '',
    due_date: '',
    description: '',
    status_id: '',
    priority: '',
    private: 0,
    billable: 0,
    label_id: '',
    milestone_id: '',
    estimate_minutes: '',
    task_point: '',
    assigned_to: '',
    dependent_task_id:'',
    repeat: 0, // New input for repeat
    repeat_count: '',
    repeat_type: 'day', // Default repeat type
    repeat_cycles: '',
    file:null
  });


  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [labels, setLabels] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [members, setMembers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isDependent, setIsDependent] = useState(false);
const [dependentTasks, setDependentTasks] = useState([]);
const [selectedDependentTask, setSelectedDependentTask] = useState('');
const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
const [isDropdown, setIsDropdown] = useState(false);




const fetchDependentTasks = async () => {
  const token = localStorage.getItem("authToken");
  const projectId = taskData.project_id; // Get project ID from taskData
  console.log('project id is',projectId);
  try {
      const response = await fetch(`https://api.myntask.com/task/task_dependent_list/${projectId}`, {
          method: "GET",
          headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
          },
      });


      if (!response.ok) {
          throw new Error("Failed to fetch dependent tasks");
      }


      const result = await response.json();
      setDependentTasks(result); // Set the dependent tasks
  } catch (error) {
      console.error("Error fetching dependent tasks:", error);
      alert("Failed to fetch dependent tasks. Please select a project.");
  }
};






  const openModal = () => {
    setIsModalOpen(true);
  };


  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setNewCategoryName(""); // Clear the input input when closing modal
  };


  // Handle the form submission for adding a new category
  const handleAddCategory = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");


    const data = {
      name: newCategoryName,
    };


    try {
      const response = await fetch("https://api.myntask.com/task/task_category_add", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });


      if (!response.ok) {
        throw new Error("Failed to add category");
      }


      const result = await response.json();
      console.log("Category added successfully");


      // Add the new category to the existing categories list
      setCategories([...categories, result]);


      // Show success toast notification
      toast.success("Category added successfully!");


      // Close the modal after successful submission
      closeModal();


    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Failed to add category. Please try again.");
    }
  };


  // Handle the category deletion
  const handleDeleteCategory = async (categoryId) => {
    const token = localStorage.getItem("authToken");
    console.log('category id is', categoryId);
    try {
      const response = await fetch(`https://api.myntask.com/task/task_category_delete/${categoryId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });


      if (!response.ok) {
        throw new Error("Failed to delete category");
      }


      const result = await response.json();
      console.log("Category deleted successfully");


      // Remove the deleted category from the list
      setCategories(categories.filter((category) => category.id !== categoryId));


      // Show success toast notification
      toast.success("Category deleted successfully!");


    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error("Failed to delete category. Please try again.");
    }
  };






  useEffect(() => {
    const fetchData = async (url, setter) => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setter(data || []);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        setter([]);
      }
    };


    fetchData('https://api.myntask.com/task/task_category', setCategories);
    fetchData('https://api.myntask.com/task/project_list', setProjects);
    fetchData('https://api.myntask.com/task/task_status', setStatuses);
    fetchData('https://api.myntask.com/employee/member', setMembers);
  }, []);


  useEffect(() => {
    if (taskData.project_id) {
      const fetchLabels = async () => {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch(`https://api.myntask.com/task/task_label/${taskData.project_id}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setLabels(data || []);
        } catch (error) {
          console.error('Error fetching labels:', error);
          setLabels([]);
        }
      };


      const fetchMilestones = async () => {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch(`https://api.myntask.com/project/milestone_list/${taskData.project_id}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setMilestones(data || []);
        } catch (error) {
          console.error('Error fetching milestones:', error);
          setMilestones([]);
        }
      };


      fetchLabels();
      fetchMilestones();
    }
  }, [taskData.project_id]);


 
const handleDependentChange = () => {
  setIsDependent((prev) => !prev);
  if (!isDependent) {
    fetchDependentTasks(); // Fetch tasks when checkbox is checked
  } else {
    setDependentTasks([]); // Clear dependent tasks when unchecked (optional)
    setSelectedDependentTask(null); // Reset selected dependent task when unchecked
    setTaskData((prevData) => ({ ...prevData, dependent_task_id: null })); // Clear dependent task ID in taskData
  }
};
 


const handleChange = (e) => {
  const { name, value, type, checked } = e.target;
  setTaskData((prevData) => ({
    ...prevData,
    [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
  }));
};




const handleMultiSelect = (memberId) => {
  setTaskData((prevData) => {
    const updatedAssignedTo = prevData.assigned_to.includes(memberId)
      ? prevData.assigned_to.filter((id) => id !== memberId) // Unselect if already selected
      : [...prevData.assigned_to, memberId]; // Add member if not selected

    return { ...prevData, assigned_to: updatedAssignedTo };
  });
};



const handleChangeCategory = (categoryId) => {
  setTaskData((prevData) => ({
    ...prevData,
    task_category_id: categoryId,
  }));
  setIsCategoryDropdownOpen(false); // Close the category dropdown after selection
};




  const handleFileChange = (e) => {
    setTaskData((prevData) => ({
      ...prevData,
      file: e.target.files[0], // Capture the file
    }));
  };
 

 

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const token = localStorage.getItem('authToken');
  
    const taskDataToSend = {
      ...taskData,
      label_id: taskData.label_id || null,
      milestone_id: taskData.milestone_id || null,
      dependent_task_id: taskData.dependent_task_id || null,
      task_category_id: taskData.task_category_id || null,
      project_id: taskData.project_id || null,
      status_id: taskData.status_id || null,
      due_date: taskData.due_date || null,
    };
  
    console.log('Task data being sent:', taskDataToSend);
  
    try {
      const response = await fetch('https://api.myntask.com/task/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(taskDataToSend),
      });
  
      if (!response.ok) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to save task',
        });
        return;
      }
  
      // Success Swal notification
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Task saved successfully',
        showConfirmButton: false,
        timer: 1500
      });
      console.log('Task saved successfully!');
  
      // Reset the task data
      setTaskData({
        title: '',
        task_category_id: '',
        project_id: '',
        start_date: '',
        due_date: '',
        description: '',
        status_id: '',
        priority: '',
        private: 0,
        billable: 0,
        label_id: '',
        milestone_id: '',
        estimate_minutes: '',
        task_point: '',
        assigned_to: '',
        dependent_task_id: '',
        repeat: 0,
        repeat_count: '',
        repeat_type: 'day',
        repeat_cycles: '',
        file: null,
      });
  
      // Navigate back to the task page
      navigate('/task');
  
    } catch (error) {
      console.error('Error saving task:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while saving the task. Please try again.',
      });
    }
  };
  

  const handleClose = () => {
    navigate('/task');
  };


  return (
    <div className="main-container">
      <ToastContainer/>
      <div className="top-controls">
        <h1 className='title'>Add Task
          <div className="underline"></div>
        </h1>
      </div>
      <section className="section">
  <h3 className="sectionTitle">Task Info</h3>

  <div className="formRow">
    <div className="formColumn">
      <div className="formGroup">
        <label className='label'>Title<span className="required">*</span></label>
        <input
          className="input"
          type="text"
          id="title"
          name="title"
          value={taskData.title}
          onChange={handleChange}
          placeholder="Enter task title"
          required
        />
      </div>

      <div className="formGroup">
        <label className="label">Category</label>
        <div className="categoryDropdownContainer" style={{border:'none'}}>
          <div
            className="custom-dropdown"
            style={{marginRight:'10px'}}
            onClick={() => setIsCategoryDropdownOpen(!isCategoryDropdownOpen)}
          >
            <div className="selected-category">
              {taskData.task_category_id
                ? categories.find((cat) => cat.id === taskData.task_category_id)?.category_name || "Select Category"
                : "Select Category"}
            </div>

            {isCategoryDropdownOpen && (
              <div className="dropdown-options">
                {categories.map((category) => (
                  <div key={category.id} className="dropdown-item">
                    <span onClick={() => handleChangeCategory(category.id)}>
                      {category.category_name}
                    </span>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteCategory(category.id)}
                    >
                      <MdDelete />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <button className="color-button" onClick={openModal}>
            Add
          </button>
        </div>
      </div>

      <div className="formGroup">
        <label className='label'>Project</label>
        <select
          className="input select-box"
          id="project_id"
          name="project_id"
          value={taskData.project_id}
          onChange={handleChange}
        >
          <option value="" disabled>Select Project</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.project_name}
            </option>
          ))}
        </select>
      </div>
      <div className="formGroup">
        <label className='label'>Start Date<span className="required">*</span></label>
        <input
          className="input"
          type="date"
          id="start_date"
          name="start_date"
          value={taskData.start_date}
          onChange={handleChange}
          placeholder="Select start date"
          required
        />
      </div>

     
    </div>

    <div className="formColumn">
    
    <div className="formGroup">
  <label className="label">Assign To</label>
  <div
    className="custom-dropdown"
    onClick={() => setIsAssignDropdownOpen((prev) => !prev)}
  >
    <div className="selected-category">
      {taskData.assigned_to.length > 0
        ? members
            .filter((member) => taskData.assigned_to.includes(member.id))
            .map((selectedMember) => selectedMember.member_name)
            .join(", ")
        : "Select Members"}
    </div>

    {isAssignDropdownOpen && (
      <div className="dropdown-options">
        {members.map((member) => (
          <div
            key={member.id}
            className="dropdown-item"
            onClick={() => handleMultiSelect(member.id)} // Clicking the row will select/unselect
          >
            <input
              type="checkbox"
              id={`member-${member.id}`}
              value={member.id}
              checked={taskData.assigned_to.includes(member.id)}
              onClick={(e) => e.stopPropagation()} // Prevents double toggle when clicking the checkbox directly
              onChange={() => handleMultiSelect(member.id)} // For keyboard accessibility
            />
            <label htmlFor={`member-${member.id}`}>
              {member.member_name}
            </label>
          </div>
        ))}
      </div>
    )}
  </div>
</div>

      <div className="formGroup">
        <label className='label'>Due Date</label>
        <input
          className="input"
          type="date"
          id="due_date"
          name="due_date"
          value={taskData.due_date}
          onChange={handleChange}
          placeholder="Select due date"
        />
      </div>

      <div className="formGroup">
        <label className='label'>Description</label>
        <textarea
          className="input"
          type="text"
          id="description"
          name="description"
          value={taskData.description}
          onChange={handleChange}
          placeholder="Enter task description"
        />
      </div>
    </div>
  </div>
</section>


<section className="section">
  <h3 className="sectionTitle">Other Details</h3>


  <div className="formRow">

  <div className="formColumn">
        <div className="formGroup">
          <label className='label'>Status<span className="required">*</span></label>
          <select
            className="input"
            id="status_id"
            name="status_id"
            value={taskData.status_id}
            onChange={handleChange}
           required
          >
            <option value="" disabled>Select Status</option>
            {statuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.status}
              </option>
            ))}
          </select>
        </div>

        <div className="formGroup">
          <label className='label'>Priority</label>
          <select
            className="input"
            id="priority"
            name="priority"
            value={taskData.priority}
            onChange={handleChange}
          >
            <option value="" disabled>Select Priority</option>
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
        </div>
        <div className="formGroup">
          <label className='label'>Label</label>
          <select
            className="input"
            id="label_id"
            name="label_id"
            value={taskData.label_id}
            onChange={handleChange}
          >
            <option value="" disabled>Select Label</option>
            {labels.map((label) => (
              <option key={label.id} value={label.id}>
                {label.label_name}
              </option>
            ))}
          </select>
        </div>
        <div className="formGroup">
          <label className='label'>Milestone</label>
          <select
            className="input"
            id="milestone_id"
            name="milestone_id"
            value={taskData.milestone_id}
            onChange={handleChange}
          >
            <option value="" disabled>Select Milestone</option>
            {milestones.map((milestone) => (
              <option key={milestone.id} value={milestone.id}>
                {milestone.milestone_title}
              </option>
            ))}
          </select>
        </div>
        
<div className="formGroup">
  <label className="label">  Is Dependent Task
  </label>
    <input
      type="checkbox"
      name="dependent"
      checked={isDependent} // Reflects the state of the dependent checkbox
      onChange={handleDependentChange}
      className="checkboxInput"
    />
</div>
{isDependent && (
  <div className="formGroup">
    <label className="label">Select Dependent Task</label>
    <div className="custom-dropdown">
      <div
        className="selected-category"
        onClick={() => setIsDropdown(!isDropdown)}
      >
        {selectedDependentTask
          ? dependentTasks.find((task) => task.id === selectedDependentTask)?.name || "Select Task"
          : "Select Task"}
      </div>


      {isDropdown && (
        <div className="dropdown-options">
          {dependentTasks.map((task) => (
            <div key={task.id} className="dropdown-item">
              <span
                onClick={() => {
                  // Update selectedDependentTask state
                  setSelectedDependentTask(task.id);
                  setIsDropdown(false); // Close dropdown


                  // Update taskData with the dependent task ID
                  setTaskData((prevData) => ({
                    ...prevData,
                    dependent_task_id: task.id, // Store the dependent task ID
                  }));


                  // Debugging log to confirm selection
                  console.log('Selected dependent task ID:', task.id);
                }}
              >
                {task.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
)}

<div className="formGroup">
  <label className="label"> Private
  </label>
    <input
      type="checkbox"
      name="private"
      checked={taskData.private === 1} // Check if taskData.private equals 1
      onChange={handleChange}
      className="checkboxInput"
    />
   
</div>

</div>

<div className="formColumn">

  <div className="formGroup">
    <label className='label'>Estimate Minutes</label>
    <input
      className="input"
      type="number"
      id="estimate_minutes"
      name="estimate_minutes"
      value={taskData.estimate_minutes}
      onChange={handleChange}
      placeholder="Enter estimated minutes"  // Placeholder added
    />
  </div>

  <div className="formGroup">
    <label className='label'>Task Points</label>
    <input
      className="input"
      type="number"
      id="task_point"
      name="task_point"
      value={taskData.task_point}
      onChange={handleChange}
      placeholder="Enter task points"  // Placeholder added
    />
  </div>

  <div className="formGroup">
    <label className='label'>Upload File</label>
    <input
      type="file"
      onChange={handleFileChange}
      className="input select-box"
      placeholder="Choose a file"  // Placeholder added (optional for file inputs)
    />
  </div>

  {/* Repeat Checkbox */}
  <div className="formGroup">
    <label className="label">Repeat</label>
    <input
      type="checkbox"
      name="repeat"
      checked={taskData.repeat === 1} // Assuming repeat is 1 for checked
      onChange={handleChange}
      className="checkboxInput"
    />
  </div>

  {/* Repeat inputs that appear when the checkbox is checked */}
  {taskData.repeat === 1 && (
    <>
      <div className="formGroup">
        <label className="label">Repeat every</label>
        <div className="categoryDropdownContainer">
          <input
            type="number"
            name="repeat_count"
            value={taskData.repeat_count}
            onChange={handleChange}
            className="input"
            placeholder="Enter repeat count"  // Placeholder added
          />
          <select
            name="repeat_type"
            value={taskData.repeat_type}
            onChange={handleChange}
            className="drop"
          >
            <option value="day">Day(s)</option>
            <option value="week">Week(s)</option>
            <option value="month">Month(s)</option>
          </select>
        </div>
      </div>

      <div className="formGroup">
        <label className="label">Cycles</label>
        <input
          type="number"
          name="repeat_cycles"
          value={taskData.repeat_cycles}
          onChange={handleChange}
          className="input"
          placeholder="Enter number of cycles"  // Placeholder added
        />
      </div>
    </>
  )}

  <div className="formGroup">
    <label className="label">Billable</label>
    <input
      type="checkbox"
      name="billable"
      checked={taskData.billable === 1} // Check if taskData.billable equals 1
      onChange={handleChange}
      className="checkboxInput"
    />
  </div>

</div>

      </div>  
      </section>

      <button onClick={handleSubmit} className="submit-button">
          Save Task
        </button>
   


      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Add New Category</h2>
            <form onSubmit={handleAddCategory}>
              <label className='label'>Category Name:</label>
              <input
                type="text"
                id="newCategoryName"
                name="newCategoryName"
                value={newCategoryName}
                className="input"
                onChange={(e) => setNewCategoryName(e.target.value)}
               
              />
              <div className="modal-buttons">
                <button type="submit" className="color-button">
                  Add Category
                </button>
                <button type="button" className="color-button" onClick={closeModal}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}




    </div>
  );
};


export default AddTaskModal;




