import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import SweetAlert
import './Form.css'; // Import the CSS file


const NewLeaveModal = () => {
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState('');
  const [duration, setDuration] = useState('full day');
  const [leaveDate, setLeaveDate] = useState('');
  const [reason, setReason] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const fetchLeaveTypes = async () => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://api.myntask.com/leave/leave_type', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch leave types');
        }


        const data = await response.json();
        setLeaveTypes(data);
      } catch (error) {
        console.error('Error fetching leave types:', error);
      }
    };


    fetchLeaveTypes();
  }, []);



  const handleSubmit = async () => {
    const token = localStorage.getItem('authToken');
  
    // Construct leaveData object with null checks
    const leaveData = {
      leave_type_id: selectedLeaveType || null, // Send null if not selected
      duration: duration || null,               // Send null if not selected
      leave_date: leaveDate ,           // Send null if not provided
      reason: reason || null                     // Send null if not provided
    };
  
    console.log('Leave data:', leaveData); // Log the leaveData for debugging
  
    try {
      const response = await fetch('https://api.myntask.com/leave/save', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leaveData),
      });
  
      // Check for a successful response
      if (!response.ok) {
        const errorText = await response.text(); // Retrieve error response for logging
        console.error('Error response from server:', errorText); // Log the error response
        throw new Error('Failed to save leave request'); // Throw an error for handling
      }
  
      // Show success message using SweetAlert without confirmation
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Leave request submitted successfully!',
        timer: 2000, // Auto-close after 3 seconds
        showConfirmButton: false, // Hide the confirm button
        willClose: () => {
          navigate('/leave'); // Navigate back to the leave list page after closing
        }
      });
    } catch (error) {
      console.error('Error saving leave request:', error); // Log the error
  
      // Show error message using SweetAlert without confirmation
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to submit leave request.',
        timer: 2000, // Auto-close after 3 seconds
        showConfirmButton: false // Hide the confirm button
      });
    }
  };
  

  const handleClose = () => {
    navigate('/leave'); // Navigate back to the Leave page
  };


  return (
<div className='main-container'>

  <div className="top-controls">
        <h1 className='title'>Add New Leave
          <div className="underline"></div>
        </h1>
      </div>


      <section className="section">
      <h3 className="sectionTitle">Leave Details</h3>
      <div className="formRow" style={{marginTop:'40px'}}>
    <div className="formColumn">
  <div className="formGroup" style={{marginBottom:'55px'}}>
  <label className="label">
      Leave Type
    </label>
    <select
  id="leave-type"
  value={selectedLeaveType}
  onChange={(e) => setSelectedLeaveType(e.target.value)}
  disabled={leaveTypes.length === 0}
  className="input"
>
  <option value="" disabled>
    Select a type
  </option>
  {leaveTypes.length > 0 ? (
    leaveTypes.map((leaveType) => (
      <option key={leaveType.id} value={leaveType.id}>
        {leaveType.type_name} ({leaveType.no_of_leaves})
      </option>
    ))
  ) : (
    <option value="" disabled>
      No leave types available
    </option>
  )}
</select>

  </div>


  <div className="formGroup" style={{marginBottom:'50px'}}>
  <label className="label">Duration</label>
    <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
      <label>
        <input
          type="radio"
          value="full day"
          checked={duration === 'full day'}
          onChange={(e) => setDuration(e.target.value)}
          className="input"
        />
        Full Day
      </label>
      <label>
        <input
          type="radio"
          value="first half"
          checked={duration === 'first half'}
          onChange={(e) => setDuration(e.target.value)}
        />
        First Half
      </label>
      <label>
        <input
          type="radio"
          value="second half"
          checked={duration === 'second half'}
          onChange={(e) => setDuration(e.target.value)}
        />
        Second Half
      </label>
      <label>
        <input
          type="radio"
          value="multiple"
          checked={duration === 'multiple'}
          onChange={(e) => setDuration(e.target.value)}
        />
        Multiple
      </label>
    </div>
  </div>
</div>
<div className="formColumn">
<div className="formGroup" style={{marginBottom:'50px'}}>
  <label className="label">
      Leave Date
    </label>
    <input
      type="date"
      id="leave-date"
      value={leaveDate}
      onChange={(e) => setLeaveDate(e.target.value)}
      className="input"
    />
  </div>


  <div className="formGroup" style={{marginBottom:'50px'}}>
  <label className="label">
      Reason
    </label>
    <input
      type="text"
      id="reason"
      value={reason}
      onChange={(e) => setReason(e.target.value)}
      className="input"
    />
  </div>
  </div>
  </div>
  </section>


  <button
    onClick={handleSubmit}
    className='color-button'
  >
    Submit
  </button>
</div>


  );
};


export default NewLeaveModal;




 
 
