// src/components/AppSetting.js
import React from 'react';

const AppSetting = () => {
  return (
    <div>
      <h1>App Setting</h1>
      {/* App setting content goes here */}
    </div>
  );
};

export default AppSetting;