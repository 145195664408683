// src/KnowledgeBase.js
import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal } from '@mui/material';

function KnowledgeBase() {
  const [categories, setCategories] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [details, setDetails] = useState([]);
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const token = localStorage.getItem('authToken');

      if (!token) {
        setError('No token found. Please log in.');
        return;
      }

      try {
        const response = await fetch('https://api.myntask.com/knowledge_base/category', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }

        const result = await response.json();
        console.log('Categories:', result);
        setCategories(result);

        if (result.length > 0) {
          fetchDetails(result[0].id);
        }
      } catch (err) {
        console.error('Error fetching categories:', err);
        setError('Failed to fetch categories');
      }
    };

    fetchCategories();
  }, []);

  const fetchDetails = async (id) => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch(`https://api.myntask.com/knowledge_base/list/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch details');
      }

      const result = await response.json();
      console.log(`Details for category ${id}:`, result);
      setDetails(result);
    } catch (err) {
      console.error(`Error fetching details for category ${id}:`, err);
      setError('Failed to fetch details');
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    fetchDetails(categories[newValue].id);
  };

  const handleViewClick = async (id) => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch(`https://api.myntask.com/knowledge_base/view/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch item details');
      }

      const result = await response.json();
      console.log(`Details for item ${id}:`, result); // Console log the data
      setModalData(result[0]); // Assuming the result is an array, take the first item
      setOpenModal(true);
    } catch (err) {
      console.error(`Error fetching item details for id ${id}:`, err);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData(null);
  };

  return (
    <div>
      <h1>Knowledge Base Categories</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Box sx={{ display: 'flex' }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', minWidth: '200px' }}
        >
          {categories.map((category, index) => (
            <Tab key={category.id} label={category.name} />
          ))}
        </Tabs>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          {details && details.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Heading</TableCell>
                    <TableCell>Content</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.map((item, idx) => (
                    <TableRow key={item.id || idx}>
                      <TableCell>{item.heading || 'No Heading Available'}</TableCell>
                      <TableCell>{item.to || 'No Content Available'}</TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => handleViewClick(item.id)}>View</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No data available</Typography>
          )}
        </Box>
      </Box>

      {/* Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box 
          sx={{ 
            p: 3, 
            backgroundColor: 'white', 
            margin: 'auto', 
            marginTop: '5%', 
            width: '50%',
            maxHeight: '80vh', // Set max height for the modal
            overflowY: 'auto'  // Enable scrolling within the modal
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {modalData?.heading || 'No Title Available'}
          </Typography>
          <Box
            id="modal-description"
            sx={{ mt: 2 }}
            dangerouslySetInnerHTML={{ __html: modalData?.description || 'No Content Available' }}
          ></Box>
          <Button variant="contained" onClick={handleCloseModal} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default KnowledgeBase;
