import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // To capture projectId
import { Box, Typography, Grid, Avatar, Button, Paper } from '@mui/material';

const TaskBoard = () => {
  const { projectId } = useParams(); // Capture the projectId from the route
  const [taskData, setTaskData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTasks = async () => {
      const token = localStorage.getItem('authToken');
      if (!projectId) {
        console.error('Project ID is not available');
        return;
      }

      try {
        const response = await fetch(`https://api.myntask.com/project/task_board/${projectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        console.log('Task data:', data); // This will log the API response in the console for verification
        setTaskData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching task data:', error);
      }
    };

    fetchTasks();
  }, [projectId]); // Make sure the projectId is being used in the fetch request

  if (loading) {
    return <Typography>Loading tasks...</Typography>;
  }

  const renderTasks = (tasks, status) => {
    const statusColor = {
      Incomplete: 'red',
      'To Do': 'goldenrod',
      Doing: 'blue',
      Completed: 'green',
    }[status]; // Assign the border color based on the status

    if (tasks && tasks.length > 0) {
      return tasks.map((task) => (
        <Paper
          key={task.task_id}
          elevation={3}
          style={{
            padding: '10px',
            marginBottom: '15px',
            borderLeft: `5px solid ${statusColor}`, // Use the correct color based on status
          }}
        >
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            {task.task}
          </Typography>
          <Typography variant="caption" style={{ color: '#999' }}>
            {task.due_date}
          </Typography>
          <Box display="flex" mt={1}>
            {task.assigned_users.map((user, i) => (
              <Avatar
                key={i}
                src={user.image}
                alt={user.name}
                style={{ width: 30, height: 30, marginRight: '5px' }}
              />
            ))}
          </Box>
        </Paper>
      ));
    } else {
      return <Typography>No tasks available.</Typography>;
    }
  };

  return (
    <Box p={2} style={{ height: '85vh' }}> {/* Set a fixed height for the task board */}
      {/* Add Task and Add Status Column Buttons */}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Button variant="contained" color="primary">
          + Add Task
        </Button>
        <Button variant="outlined" color="primary">
          + Add Status Column
        </Button>
      </Box>

      {/* Task Board Columns */}
      <Grid container spacing={2} style={{ height: '100%' }}> {/* Ensure the grid takes full height */}
        {/* Incomplete Tasks Column */}
        <Grid item xs={3} style={{ height: '100%' }}>
          <Box
            style={{
              padding: '10px',
              backgroundColor: '#f9f9f9',
              borderRadius: '8px',
              boxShadow: '0px 2px 6px rgba(0,0,0,0.1)',
              height: '100%',  // Full height of the column
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              variant="h6"
              style={{ color: 'red', marginBottom: '10px', fontWeight: 500 }}
            >
              Incomplete ({taskData?.Incomplete?.count || 0})
            </Typography>
            <Box
              style={{
                overflowY: 'auto',  // Add scrolling for the task list
                flexGrow: 1,        // Ensure it takes the available height
              }}
            >
              {renderTasks(taskData?.Incomplete?.tasks, 'Incomplete')}
            </Box>
          </Box>
        </Grid>

        {/* To Do Tasks Column */}
        <Grid item xs={3} style={{ height: '100%' }}>
          <Box
            style={{
              padding: '10px',
              backgroundColor: '#f9f9f9',
              borderRadius: '8px',
              boxShadow: '0px 2px 6px rgba(0,0,0,0.1)',
              height: '100%',  // Full height of the column
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              variant="h6"
              style={{ color: 'goldenrod', marginBottom: '10px', fontWeight: 500 }}
            >
              To Do ({taskData?.['To Do']?.count || 0})
            </Typography>
            <Box
              style={{
                overflowY: 'auto',  // Add scrolling for the task list
                flexGrow: 1,        // Ensure it takes the available height
              }}
            >
              {renderTasks(taskData?.['To Do']?.tasks, 'To Do')}
            </Box>
          </Box>
        </Grid>

        {/* Doing Tasks Column */}
        <Grid item xs={3} style={{ height: '100%' }}>
          <Box
            style={{
              padding: '10px',
              backgroundColor: '#f9f9f9',
              borderRadius: '8px',
              boxShadow: '0px 2px 6px rgba(0,0,0,0.1)',
              height: '100%',  // Full height of the column
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              variant="h6"
              style={{ color: 'blue', marginBottom: '10px', fontWeight: 500 }}
            >
              Doing ({taskData?.Doing?.count || 0})
            </Typography>
            <Box
              style={{
                overflowY: 'auto',  // Add scrolling for the task list
                flexGrow: 1,        // Ensure it takes the available height
              }}
            >
              {renderTasks(taskData?.Doing?.tasks, 'Doing')}
            </Box>
          </Box>
        </Grid>

        {/* Completed Tasks Column */}
        <Grid item xs={3} style={{ height: '100%' }}>
          <Box
            style={{
              padding: '10px',
              backgroundColor: '#f9f9f9',
              borderRadius: '8px',
              boxShadow: '0px 2px 6px rgba(0,0,0,0.1)',
              height: '100%',  // Full height of the column
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              variant="h6"
              style={{ color: 'green', marginBottom: '10px', fontWeight: 500 }}
            >
              Completed ({taskData?.Completed?.count || 0})
            </Typography>
            <Box
              style={{
                overflowY: 'auto',  // Add scrolling for the task list
                flexGrow: 1,        // Ensure it takes the available height
              }}
            >
              {renderTasks(taskData?.Completed?.tasks, 'Completed')}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TaskBoard;
