import React, { useState, useEffect } from 'react';
import './css/Settings.css'; // Import your custom CSS

// Import your components for each tab
import ProfileSettingg from '../Components/ProfileSettingg';
import RolePermissionSetting from '../Components/RolePermissionSetting';
import LeavesSetting from '../Components/LeavesSetting';
import AttendanceSetting from '../Components/AttendanceSetting';
import ProjectSetting from '../Components/ProjectSetting';
import FinanceSettingg from '../Components/FinanceSettingg';
import TaxSetting from '../Components/TaskSetting';
import MessageSetting from '../Components/MessageSetting';
import TaskSetting from '../Components/TaskSetting';

function Settings() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0); // State to track the active tab

  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem('authToken'); // Get the token from local storage

      if (!token) {
        setError('No token found. Please log in.');
        return;
      }

      try {
        const response = await fetch('https://api.myntask.com/auth/setting_sidebar', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const result = await response.json();
        console.log('API Response:', result); // Add console log to check the response

        setData(result); // Save the fetched data in the state
      } catch (error) {
        console.error('Error fetching settings:', error);
        setError(error.message);
      }
    };

    fetchSettings();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  // Function to render the correct component based on the active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <ProfileSettingg />;
      case 1:
        return <RolePermissionSetting />;
      case 2:
        return <LeavesSetting />;
      case 3:
        return <AttendanceSetting />;
      case 4:
        return <ProjectSetting />;
      case 5:
        return <FinanceSettingg />;
      case 6:
        return <TaxSetting/>;
      case 7:
        return <MessageSetting />;
      case 8:
        return <TaskSetting />;
      default:
        return <div>No content available for this tab.</div>;
    }
  };

  return (
    <div className="settings-container">
      {error && <p>Error: {error}</p>}
      {data.length > 0 ? (
        <div className="vertical-tabs">
          <div className="tab-list">
            {data.map((item, index) => (
              <div
                key={index}
                className={`tab-item ${activeTab === index ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="tab-content">
            {renderTabContent()}
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Settings;
