import React, { useEffect, useState } from 'react';
import { Modal, Box,Button} from '@mui/material';
import Swal from 'sweetalert2';


function EditDesignationModal({ open, handleClose, designationId, onDesignationUpdated}) {
  const [designation, setDesignation] = useState({ name: '', parent_id: '' });
  const [parents, setParents] = useState([]);


  useEffect(() => {
    // Fetch the current designation details
    const fetchDesignationDetails = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) return;


      try {
        const response = await fetch(`https://api.myntask.com/employee/designation_edit/${designationId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (response.ok) {
          const data = await response.json();
          if (data && data.length > 0) {
            setDesignation({ name: data[0].name, parent_id: data[0].parent_id || '' });
          }
        } else {
          console.error('Failed to fetch designation details:', response.statusText);
        }
      } catch (error) {
        console.error('Failed to fetch designation details:', error);
      }
    };


    // Fetch the list of all designations for the dropdown
    const fetchParentOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) return;


      try {
        const response = await fetch(`https://api.myntask.com/employee/designation`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (response.ok) {
          const data = await response.json();
          setParents(data);
        } else {
          console.error('Failed to fetch designations:', response.statusText);
        }
      } catch (error) {
        console.error('Failed to fetch designations:', error);
      }
    };


    if (designationId) {
      fetchDesignationDetails();
    }
    fetchParentOptions();
  }, [designationId]);


  const handleSave = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return;
  
    const payload = {
      designation_id: designationId,
      name: designation.name,
      parent_id: designation.parent_id || 0, // Set parent_id to 0 if it is null or undefined
    };
  
    try {
      const response = await fetch(`https://api.myntask.com/employee/designation_update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        // Show success alert
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Designation updated successfully!',
       
        });
  
        handleClose();
        onDesignationUpdated(); // Call the fetch function to refresh the designation list
        // Optionally refresh the designation list after saving
      } else {
        const errorMessage = await response.text(); // Get error message from response
        console.error('Failed to save designation:', errorMessage);
        
        // Show error alert
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: `Failed to save designation: ${errorMessage}`,
        
        });
      }
    } catch (error) {
      console.error('Failed to save designation:', error);
  
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An unexpected error occurred while saving designation.',
      
      });
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDesignation((prev) => ({ ...prev, [name]: value }));
  };


  return (
    <Modal open={open} onClose={handleClose}>
    <Box
      sx={{
        p: 4,
        bgcolor: 'background.paper',
        margin: 'auto',
        width: 400,
        marginTop: 10,
        position: 'relative',
      }}
    >
      <button
        onClick={handleClose}
        style={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          backgroundColor: 'transparent',
          border: 'none',
          fontSize: '18px',
          cursor: 'pointer',
        }}
      >
        X
      </button>
      <h2>Edit Designation</h2>
      
      <label htmlFor="name">Designation Name</label>
      <input
        type="text"
        id="name"
        name="name"
        value={designation.name}
        onChange={handleChange}
        className="field"
      />
  
      <label htmlFor="parent_id">Parent Designation</label>
      <select
        id="parent_id"
        name="parent_id"
        value={designation.parent_id}
        onChange={handleChange}
        className="drop"
      >
        <option value="">None</option>
        {parents.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
  
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
        <Button
  onClick={handleClose}
  variant="outlined"
  color="secondary"
  sx={{
    '&:hover': {
      color: 'white', // Change font color to white on hover
    },
  }}
>
  Cancel
</Button>

      </div>
    </Box>
  </Modal>
  
  );
}


export default EditDesignationModal;
