// src/components/SettingsSidebar.js
import React, { useEffect, useState } from 'react';
import { Button, Typography, TextField } from '@mui/material';
import AppSetting from './AppSetting';
import ProfileSetting from './ProfileSetting';
import NotificationSetting from './NotificationSetting';
import CurrencySetting from './CurrencySetting';
import FinanceSetting from './FinanceSetting';
import RolesPermisssion from './RolesPermisssion';
// Import other setting components here

const SettingsSidebar = () => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem('authToken'); // Ensure the correct key is used
      console.log('Retrieved token from localStorage:', token); // Log the retrieved token
  
      if (!token) {
        setError('No token found');
        setLoading(false);
        return;
      }
  
      // Decode the token to check its expiration
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode the JWT payload
        const currentTime = Date.now() / 1000; // Current time in seconds
  
        if (decodedToken.exp < currentTime) {
          setError('Token expired. Please log in again.');
          localStorage.removeItem('authToken'); // Clear the expired token
          setLoading(false);
          return;
        }
      } catch (e) {
        console.error("Error decoding token:", e);
        setError('Invalid token');
        setLoading(false);
        return;
      }
  
      try {
        console.log("Fetching settings...");
        const response = await fetch('https://api.myntask.com/auth/setting_sidebar', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          console.error(`HTTP error! status: ${response.status}, response: ${errorText}`);
          throw new Error(`HTTP error! status: ${response.status}, response: ${errorText}`);
        }
  
        const data = await response.json();
        console.log("Fetched data:", data);
        setSettings(data);
        if (data.length > 0) {
          setSelectedSetting(data.find(setting => setting === 'Profile Setting') || data[0]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setError(error.message);
        setLoading(false);
      }
    };
  
    fetchSettings();
  }, []);
  
  
  

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" color="error">Error: {error}</Typography>;
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSettings = settings.filter(setting =>
    setting.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderContent = () => {
    switch (selectedSetting) {
      case 'App Setting':
        return <AppSetting />;
      case 'Profile Setting':
        return <ProfileSetting />;
      case 'Notification Setting':
        return <NotificationSetting />;
      case 'Currency Setting':
        return <CurrencySetting />;
        case 'Finance Setting':
            return <FinanceSetting />;
            case 'Superadmin Role & Permission':
                return <RolesPermisssion />;
      // Add cases for other settings
      default:
        return <Typography variant="h6">Please select a setting</Typography>;
    }
  };

  return (
    <div style={styles.root}>
      <div style={styles.sidebar}>
        <TextField
          variant="outlined"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={styles.searchBar}
        />
        {filteredSettings.map((setting, index) => (
          <Button
            key={index}
            variant="text" // Use text variant to avoid default box shadow
            style={{
              ...styles.button,
              ...(selectedSetting === setting ? styles.selectedButton : {})
            }}
            fullWidth
            onClick={() => setSelectedSetting(setting)}
          >
            {setting}
          </Button>
        ))}
      </div>
      <div style={styles.content}>
        {renderContent()}
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: 'flex',
    height: '100vh',
  },
  sidebar: {
    width: '250px',
    padding: '16px',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'fixed', // Keeps sidebar fixed on the left
    height: '100vh', // Full height of the viewport
    borderRight: '1px solid #ddd', // Optional: add a border on the right side of the sidebar
    overflowY: 'auto', // Enable vertical scrolling
  },
  searchBar: {
    marginBottom: '16px', // Add margin for spacing
    width: '100%', // Make search bar full width of the sidebar
  },
  button: {
    marginBottom: '8px',
    textAlign: 'left',
    textTransform: 'none', // Keeps button text case as it is
    padding: '12px 16px', // Padding for the button
    borderRadius: '0', // Remove rounded corners
    boxShadow: 'none', // Remove box shadow
    color: 'black', // Set font color to black
    backgroundColor: 'transparent', // Remove background color
    '&:hover': {
      backgroundColor: 'transparent', // Ensure no background color on hover
    },
  },
  selectedButton: {
    backgroundColor: '#3a99e4', // Blue background for selected button
    color: '#fff', // White text color for selected button
  },
  content: {
    marginLeft: '250px', // Margin to account for sidebar width
    padding: '16px',
    width: 'calc(100% - 250px)', // Ensure the content takes the remaining width
    overflowY: 'auto', // Enable vertical scrolling for the content
  },
};

export default SettingsSidebar;
