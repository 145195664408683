import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './AddOrder.css';

function AddOrder() {
  const [items, setItems] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [taxOptions, setTaxOptions] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedShippingAddress, setSelectedShippingAddress] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [showTaxDropdown, setShowTaxDropdown] = useState(false);
  const [showUnitTypeDropdown, setShowUnitTypeDropdown] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [orderDate, setOrderDate] = useState(null);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
  const token = localStorage.getItem('authToken');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Calculate Tax');
  const [status, setStatus] = useState('Delivery Status');
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const handleSelect = (selectedStatus) => {
    setStatus(selectedStatus);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    fetch('https://api.myntask.com/purchase_order/vendor_company', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setVendors(data);
      })
      .catch(error => console.error('Error fetching vendors:', error));




    fetch('https://api.myntask.com/purchase_order/shipping_address', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setShippingAddresses(data);
      })
      .catch(error => console.error('Error fetching shipping addresses:', error));




    fetch('https://api.myntask.com/purchase_order/product_dropdown', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('Product Options API Response:', data);
        setProducts(data);
      })
      .catch(error => console.error('Error fetching products:', error));
  }, [token]);




  const handleAddItem = () => {
    setItems([...items, { name: '', description: '', hsn: '', quantity: '', unitType: 'Pcs', unitPrice: '', taxes: [], totalAmount: 0, image: null, selectedProduct: '' }]);
  };


  const extractTaxPercentage = (taxString) => {
    if (!taxString) return 0;
    const match = taxString.match(/(\d+(\.\d+)?)%/);
    if (match) {
      return parseFloat(match[1]) / 100;
    }
    return 0;
  };


  const handleProductChange = (index, productId) => {
    const updatedItems = [...items];
    updatedItems[index].selectedProduct = productId; // Set the selected product for the specific item


    console.log(`Selected Product ID for item ${index}: ${productId}`);


    if (productId) {
      fetch(`https://api.myntask.com/purchase_order/product_dropdown_info/${productId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log('Product Details API Response:', data);


          if (data && data.length > 0) {
            const product = data[0]; // Assuming the API returns an array of products
            const unitPrice = parseFloat(product.purchase_price) || 0;
            const quantity = parseFloat(product.quantity) || 1;


            // Calculate tax percentage
            const taxTotalPercentage = product.taxes.reduce((acc, tax) => acc + extractTaxPercentage(tax), 0);

            // Calculate totalAmount
            const amountWithoutTax = quantity * unitPrice;
            const taxAmount = amountWithoutTax * taxTotalPercentage;
            const totalAmount = amountWithoutTax + taxAmount;


            updatedItems[index] = {
              ...updatedItems[index],
              product_id: product.id, // Ensure the product_id is set
              name: product.name || '',
              description: product.description || '',
              hsn: product.hsn_sac_code || '',
              quantity: quantity,
              unitType: product.unit_type || 'Pcs',
              unitPrice: unitPrice,
              taxes: product.taxes || [],
              totalAmount: Number(totalAmount.toFixed(2)), // Ensure totalAmount is a number
              image: null, // Assuming image will be handled separately
            };


            setItems(updatedItems); // Update the items state
            console.log('Mapped Item with Total Amount (Including Tax):', updatedItems[index]);
          } else {
            console.error('No product data available');
          }
        })
        .catch(error => {
          console.error('Error fetching product details:', error);
        });
    }
  };


  const handleImageChange = (index, event) => {
    const newItems = [...items];
    newItems[index].image = event.target.files[0];
    setItems(newItems);
  };




  useEffect(() => {
    console.log('Updated Tax Options:', taxOptions);
  }, [taxOptions]); // Logs the updated taxOptions whenever it changes


  const handleTaxFieldClick = (index) => {
    console.log("Tax field clicked for item index:", index);
    setSelectedItemIndex(index);
    setShowTaxDropdown(true);


    fetch('https://api.myntask.com/product/taxes', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('Tax Options API Response:', data); // Logs the raw API response
        setTaxOptions(data); // Updates the taxOptions state
      })
      .catch(error => console.error('Error fetching tax options:', error));
  };




  const handleTaxSelect = (index, taxOption) => {
    const newItems = [...items];
    const taxValue = taxOption.tax_name;

    if (newItems[index].taxes.includes(taxValue)) {
      newItems[index].taxes = newItems[index].taxes.filter(tax => tax !== taxValue);
    } else {
      newItems[index].taxes.push(taxValue);
    }
    calculateAmounts(newItems, index);
    setItems(newItems);
    console.log('Selected Tax Option:', taxOption);
  };
  const handleDoneClick = () => {
    setShowTaxDropdown(false);
    setSelectedItemIndex(null);
  };
  const handleUnitTypeClick = (index) => {
    setSelectedItemIndex(index);
    setShowUnitTypeDropdown(true);
    fetch('https://api.myntask.com/product/unit_types', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('Unit Types API Response:', data);
        setUnitTypes(data);
      })
      .catch(error => console.error('Error fetching unit types:', error));
  };
  const handleUnitTypeSelect = (index, unitType) => {
    const newItems = [...items];
    newItems[index].unitType = unitType;
    setItems(newItems);
    setShowUnitTypeDropdown(false);
  };
  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    // Calculate amounts after updating the item
    calculateAmounts(newItems, index);
    // Update the items state
    setItems(newItems);
  };

  const calculateAmounts = (newItems, index) => {
    const quantity = parseFloat(newItems[index].quantity) || 0;
    const unitPrice = parseFloat(newItems[index].unitPrice) || 0;
    const discountPercentage = parseFloat(newItems[index].discount) || 0;
    const taxTotalPercentage = newItems[index].taxes.reduce((acc, tax) => acc + extractTaxPercentage(tax), 0);


    const amountWithoutTax = quantity * unitPrice;
    const discountAmount = amountWithoutTax * (discountPercentage / 100);
    const amountAfterDiscount = amountWithoutTax - discountAmount;
    const taxAmount = amountAfterDiscount * taxTotalPercentage;


    newItems[index].totalAmount = amountAfterDiscount + taxAmount;
    newItems[index].subtotal = amountAfterDiscount; // Save subtotal excluding tax


    // Debugging the subtotal calculation
    console.log(`Item ${index} - Subtotal: ${newItems[index].subtotal}`);
  };






  const handleSave = () => {
    // Use the current state of items
    const updatedItems = [...items];


    updatedItems.forEach((item, index) => {
      console.log(`Item ${index} subtotal before saving:`, item.subtotal);
    });


    const subTotal = updatedItems.reduce((total, item) => {
      return total + (parseFloat(item.subtotal) || 0); // Aggregate subtotal for all items
    }, 0).toFixed(2);


    console.log("Calculated Subtotal:", subTotal); // Ensure the subtotal is correct




    const total = updatedItems.reduce((total, item) => {
      return total + (parseFloat(item.totalAmount) || 0);
    }, 0).toFixed(2);


    console.log("Calculated Subtotal:", subTotal); // Check if the subtotal is calculated correctly

    const purchaseOrderData = {
      purchase_order_number: '',
      vendor_company_id: parseInt(selectedVendor),
      purchase_date: orderDate ? orderDate.toISOString().split('T')[0] : '',
      expected_delivery_date: expectedDeliveryDate ? expectedDeliveryDate.toISOString().split('T')[0] : '',
      address_id: parseInt(selectedShippingAddress),
      calculate_tax: selectedOption,
      delivery_status: status.toLowerCase().replace(' ', '_'),
      note: '',
      sub_total: subTotal, // Ensure this is the calculated subtotal
      total: total,
      discount: 0.00,
      discount_type: 'percent',
      items: updatedItems.map(item => ({
        item_name: item.name,
        quantity: parseFloat(item.quantity),
        product_id: item.product_id || '',
        hsn_code: item.hsn || '',
        unit_price: parseFloat(item.unitPrice) || 0,
        unit_id: '3',
        item_summary: item.description || '',
        amount: parseFloat(item.totalAmount) || 0,
        tax_id: item.taxes.map(tax => {
          const taxOption = taxOptions.find(option => option.tax_name === tax);
          return taxOption ? taxOption.id : null;
        }).filter(id => id !== null)
      }))
    };


    console.log('Purchase Order Data:', purchaseOrderData); // Debug before sending

    fetch('https://api.myntask.com/purchase_order/save', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(purchaseOrderData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('API Response:', data);
      })
      .catch(error => {
        console.error('Error saving order:', error);
      });
  };








  const handleCancel = () => {
    window.history.back();
  };




  return (
    <div className="add-order-screen">
      <h2>Add Purchase Order</h2>
      <div className="add-order-body">
        <input type="text" className="input-field" placeholder="Order No." />
  
        {/* Vendor Dropdown */}
        <select
          className="input-field"
          value={selectedVendor}
          onChange={(e) => setSelectedVendor(e.target.value)}
        >
          <option value="">Select Vendor</option>
          {vendors.map((vendor) => (
            <option key={vendor.id} value={vendor.id}>
              {vendor.company_name}
            </option>
          ))}
        </select>
  
        {/* Shipping Address Dropdown */}
        <select
          className="input-field"
          value={selectedShippingAddress}
          onChange={(e) => setSelectedShippingAddress(e.target.value)}
        >
          <option value="">Select Shipping Address</option>
          {shippingAddresses.map((address) => (
            <option key={address.id} value={address.id}>
              {address.address}
            </option>
          ))}
        </select>
  
        {/* Order Date Picker */}
        <DatePicker
          selected={orderDate}
          onChange={(date) => setOrderDate(date)}
          className="input-field"
          placeholderText="Order Date"
        />
  
        {/* Expected Delivery Date Picker */}
        <DatePicker
          selected={expectedDeliveryDate}
          onChange={(date) => setExpectedDeliveryDate(date)}
          className="input-field"
          placeholderText="Expected Delivery Date"
        />
  
        {/* Tax Calculation Option */}
        <div style={{ position: 'relative', width: '200px' }}>
          <div
            onClick={toggleDropdown}
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              cursor: 'pointer',
              borderRadius: '4px'
            }}
          >
            {selectedOption}
          </div>
  
          {isDropdownOpen && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: '0',
                border: '1px solid #ccc',
                backgroundColor: '#fff',
                borderRadius: '4px',
                width: '100%',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                zIndex: 1000
              }}
            >
              <div
                onClick={() => handleOptionSelect('after_discount')}
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #ccc'
                }}
              >
                after_discount
              </div>
              <div
                onClick={() => handleOptionSelect('before_discount')}
                style={{
                  padding: '10px',
                  cursor: 'pointer'
                }}
              >
                before_discount
              </div>
            </div>
          )}
        </div>
  
        {/* Delivery Status */}
        <div style={{ position: 'relative', width: '200px' }}>
          <div
            onClick={handleToggle}
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              cursor: 'pointer',
              backgroundColor: '#f9f9f9'
            }}
          >
            {status}
          </div>
          {isOpen && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: '#fff',
                zIndex: 1000
              }}
            >
              <div
                onClick={() => handleSelect('Delivered')}
                style={{ padding: '10px', cursor: 'pointer', borderBottom: '1px solid #ccc' }}
              >
                delivered
              </div>
              <div
                onClick={() => handleSelect('Delivered Failed')}
                style={{ padding: '10px', cursor: 'pointer', borderBottom: '1px solid #ccc' }}
              >
                delivered_failed
              </div>
              <div
                onClick={() => handleSelect('In Transaction')}
                style={{ padding: '10px', cursor: 'pointer', borderBottom: '1px solid #ccc' }}
              >
                in_transaction
              </div>
              <div
                onClick={() => handleSelect('Not Started')}
                style={{ padding: '10px', cursor: 'pointer' }}
              >
                not_started
              </div>
            </div>
          )}
        </div>
  
        {/* Items Section */}
        {items.map((item, index) => (
          <div className="item-container" key={index}>
            <select
              className="input-field"
              value={item.selectedProduct} // Use item.selectedProduct
              onChange={(e) => handleProductChange(index, e.target.value)} // Pass index here
              style={{ width: '400px' }}
            >
              <option value="">Select Product</option>
              {products.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </select>
  
            {/* Quantity and Unit Type */}
            <div className="quantity-container">
              <input
                type="number"
                className="input-field"
                placeholder="Quantity"
                value={item.quantity}
                onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
              />
              <button
                className="quantity-in-button"
                onClick={() => handleUnitTypeClick(index)}
              >
                {item.unitType || 'Pcs'}
              </button>
              {showUnitTypeDropdown && selectedItemIndex === index && (
                <div className="unit-type-dropdown">
                  {unitTypes.length > 0 ? (
                    unitTypes.map((unitType) => (
                      <div
                        key={unitType.id}
                        className={`unit-type-option ${item.unitType === unitType.unit_type ? 'selected' : ''}`}
                        onClick={() => handleUnitTypeSelect(index, unitType.unit_type)}
                      >
                        {unitType.unit_type}
                      </div>
                    ))
                  ) : (
                    <div>No unit types available</div>
                  )}
                </div>
              )}
            </div>
  
            {/* Unit Price and Tax */}
            <input
              type="text"
              className="input-field"
              placeholder="Unit Price"
              value={item.unitPrice}
              onChange={(e) => handleItemChange(index, 'unitPrice', e.target.value)}
            />
            <input
              type="text"
              className="input-field"
              placeholder="Tax"
              value={item.taxes.join(', ')} // Display selected taxes as a comma-separated list
              onClick={() => handleTaxFieldClick(index)}
              readOnly
              style={{ cursor: 'pointer' }}
            />
            {showTaxDropdown && selectedItemIndex === index && (
              <div className="tax-dropdown">
                {taxOptions.map((taxOption) => (
                  <div
                    key={taxOption.id}
                    className="tax-option"
                    onClick={() => handleTaxSelect(index, taxOption)}
                  >
                    <input
                      type="checkbox"
                      checked={item.taxes.includes(taxOption.tax_name)}
                      onChange={() => handleTaxSelect(index, taxOption)}
                    />
                    {taxOption.tax_name}
                  </div>
                ))}
                <button onClick={handleDoneClick} className="done-button">Done</button>
              </div>
            )}
  
            {/* Image Upload and Amount */}
            <input
              type="file"
              className="input-field"
              onChange={(e) => handleImageChange(index, e)}
            />
            <input
              type="text"
              className="input-field"
              placeholder="Amount"
              value={item.totalAmount ? item.totalAmount.toFixed(2) : '0.00'} // Ensure valid number
              readOnly
            />
          </div>
        ))}
  
        {/* Add Item Button */}
        <button className="add-item-button" onClick={handleAddItem}>Add Item</button>
  
        {/* Subtotal and Total */}
        <div className="subtotal-amount">
          <strong>Subtotal: </strong>
          {items.reduce((subtotal, item) => {
            const quantity = parseFloat(item.quantity) || 0;
            const unitPrice = parseFloat(item.unitPrice) || 0;
            const discountPercentage = parseFloat(item.discount) || 0;
            const taxTotalPercentage = item.taxes.reduce((acc, tax) => acc + extractTaxPercentage(tax), 0);
  
            const amountWithoutTax = quantity * unitPrice;
            const discountAmount = amountWithoutTax * (discountPercentage / 100);
            const amountAfterDiscount = amountWithoutTax - discountAmount;
  
            return subtotal + amountAfterDiscount;
          }, 0).toFixed(2)}
        </div>
  
        <div className="total-amount">
          <strong>Total Amount: </strong>
          {items.reduce((total, item) => total + (item.totalAmount || 0), 0).toFixed(2)}
        </div>
  
        {/* Action Buttons */}
        <div className="action-buttons">
          <button className="save-button" onClick={handleSave}>Save</button>
          <button className="cancel-button" onClick={handleCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
  
}
export default AddOrder;








