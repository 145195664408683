import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './ClientDashboard.css'; // Import CSS for styling

// Importing all tab components
import ClientProfile from '../Components/ClientProfile';
import ClientProjects from '../Components/ClientProjects';
import ClientInvoices from '../Components/ClientInvoices';
// import Estimates from './ClinetDashboard/Estimates';
// import CreditNote from './ClinetDashboard/CreditNote'; // Assuming you have CreditNote.js
// import Payments from './ClinetDashboard/Payments';
// import Contacts from './ClinetDashboard/Contacts';
// import Documents from './ClinetDashboard/Documents';
// import Notes from './ClinetDashboard/Notes';

const ClientDashboard = () => {
  const { clientId } = useParams(); // Extract clientId from the URL
  const [activeTab, setActiveTab] = useState("Profile");

  // Handle Tab Change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Determine which component to render based on the active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case "Profile":
        return <ClientProfile clientId={clientId} />;
      case "Projects":
        return <ClientProjects clientId={clientId} />;
      case "Invoices":
        return <ClientInvoices clientId={clientId} />;
    //   case "Estimates":
    //     return <Estimates clientId={clientId} />;
    //   case "Credit Note":
    //     return <CreditNote clientId={clientId} />;
    //   case "Payments":
    //     return <Payments clientId={clientId} />;
    //   case "Contacts":
    //     return <Contacts clientId={clientId} />;
    //   case "Documents":
    //     return <Documents clientId={clientId} />;
    //   case "Notes":
    //     return <Notes clientId={clientId} />;
    //   default:
    //     return <Profile clientId={clientId} />;
    }
  };

  return (
    <div className="client-dashboard-container">
      <div className="dashboard-header">
        <h1>Support CMA</h1>
        <p>Home • Clients • Support CMA</p>
      </div>
      <div className="tabs-container">
        {["Profile", "Projects", "Invoices", "Estimates", "Credit Note", "Payments", "Contacts", "Documents", "Notes"].map((tab) => (
          <div
            key={tab}
            className={`tab-item ${activeTab === tab ? "active-tab" : ""}`}
            onClick={() => handleTabChange(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default ClientDashboard;
