// src/ClientVisit.js

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

const ClientVisit = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [image, setImage] = useState(null); // Store the image file object
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');  
  const [clientVisits, setClientVisits] = useState([]); // State to store client visit data
  const [loading, setLoading] = useState(true);


  // Function to open the modal
  const openModal = () => setIsModalOpen(true);

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);

// Fetch data from API when component mounts or after adding data
const fetchClientVisits = async () => {
    const token = localStorage.getItem('authToken'); // Get token from local storage
    if (!token) {
      alert('Authorization token is missing!');
      return;
    }

    try {
      const response = await fetch('https://api.myntask.com/home/client_visit_list', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch client visit list');
      }

      const data = await response.json();
      setClientVisits(data); // Set the fetched data in the state
      setLoading(false); // Stop loading once data is fetched
    } catch (error) {
      console.error('Error fetching client visits:', error);
     
     
    }
  };

  useEffect(() => {
    fetchClientVisits();
  }, []);


  // Automatically fetch geolocation (latitude, longitude)
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude); // Set the latitude
          setLongitude(longitude); // Set the longitude
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    }
  }, []);


  // Handle form submission using fetch API


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const token = localStorage.getItem('authToken'); // Get token from local storage
  
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authorization Error',
        text: 'Authorization token is missing!',
      });
      return;
    }
  
    const formData = new FormData();
    formData.append('client_name', clientName);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);
  
    // Append the image file if one is selected
    if (image) {
      formData.append('image', image);
    }
  
    try {
      const response = await fetch('https://api.myntask.com/home/client_visit', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      const responseText = await response.text(); // Get the response as text
      console.log('API Response:', responseText);
  
      // If the response is okay (status code 200-299)
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Client visit added successfully!',
        });
        closeModal(); // Close modal after successful submission
  
        // Fetch the updated client visits after successful submission
        fetchClientVisits();  // Automatically refetch the data
  
      } 
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add client visit!',
      });
    }
  };
  
  

  // Handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Set the selected file
    }
  };

  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>
          Client Visit
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={openModal}>
            Add client visit
          </button>
        </div>
      </div>

      <table>
  <thead>
    <tr>
      <th>User Name</th>
      <th>Visited On</th>
      <th>Client Name</th>  
      <th>Location</th>
      <th>Image</th>
    </tr>
  </thead>
  <tbody>
    {clientVisits.length > 0 ? (
      clientVisits.map((visit, index) => (
        <tr key={index}>
          <td>{visit.user_name}</td>
          <td style={{ textAlign: 'center' }}>{visit.created_at}</td>
          <td>{visit.client_name}</td>
          <td style={{ textAlign: 'center' }}>
            <a href={visit.location} target="_blank" rel="noopener noreferrer">
              View Location
            </a>
          </td>
          <td style={{ textAlign: 'center' }}>
            {visit.image ? (
              <a href={visit.image} target="_blank" rel="noopener noreferrer">
                View Image
              </a>
            ) : (
              'No Image'
            )}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>
          No client visits available.
        </td>
      </tr>
    )}
  </tbody>
</table>



      {/* Modal */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Add Client Visit</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="clientName">Client Name</label>
                <input
                  type="text"
                  id="clientName"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  placeholder="Enter client name"
                  required
                />
              </div>

              {/* Image upload */}
              <div className="form-group">
                <label htmlFor="image">Select Image</label>
                <input
                  type="file"
                  id="image"
                  onChange={handleImageChange} // Handle the file change
                  accept="image/*" // Restrict to images only
                />
              </div>

              <button type="submit" className='color-button'>Save Visit</button>
              <button type="button" onClick={closeModal} className="color-button">
                Close
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientVisit;
