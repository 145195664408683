import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const GanttChart = () => {
  const { projectId } = useParams();
  const [ganttData, setGanttData] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGanttData = async () => {
      const token = localStorage.getItem('authToken');
      if (!projectId) {
        console.error('Project ID is not available');
        return;
      }

      try {
        // Fetch Gantt Chart Data
        const ganttResponse = await fetch(`https://api.myntask.com/project/gantt_chart/${projectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const ganttData = await ganttResponse.json();
        console.log('Gantt chart data:', ganttData);

        // Fetch Milestones Data
        const milestoneResponse = await fetch(`https://api.myntask.com/project/milestone_list/${projectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const milestoneData = await milestoneResponse.json();
        console.log('Milestones data:', milestoneData);

        setGanttData(ganttData);
        setMilestones(milestoneData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Gantt chart data:', error);
      }
    };

    fetchGanttData();
  }, [projectId]);

  if (loading) {
    return <Typography>Loading Gantt chart...</Typography>;
  }

  const renderMilestones = () => {
    return milestones.map((milestone) => (
      <MenuItem key={milestone.id} value={milestone.id}>
        {milestone.milestone_title}
      </MenuItem>
    ));
  };

  const renderGanttTasks = () => {
    return ganttData.map((task) => (
      <Box key={task.task_id} display="flex" mb={1}>
        <Box width="200px">
          <Typography variant="body2">{task.task}</Typography>
          <Typography variant="caption" color="textSecondary">
            {task.start_date} - {task.due_date}
          </Typography>
        </Box>
        <Box
          style={{
            flex: 1,
            marginLeft: '20px',
            position: 'relative',
            background: 'lightgray',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              position: 'absolute',
              left: calculateTaskPosition(task.start_date),
              width: calculateTaskWidth(task.start_date, task.due_date),
              backgroundColor: task.status === 'Completed' ? 'green' : 'blue',
              height: '100%',
            }}
          />
        </Box>
      </Box>
    ));
  };

  const calculateTaskPosition = (startDate) => {
    // Convert start date to a position on the timeline
    const start = new Date(startDate).getTime();
    const chartStartDate = new Date('2024-07-01').getTime(); // Example start date for the chart
    const totalDays = (start - chartStartDate) / (1000 * 60 * 60 * 24);
    return `${totalDays * 10}px`; // Scale factor for positioning
  };

  const calculateTaskWidth = (startDate, endDate) => {
    // Convert task duration to width
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const duration = (end - start) / (1000 * 60 * 60 * 24);
    return `${duration * 10}px`; // Scale factor for width
  };

  return (
    <Box p={2} style={{ height: '500px', overflowY: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        Gantt Chart
      </Typography>

      {/* Select Dropdowns */}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <FormControl variant="outlined">
          <InputLabel>Milestones</InputLabel>
          <Select style={{ width: '200px' }} label="Milestones">
            <MenuItem value="">None</MenuItem>
            {renderMilestones()}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Task Status</InputLabel>
          <Select style={{ width: '200px' }} label="Task Status">
            <MenuItem value="Incomplete">Incomplete</MenuItem>
            <MenuItem value="To Do">To Do</MenuItem>
            <MenuItem value="Doing">Doing</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Render Gantt tasks */}
      <Box>
        {renderGanttTasks()}
      </Box>
    </Box>
  );
};

export default GanttChart;
