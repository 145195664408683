import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

function Bom() {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleBomClick = () => {
    console.log('Navigating to /add-bom'); // Debug log
    navigate('/add-bom');
  };
  

  return (
    <div style={{ padding: '20px', position: 'relative' }}>

      <button
        onClick={handleBomClick}
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
        }}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
      >
        Add BOM
      </button>
    </div>
  );
}

export default Bom;
