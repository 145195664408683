import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const LeadProfile = () => {
  const { id } = useParams();
  const [lead, setLead] = useState(null);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('profile');
  const [deals, setDeals] = useState([]);
  const [notice, setNotice] = useState([]);

  useEffect(() => {
    const fetchLeadData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }

      try {
        const response = await fetch(`https://api.myntask.com/leads/lead_view/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch lead data');
        }

        const data = await response.json();
        setLead(data);
      } catch (err) {
        console.error('Error fetching lead data:', err);
        setError('Failed to load lead data.');
      }
    };

    fetchLeadData();
  }, [id]);

  useEffect(() => {
    const fetchNotices = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }

      try {
        const response = await fetch(`https://api.myntask.com/leads/lead_notes/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch notice data');
        }

        const data = await response.json();
        console.log('Notice data:', data);
        setNotice(data);
      } catch (err) {
        console.error('Error fetching notices data:', err);
        setError('Failed to load notice data.');
      }
    };

    const fetchDeals = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }

      try {
        const response = await fetch(`https://api.myntask.com/leads/deal_view/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch deals');
        }

        const data = await response.json();
        console.log('Deals data:', data);
        setDeals(data);
      } catch (err) {
        console.error('Error fetching deals:', err);
        setError('Failed to load deals.');
      }
    };

    if (activeTab === 'notice') {
      fetchNotices();
    } else if (activeTab === 'deals') {
      fetchDeals();
    }

  }, [id, activeTab]);

  const renderProfile = () => (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: '10px', alignItems: 'center' }}>
      {renderProfileItem('Company Name', lead.company_name || 'N/A')}
      {renderProfileItem('Contact Person', lead.client_name)}
      {renderProfileItem('Client Email', lead.client_email)}
      {renderProfileItem('Added By', (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={lead.added_by_image} alt={lead.added_by} style={profileImageStyle} />
          {lead.added_by}
        </div>
      ))}
      {renderProfileItem('Created At', lead.created_at)}
      {renderProfileItem('Type', lead.type)}
      {renderProfileItem('Website', lead.website || 'N/A')}
      {renderProfileItem('Mobile', lead.mobile || 'N/A')}
      {renderProfileItem('Address', lead.address || 'N/A')}
      {renderProfileItem('City', lead.city || 'N/A')}
      {renderProfileItem('State', lead.state || 'N/A')}
      {renderProfileItem('Country', lead.country || 'N/A')}
      {renderProfileItem('Postal Code', lead.postal_code || 'N/A')}
    </div>
  );

  const renderProfileItem = (label, value) => (
    <>
      <p style={profileLabelStyle}>{label}:</p>
      <p style={profileValueStyle}>{value}</p>
    </>
  );

  const renderDeals = () => (
    <div>
      <h2 style={subHeaderStyle}>Deals</h2>
      {deals.length > 0 ? (
        <table style={tableStyle}>
          <thead>
            <tr>
              {['Name', 'Company Name', 'Client Name', 'Client Email', 'Mobile', 'Value', 'Close Date', 'Next Follow Up Date', 'Stage', 'Agent', 'Deal Watcher'].map((header) => (
                <th key={header} style={tableHeaderStyle}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {deals.map(deal => (
              <tr key={deal.id} style={tableRowStyle}>
                <td style={tableCellStyle}>{deal.name}</td>
                <td style={tableCellStyle}>{deal.company_name}</td>
                <td style={tableCellStyle}>{deal.client_name}</td>
                <td style={tableCellStyle}>{deal.client_email}</td>
                <td style={tableCellStyle}>{deal.mobile}</td>
                <td style={tableCellStyle}>{deal.value}</td>
                <td style={tableCellStyle}>{deal.close_date}</td>
                <td style={tableCellStyle}>{deal.next_follow_up_date}</td>
                <td style={tableCellStyle}>{deal.stage}</td>
                <td style={tableCellStyle}>
                  <div style={profileImageContainer}>
                    <img src={deal.agentImage} alt={deal.agent} style={profileImageStyle} />
                    <span>{deal.agent || 'N/A'}</span>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div style={profileImageContainer}>
                    <img src={deal.dealWatcherImage} alt={deal.deal_watcher} style={profileImageStyle} />
                    <span>{deal.deal_watcher || 'N/A'}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p style={noDataStyle}>No deals found for this lead.</p>
      )}
    </div>
  );

  const renderNotice = () => (
    <div>
      <h2 style={subHeaderStyle}>Notice</h2>
      {notice.length > 0 ? (
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Title</th>
              <th style={tableHeaderStyle}>Type</th>
              <th style={tableHeaderStyle}>Created At</th>
            </tr>
          </thead>
          <tbody>
            {notice.map(notice => (
              <tr key={notice.id} style={tableRowStyle}>
                <td style={tableCellStyle}>{notice.title}</td>
                <td style={tableCellStyle}>{notice.type}</td>
                <td style={tableCellStyle}>{notice.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p style={noDataStyle}>No notices available for this lead.</p>
      )}
    </div>
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return renderProfile();
      case 'deals':
        return renderDeals();
      case 'notice':
        return renderNotice();
      default:
        return null;
    }
  };

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!lead) {
    return <p style={loadingStyle}>Loading...</p>;
  }

  return (
    <div style={containerStyle}>
      <h1 style={headerStyle}>Lead Profile</h1>
      <div style={tabContainerStyle}>
        <button onClick={() => setActiveTab('profile')} style={activeTab === 'profile' ? activeTabButtonStyle : tabButtonStyle}>
          Profile
        </button>
        <button onClick={() => setActiveTab('deals')} style={activeTab === 'deals' ? activeTabButtonStyle : tabButtonStyle}>
          Deals
        </button>
        <button onClick={() => setActiveTab('notice')} style={activeTab === 'notice' ? activeTabButtonStyle : tabButtonStyle}>
          Notice
        </button>
      </div>
      <div style={tabContentStyle}>
        {renderTabContent()}
      </div>
    </div>
  );
};

export default LeadProfile;

const containerStyle = {
  padding: '20px',
  fontFamily: 'Arial, sans-serif',
  color: '#333',
};

const headerStyle = {
  fontSize: '28px',
  color: '#4A4A4A',
  marginBottom: '20px',
};

const tabContainerStyle = {
  display: 'flex',
  gap: '10px',
  marginBottom: '20px',
};

const tabButtonStyle = {
  padding: '10px 20px',
  cursor: 'pointer',
  backgroundColor: '#E1E3FF',
  color: '#4A4A4A',
  border: 'none',
  borderRadius: '5px',
  fontWeight: 'bold',
};

const activeTabButtonStyle = {
  ...tabButtonStyle,
  backgroundColor: '#5D5FEF',
  color: '#fff',
};

const tabContentStyle = {
  backgroundColor: '#F3F4FF',
  padding: '20px',
  borderRadius: '8px',
};

const subHeaderStyle = {
  fontSize: '22px',
  marginBottom: '15px',
  color: '#5D5FEF',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  backgroundColor: '#fff',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  borderRadius: '8px',
  overflow: 'hidden',
};

const tableHeaderStyle = {
  padding: '12px 15px',
  backgroundColor: '#5D5FEF',
  color: '#fff',
  fontWeight: 'bold',
  textAlign: 'left',
};

const tableRowStyle = {
  borderBottom: '1px solid #ccc',
};

const tableCellStyle = {
  padding: '10px 15px',
  borderBottom: '1px solid #e6e8f1',
};

const profileLabelStyle = {
  fontWeight: 'bold',
  fontSize: '14px',
  color: '#4A4A4A',
};

const profileValueStyle = {
  fontSize: '14px',
  color: '#6B7280',
};

const profileImageStyle = {
  width: '30px',
  borderRadius: '50%',
  marginRight: '10px',
};

const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};

const noDataStyle = {
  fontSize: '14px',
  color: '#6B7280',
};

const loadingStyle = {
  fontSize: '16px',
  color: '#6B7280',
};
