
// src/screens/LeaveReport.js
import React, { useEffect, useState } from 'react';


const LeaveReport = () => {
  const [leaveData, setLeaveData] = useState([]);


  useEffect(() => {
    // Fetch the token from localStorage
    const token = localStorage.getItem('authToken');


    // Fetch data from the API
    fetch('https://api.myntask.com/report/leave', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setLeaveData(data);
      })
      .catch(error => {
        console.error('Error fetching the leave report:', error);
      });
  }, []);


  return (
    <div className='main-container'>
      <div className="top-controls">
       <h1 className='title'>Leave Report
         <div className="underline"></div>
       </h1>
     </div>
      <table >
        <thead>
          <tr>
            <th>Team Member</th>
            <th>Approved Leaves</th>
            <th>Pending Leaves</th>
          </tr>
        </thead>
        <tbody>
          {leaveData.map((user, index) => (
            <tr key={user.user_id}>
              <td>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft:'10px' }}>
          <img
            src={user.user_image || 'default-image-url.jpg'}
            alt={user.user_name ? user.user_name : 'user'}
            className="image"
          />
          <div>
            <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>
              {user.user_name || 'N/A'}
            </span>
            <p style={{ margin: 0, fontSize: '12px', color: '#777' }}>
              {user.designaton || 'N/A'}
            </p>
          </div>
        </div>
      </td>
              <td style={{textAlign:'center'}}>{user.approved_leaves !== null ? user.approved_leaves : 'N/A'}</td>
              <td style={{textAlign:'center'}}>{user.pending_leaves !== null ? user.pending_leaves : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export default LeaveReport;