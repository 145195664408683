import React, { useEffect, useState } from 'react';
import './ProfileSetting.css'; // Import the CSS file

const ProfileSetting = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    language: '',
    receive_email_notification: false,
    enable_rtl_theme: false,
    profile_picture: '',
    password: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Correct the key
        console.log('Retrieved token:', token);

        if (!token) {
          throw new Error('Token not found');
        }

        const response = await fetch('https://api.myntask.com/auth/profile_setting', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setProfileData(data);
        console.log('Profile Data:', data);
      } catch (error) {
        setError(error.message);
        console.error('There was a problem with the fetch operation:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setProfileData((prevData) => ({
          ...prevData,
          profile_picture: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('Token not found');
      }

      const updateData = {
        name: profileData.name,
        email: profileData.email,
        email_notifications: profileData.receive_email_notification ? 1 : 0,
        rtl: profileData.enable_rtl_theme ? 1 : 0,
        locale: profileData.language,
        password: profileData.password,
      };

      const response = await fetch('https://api.myntask.com/auth/profile_setting_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Updated Profile Data:', data);
      alert('Profile updated successfully');
    } catch (error) {
      setError(error.message);
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  if (loading) {
    return <div className="profile-container">Loading...</div>;
  }

  if (error) {
    return <div className="profile-container">Error: {error}</div>;
  }

  return (
    <div className="profile-container">
      <h1>Profile Setting</h1>
      {profileData ? (
        <div className="profile-details">
          <div className="profile-picture">
            <img src={selectedImage || profileData.profile_picture} alt="Profile" />
            <input type="file" accept="image/*" onChange={handleImageChange} className="image-input" />
          </div>
          <div className="profile-info">
            <label>
              <strong>Name:</strong>
              <input
                type="text"
                name="name"
                value={profileData.name}
                onChange={handleChange}
                className="profile-input"
              />
            </label>
            <label>
              <strong>Email:</strong>
              <input
                type="email"
                name="email"
                value={profileData.email}
                onChange={handleChange}
                className="profile-input"
              />
            </label>
            <label>
              <strong>Language:</strong>
              <input
                type="text"
                name="language"
                value={profileData.language}
                onChange={handleChange}
                className="profile-input"
              />
            </label>
            <label>
              <strong>Receive Email Notifications:</strong>
              <input
                type="checkbox"
                name="receive_email_notification"
                checked={profileData.receive_email_notification}
                onChange={handleChange}
              />
            </label>
            <label>
              <strong>RTL Theme Enabled:</strong>
              <input
                type="checkbox"
                name="enable_rtl_theme"
                checked={profileData.enable_rtl_theme}
                onChange={handleChange}
              />
            </label>
            <label>
              <strong>Password:</strong>
              <input
                type="password"
                name="password"
                value={profileData.password}
                onChange={handleChange}
                className="profile-input"
              />
            </label>
          </div>
          <button className="save-button" onClick={handleSave}>Save</button>
        </div>
      ) : (
        <p>No profile data available</p>
      )}
    </div>
  );
};

export default ProfileSetting;
