import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import noDataImage from '../assets/vector.jpeg'; // Adjust path based on your folder structure
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import '../Components/Main.css';

function Product() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const productsPerPage = 20; // Show 20 products per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      const token = localStorage.getItem('authToken');

      if (!token) {
        setError('No token found. Please log in again.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://api.myntask.com/product/list', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok, status: ${response.status}`);
        }

        const data = await response.json();
        setProducts(data); // Set the products state
      } catch (error) {
        setError('Failed to fetch products.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Filter products by search term
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle search input change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };


  // Function to handle navigation to AddProduct screen
  const handleAddProduct = () => {
    navigate('/add-product');
  };


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;


  const truncateName = (name) => {
    const words = name.split(' '); // Split the name into an array of words
    return words.length > 10 ? `${words.slice(0, 10).join(' ')}...` : name; // Join the first 10 words and add ellipsis
  };
  

  const toTitleCase = (str) => {
    if (!str) return 'N/A';
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  

  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Product
          <div className="underline"></div>
        </h1>

        <div className="buttons-row">
          <button className="color-button" onClick={handleAddProduct}>
            Add Product
          </button>
        </div>
      </div>

      <div className="top-controls">
        <div className="filters-and-search">
          <input
            type="text"
            className="searchbar"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>

      {currentProducts.length > 0 ? (
        <>
           <table>
  <thead>
    <tr>
     
      <th>Name</th>
      <th>Status</th>
      <th>Unit Type</th>
      <th>Client Can Purchase</th>
      <th>Price with Taxes</th>
    </tr>
  </thead>
  <tbody>
    {currentProducts.map((product) => (
      <tr key={product.id}>
       
        <td>
          <span className="tooltip" data-tooltip={product.name}>
            {truncateName(toTitleCase(product.name) || 'N/A')}
          </span>
        </td>
        <td style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <div
            className="status-container"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: product.status.toLowerCase() === 'active' ? 'green' : 'red',
                marginRight: '5px',
              }}
            ></span>
            <span>{toTitleCase(product.status || 'N/A')}</span> {/* Title case for status */}
          </div>
        </td>
        <td style={{textAlign:'center'}}>{toTitleCase(product.unit_type || 'N/A')}</td> {/* Title case for unit type */}
        <td style={{textAlign:'center'}}>{product.client_can_purchase ? 'Yes' : 'No'}</td>
        <td style={{textAlign:'center'}}>{product.price_with_taxes || 'N/A'}</td> {/* Assuming this may also need title case */}
      </tr>
    ))}
  </tbody>
          </table>

          {/* Pagination with MUI */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Stack spacing={2} >
            <Pagination
              count={totalPages} // Total number of pages
              page={currentPage} // Current page
              onChange={(event, page) => paginate(page)} // Handle page change
              color="primary"
              
            />
          </Stack>
          </div>
        </>
      ) : (
        <div className="no-data-container">
          <img src={noDataImage} alt="No data available" className="no-data-image" />
        </div>
      )}
    </div>
  );
}

export default Product;
