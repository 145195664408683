import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";




const ClientEdit= () => {
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const navigate = useNavigate();
  console.log("client id is",clientId)
  useEffect(() => {
    const fetchClient = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(
          `https://api.myntask.com/client/edit/${clientId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );




        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }




        const data = await response.json();
        setClient(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };




    fetchClient();
  }, [clientId]);




  useEffect(() => {
    // Fetch country list from the API
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://api.myntask.com/home/country_list"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch country list");
        }
        const data = await response.json();
        setCountries(data); // Assuming the API returns an array of countries




        // Assuming each country object has a `phonecode` property
        const codes = data.map((country) => ({
          id: country.id,
          code: country.phonecode,
          name: country.name,
        }));
        setCountryCodes(codes);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };




    // Fetch client categories from the API
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("authToken"); // Get the token from local storage
        const response = await fetch(
          "https://api.myntask.com/client/category",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch client categories");
        }
        const data = await response.json();
        setCategories(data); // Assuming the API returns an array of categories
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };




    fetchCountries();
    fetchCategories();
  }, []);




  const fetchSubCategories = async (categoryId) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(
        `https://api.myntask.com/client/sub_category/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
     
      if (!response.ok) {
        throw new Error("Failed to fetch client sub-categories");
      }
      const data = await response.json();
      console.log(data);
      setSubCategories(data); // Assuming the API returns an array of sub-categories
    } catch (error) {
      console.error("Error fetching sub-categories:", error);
    }
  };
 




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };




  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    const selectedCountry = countryCodes.find(
      (country) => country.id === parseInt(countryId, 10)
    );
 
    setClient((prevState) => ({
      ...prevState,
      country_id: countryId,
      phonecode: selectedCountry ? selectedCountry.code : "", // Set phone code here
    }));
  };
 
  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setClient((prevState) => ({
      ...prevState,
      category_id: categoryId,
   
    }));
    fetchSubCategories(categoryId);
  };
 




  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setClient((prevData) => ({
      ...prevData,
      [name]: parseInt(value)
    }));
  };




  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setClient((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };




  const handleSubCategoryChange = (e) => {
    const subCategoryId = e.target.value;
    setClient((prevState) => ({ ...prevState, sub_category_id: subCategoryId }));
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
 
    const clientData = {
      client_id: client.client_id,
      user_id: client.user_id,
      client_name: client.client_name,
      company_name: client.company_name,
      status: client.status || "active",
      gender: client.gender,
      salutation: client.salutation,
      login: client.login,
      address: client.company_address,
      shipping_address: client.shipping_address,
      postal_code: client.postal_code,
      state: client.state,
      city: client.city,
      office_phone: client.office_phone,
      mobile: client.mobile,
      electronic_address: client.electronic_address,
      electronic_address_scheme: client.electronic_address_scheme,
      email_notifications: client.email_notifications,
      website: client.website,
      note: client.note,
      linkedin: client.linkedin || "",
      facebook: client.facebook || "",
      twitter: client.twitter || "",
      skype: client.skype || "",
      tax_name: client.tax_name,
      gst_number: client.gst_number,
      category_id: client.category_id,
      sub_category_id: client.client_sub_category,
      added_by: '',
    };
 
    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch("https://api.myntask.com/client/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(clientData),
       
      });
     
      if (!response.ok) {
        throw new Error("Failed to update client data");
      }
 
      const result = await response.json();
      console.log("Response from server:", result);
 
      // Optionally, re-fetch client data to ensure UI reflects the latest information
      // fetchClient();
 
      navigate("/clients");
    } catch (error) {
      console.error("Error updating client:", error);
      setError(error.message);
    }
  };
 
 




  if (loading) {
    return <p>Loading...</p>;
  }




  if (error) {
    return <p>Error: {error}</p>;
  }




  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Edit Client
          <div className="underline"></div>
        </h1>
      </div>


      {client && (
        <div>
          <section className="section">
          <h3 className="sectionTitle">Account Details</h3>
          <div className="formRow">
          <div className="formColumn">
            <div className="formGroup">
              <label className="label">Salutation:</label>
              <input
                type="text"
                name="salutation"
                value={client.salutation || ""}
                onChange={handleInputChange}
                 className="input"
              />
            </div>
            <div className="formGroup">
              <label className="label">Client Name:</label>
              <input
                type="text"
                name="client_name"
                value={client.client_name || ""}
                onChange={handleInputChange}
                 className="input"
              />
            </div>
            <div className="formGroup">
              <label className="label">Email:</label>
              <input
                type="email"
                name="email"
                value={client.email || ""}
                onChange={handleInputChange}
                 className="input"
              />
            </div>
            <div className="formGroup">
  <label className="label">Country:</label>
  <select
    name="country_id"
    value={client.country_id || ""}
    onChange={handleCountryChange}
     className="input"
  >
    <option value="">Select Country</option>
    {countries.map((country) => (
      <option key={country.id} value={country.id}>
        {country.name}
      </option>
    ))}
  </select>
</div>  
            <div className="formGroup">
              <label className="label">Phone Code:</label>
              <input
                type="text"
                name="phonecode"
                value={
                  countryCodes.find((code) => code.id === client.country_id)
                    ?.code || ""
                }
                onChange={handleCountryChange}
                 className="input"
                readOnly
              />
            </div>
            <div className="formGroup">
              <label className="label">Mobile:</label>
              <input
                type="text"
                name="mobile"
                value={client.mobile || ""}
                onChange={handleInputChange}
                 className="input"
              />
            </div>
            </div>
            <div className="formColumn">
            <div className="formGroup">
              <label className="label">Gender:</label>
              <select
                name="gender"
                value={client.gender || ""}
                onChange={handleInputChange}
                 className="input"
              >
                <option value="">Select Gender</option>
                <option value="female">Female</option>
                <option value="male">Male</option>
                <option value="others">Others</option>
              </select>
            </div>
            <div className="formGroup">
              <label className="label">Language:</label>
              <input
                type="text"
                name="language"
                value={client.language || ""}
                onChange={handleInputChange}
                 className="input"
              />
            </div>
            <div className="formGroup">
  <label className="label">Client Category:</label>
  <select
    name="category_id"
    value={client.category_id || ""}
    onChange={handleCategoryChange}
     className="input"
  >
    <option value="">Select Category</option>
    {categories.map((category) => (
      <option key={category.category_id} value={category.category_id}>
        {category.category_name}
      </option>
    ))}
  </select>
            </div>
            <div className="formGroup">
  <label className="label">Client Sub-Category:</label>
  <select
    name="sub_category_id"
    value={client.sub_category_id}
    onChange={handleSubCategoryChange}
     className="input"
  >
    <option value="">Select Sub-Category</option>
    {subCategories.map((subCategory) => (
      <option key={subCategory.sub_category_id} value={subCategory.sub_category_id}>
        {subCategory.category_name}
      </option>
    ))}
  </select>
            </div>
       
            <div className="formGroup">
              <label className="label">Login Allowed:</label>
              <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between',width:'60%'}}>
                <label >
                  <input
                    type="radio"
                    name="login"
                    value="enable"
                    checked={client.login === "enable"}
                    onChange={handleLoginChange}
                  />
                  Enable
                </label>
               <label className="label">
                  <input
                    type="radio"
                    name="login"
                    value="disable"
                    checked={client.login === "disable"}
                    onChange={handleLoginChange}
                  />
                  Disable
                </label>
              </div>
            </div>
            <div className="formGroup">
            <label className="label">Receive Email Notification:</label>
            <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between',width:'60%'}}>
   <label className="label">
      <input
        type="radio"
        name="email_notifications"
        value="0"
        checked={client.email_notifications === "0" || client.email_notifications === 0}
        onChange={handleRadioChange}
      />
      Enable
    </label>
   <label className="label">
      <input
        type="radio"
        name="email_notifications"
        value="1"
        checked={client.email_notifications === "1" || client.email_notifications === 1}
        onChange={handleRadioChange}
      />
      Disable
    </label>
  </div>
           </div>
           </div>
           </div>
           </section>
       




           <section className="section">
      <h3 className="sectionTitle">Company Details</h3>
   
      <div className="formRow">
      <div className="formColumn">
         <div className="formGroup">
          <label className="label">Company Name:</label>
           <input
             type="text"
             name="company_name"
             value={client.company_name}
             onChange={handleInputChange}
             className="input"
           />
         </div>
         <div className="formGroup">
          <label className="label">Official Website:</label>
           <input
             type="text"
             name="official_website"
             value={client.website}
             onChange={handleInputChange}
             className="input"
           />
         </div>
         <div className="formGroup">
          <label className="label">Tax Name:</label>
           <input
             type="text"
             name="tax_name"
             value={client.tax_name}
             onChange={handleInputChange}
             className="input"
           />
         </div>
         <div className="formGroup">
          <label className="label">GST Number:</label>
           <input
             type="text"
             name="gst_number"
             value={client.gst_number}
             onChange={handleInputChange}
             className="input"
           />
         </div>
           
         <div className="formGroup">
          <label className="label">Office Phone Number:</label>
           <input
             type="text"
             name="office_phone_number"
             value={client.office_phone}
             onChange={handleInputChange}
             className="input"
           />
         </div>
         <div className="formGroup">
          <label className="label">City:</label>
           <input
             type="text"
             name="city"
             value={client.city}
             onChange={handleInputChange}
             className="input"
           />
         </div>
         <div className="formGroup">
          <label className="label">State:</label>
           <input
             type="text"
             name="state"
             value={client.state}
             onChange={handleInputChange}
             className="input"
           />
         </div>
         </div>


         <div className="formColumn">
         <div className="formGroup">
          <label className="label">Postal Code:</label>
           <input
             type="text"
             name="postal_code"
             value={client.postal_code}
             onChange={handleInputChange}
             className="input"
           />
         </div>
           
         <div className="formGroup">
          <label className="label">Company Address:</label>
           <input
             type="text"
             name="company_address"
             value={client.company_address}
             onChange={handleInputChange}
             className="input"
           />
         </div>
         <div className="formGroup">
          <label className="label">Shipping Address:</label>
           <input
             type="text"
             name="shipping_address"
             value={client.shipping_address}
             onChange={handleInputChange}
             className="input"
           />
         </div>
         
         <div className="formGroup">
          <label className="label">Electronic Address:</label>
           <input
             type="text"
             name="electronic_address"
             value={client.electronic_address}
             onChange={handleInputChange}
             className="input"
           />
         </div>
         <div className="formGroup">
          <label className="label">Electronic Address Scheme:</label>
           <input
             type="text"
             name="electronic_address_scheme"
             value={client.electronic_address_scheme}
             onChange={handleInputChange}
             className="input"
           />
         </div>
         <div className="formGroup">
          <label className="label">Note:</label>
           <textarea
             name="note"
             value={client.note}
             onChange={handleInputChange}
             className="input"
           />
         </div>
     
         </div>
       </div>
       </section>


       <button className="color-button" onClick={handleSubmit}>
         Save Client
       </button>
     </div>
      )}
    </div>
  );
};




export default ClientEdit;








