import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto'; // Required for Chart.js

const RecruitDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('authToken');
      const response = await fetch('https://api.myntask.com/recruit/dashboard', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setDashboardData(data);
    };
    fetchData();
  }, []);

  if (!dashboardData) {
    return <div>Loading...</div>;
  }

  const { application_status, total_applications } = dashboardData;

  // Data for Pie Chart
  const pieData = {
    labels: application_status.map((status) => status.status),
    datasets: [
      {
        data: application_status.map((status) => status.total),
        backgroundColor: ['#FFC0CB', '#ADD8E6', '#FFD700', '#90EE90', '#D3D3D3'], // Soft pastel colors
        hoverBackgroundColor: ['#FFB6C1', '#B0E0E6', '#FFD700', '#98FB98', '#C0C0C0'],
      },
    ],
  };

  return (
    <div style={styles.dashboardContainer}>
      <h1 style={styles.header}>Recruit Dashboard</h1>

      {/* Cards Section */}
      <div style={styles.cardContainer}>
        <div style={{ ...styles.card, backgroundColor: '#E3F2FD' }}> {/* Soft Blue */}
          <h3>Total Openings</h3>
          <p style={styles.cardValue}>{dashboardData.total_opening}</p>
        </div>

        <div style={{ ...styles.card, backgroundColor: '#FFF9C4' }}> {/* Soft Yellow */}
          <h3>Total Applications</h3>
          <p style={styles.cardValue}>{dashboardData.total_application}</p>
        </div>

        <div style={{ ...styles.card, backgroundColor: '#FFEBEE' }}> {/* Soft Red */}
          <h3>Total Hired</h3>
          <p style={styles.cardValue}>{dashboardData.total_hired}</p>
        </div>

        <div style={{ ...styles.card, backgroundColor: '#C8E6C9' }}> {/* Soft Green */}
          <h3>Total Rejected</h3>
          <p style={styles.cardValue}>{dashboardData.total_rejected}</p>
        </div>

        <div style={{ ...styles.card, backgroundColor: '#EDE7F6' }}> {/* Soft Purple */}
          <h3>New Applications</h3>
          <p style={styles.cardValue}>{dashboardData.new_application}</p>
        </div>

        <div style={{ ...styles.card, backgroundColor: '#E1F5FE' }}> {/* Soft Light Blue */}
          <h3>Shortlisted Candidate</h3>
          <p style={styles.cardValue}>{dashboardData.shortlisted_candidate}</p>
        </div>

        <div style={{ ...styles.card, backgroundColor: '#FFF3E0' }}> {/* Soft Orange */}
          <h3>Today's Interview</h3>
          <p style={styles.cardValue}>{dashboardData.today_interview}</p>
        </div>
      </div>

      {/* Pie Chart Section */}
      <div style={styles.pieChartContainer}>
        <h3 style={styles.chartHeader}>Application Status</h3>
        <div style={styles.pieChartWrapper}>
          <Pie data={pieData} />
        </div>
      </div>

      {/* Total Applications Table */}
      <div style={styles.applicationsContainer}>
        <h3>Total Applications</h3>
        <table style={styles.applicationsTable}>
          <thead style={styles.applicationsTableHeader}>
            <tr>
              <th style={styles.applicationsTableTh}>Job Title</th>
              <th style={styles.applicationsTableTh}>Total Applications</th>
              <th style={styles.applicationsTableTh}>Applied</th>
              <th style={styles.applicationsTableTh}>Phone Screen</th>
              <th style={styles.applicationsTableTh}>Interview</th>
              <th style={styles.applicationsTableTh}>Hired</th>
              <th style={styles.applicationsTableTh}>Rejected</th>
            </tr>
          </thead>
          <tbody>
            {total_applications.map((job) => (
              <tr key={job.id}>
                <td style={styles.applicationsTableTd}>{job.title}</td>
                <td style={styles.applicationsTableTd}>{job.total_applications}</td>
                <td style={styles.applicationsTableTd}>{job.applied}</td>
                <td style={styles.applicationsTableTd}>{job.phone_screen}</td>
                <td style={styles.applicationsTableTd}>{job.interview}</td>
                <td style={styles.applicationsTableTd}>{job.hired}</td>
                <td style={styles.applicationsTableTd}>{job.rejected}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Inline styles for the updated dashboard
const styles = {
  dashboardContainer: {
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '20px',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    marginBottom: '20px',
  },
  card: {
    flex: '1 1 calc(33.33% - 20px)',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardValue: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: 'green',
  },
  pieChartContainer: {
    marginTop: '30px',
    textAlign: 'center',
  },
  chartHeader: {
    marginBottom: '20px',
    fontSize: '18px',
  },
  pieChartWrapper: {
    width: '350px', // Reduced size of pie chart
    margin: '0 auto',
  },
  applicationsContainer: {
    marginTop: '20px',
  },
  applicationsTable: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  applicationsTableHeader: {
    backgroundColor: '#f1f1f1',
  },
  applicationsTableTh: {
    padding: '10px',
    border: '1px solid #ddd',
  },
  applicationsTableTd: {
    padding: '10px',
    border: '1px solid #ddd',
  },
};

export default RecruitDashboard;
// App.js
// import React, { useState } from 'react';
// import SuccessPopup from '../Components/SuccessPopup';

// function App() {
//   const [isVisible, setIsVisible] = useState(false);

//   const handleButtonClick = () => {
//     setIsVisible(true);
//   };

//   const handleClose = () => {
//     setIsVisible(false);
//   };

//   return (
//     <div>
//       <button onClick={handleButtonClick}>Show Success Popup</button>
//       <SuccessPopup isVisible={isVisible} onClose={handleClose} />
//     </div>
//   );
// }

// export default App;

// import React, { useState } from 'react';

// function PurchaseOrderForm() {
//   const [poNumber, setPoNumber] = useState('');
//   const [vendor, setVendor] = useState('');
//   const [source, setSource] = useState('requisition');
//   const [shippingAddress, setShippingAddress] = useState('');
//   const [items, setItems] = useState([{ id: 1, product: '', quantity: 0, unitPrice: 0 }]);
//   const [tax, setTax] = useState([]);
//   const [orderDate, setOrderDate] = useState('');
//   const [deliveryDate, setDeliveryDate] = useState('');
//   const [termsConditions, setTermsConditions] = useState('');

//   // Function to add a new item row
//   const addItem = () => {
//     const newItem = { id: items.length + 1, product: '', quantity: 0, unitPrice: 0 };
//     setItems([...items, newItem]);
//   };

//   // Function to handle change in item details
//   const handleItemChange = (id, field, value) => {
//     const updatedItems = items.map(item => {
//       if (item.id === id) {
//         return { ...item, [field]: value };
//       }
//       return item;
//     });
//     setItems(updatedItems);
//   };

//   // Function to calculate subtotal, tax, and grand total
//   const calculateTotals = () => {
//     const subtotal = items.reduce((acc, item) => acc + (item.quantity * item.unitPrice), 0);
//     const taxAmount = subtotal * (tax.reduce((acc, rate) => acc + parseInt(rate), 0) / 100);
//     const grandTotal = subtotal + taxAmount;
//     return { subtotal, taxAmount, grandTotal };
//   };

//   const { subtotal, taxAmount, grandTotal } = calculateTotals();

//   return (
//     <div>
//       <h2>Create Purchase Order</h2>
//       <form>
//         {/* Order and Vendor Information */}
//         <div className="section">
//           <h3>Order and Vendor Information</h3>
//           <div className="row">
//             <div className="col">
//               <label htmlFor="poNumber">Purchase Order Number</label>
//               <input
//                 type="text"
//                 id="poNumber"
//                 value={poNumber}
//                 onChange={(e) => setPoNumber(e.target.value)}
//                 placeholder="Enter PO Number"
//                 required
//               />
//             </div>
//             <div className="col">
//               <label htmlFor="vendor">Select Vendor</label>
//               <select id="vendor" value={vendor} onChange={(e) => setVendor(e.target.value)} required>
//                 <option value="">Select Vendor</option>
//                 {/* Add vendor options dynamically */}
//               </select>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col">
//               <label htmlFor="source">Select Source</label>
//               <select id="source" value={source} onChange={(e) => setSource(e.target.value)}>
//                 <option value="requisition">Purchase Requisition</option>
//                 <option value="quotation">Quotation</option>
//               </select>
//             </div>
//             <div className="col">
//               <label htmlFor="shippingAddress">Select Shipping Address</label>
//               <select
//                 id="shippingAddress"
//                 value={shippingAddress}
//                 onChange={(e) => setShippingAddress(e.target.value)}
//                 required
//               >
//                 <option value="">Select Shipping Address</option>
//                 {/* Add addresses dynamically */}
//               </select>
//             </div>
//           </div>
//         </div>

//         {/* Item Selection */}
//         <div className="section">
//           <h3>Items</h3>
//           <div id="itemsSection">
//             {items.map((item) => (
//               <div className="row" key={item.id}>
//                 <div className="col">
//                   <label htmlFor={`item${item.id}`}>Select Product</label>
//                   <select
//                     id={`item${item.id}`}
//                     value={item.product}
//                     onChange={(e) => handleItemChange(item.id, 'product', e.target.value)}
//                   >
//                     <option value="">Select Product</option>
//                     {/* Add product options dynamically */}
//                   </select>
//                 </div>
//                 <div className="col">
//                   <label htmlFor={`quantity${item.id}`}>Quantity</label>
//                   <input
//                     type="number"
//                     id={`quantity${item.id}`}
//                     value={item.quantity}
//                     onChange={(e) => handleItemChange(item.id, 'quantity', e.target.value)}
//                     placeholder="Enter Quantity"
//                     required
//                   />
//                 </div>
//                 <div className="col">
//                   <label htmlFor={`unitPrice${item.id}`}>Unit Price</label>
//                   <input
//                     type="number"
//                     id={`unitPrice${item.id}`}
//                     value={item.unitPrice}
//                     onChange={(e) => handleItemChange(item.id, 'unitPrice', e.target.value)}
//                     placeholder="Enter Unit Price"
//                     required
//                   />
//                 </div>
//               </div>
//             ))}
//           </div>
//           <button type="button" onClick={addItem}>
//             Add Item
//           </button>
//         </div>

//         {/* Tax and Total Calculation */}
//         <div className="section">
//           <h3>Tax and Total</h3>
//           <div className="row">
//             <div className="col">
//               <label htmlFor="tax">Select Tax</label>
//               <select id="tax" multiple value={tax} onChange={(e) => setTax([...e.target.selectedOptions].map(opt => opt.value))}>
//                 <option value="5">5% GST</option>
//                 <option value="12">12% GST</option>
//                 <option value="18">18% GST</option>
//                 <option value="28">28% GST</option>
//               </select>
//             </div>
//             <div className="col">
//               <label htmlFor="orderDate">Order Date</label>
//               <input
//                 type="date"
//                 id="orderDate"
//                 value={orderDate}
//                 onChange={(e) => setOrderDate(e.target.value)}
//                 required
//               />
//             </div>
//             <div className="col">
//               <label htmlFor="deliveryDate">Expected Delivery Date</label>
//               <input
//                 type="date"
//                 id="deliveryDate"
//                 value={deliveryDate}
//                 onChange={(e) => setDeliveryDate(e.target.value)}
//                 required
//               />
//             </div>
//           </div>

//           {/* Display Subtotal, Tax, and Grand Total */}
//           <div className="summary">
//             <p>
//               Subtotal: <span>{subtotal.toFixed(2)}</span>
//             </p>
//             <p>
//               Tax: <span>{taxAmount.toFixed(2)}</span>
//             </p>
//             <p>
//               Grand Total: <span>{grandTotal.toFixed(2)}</span>
//             </p>
//           </div>
//         </div>

//         {/* Terms and Conditions */}
//         <div className="section">
//           <h3>Terms and Conditions</h3>
//           <textarea
//             name="termsConditions"
//             id="termsConditions"
//             value={termsConditions}
//             onChange={(e) => setTermsConditions(e.target.value)}
//             rows="4"
//             placeholder="Enter any terms and conditions"
//           />
//         </div>

//         {/* Buttons */}
//         <div className="section">
//           <button type="submit">Save Purchase Order</button>
//           <button type="reset" style={{ backgroundColor: '#6c757d' }}>
//             Cancel
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default PurchaseOrderForm;


