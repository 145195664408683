
import React, { useEffect, useState } from 'react';
import './Form.css'; // Import the CSS file
import { ToastContainer, toast } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

function InventoryForm() {
    const [categories, setCategories] = useState([]); // To hold the fetched categories
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [newCategory, setNewCategory] = useState({ name: '', code: '' }); // For new category form data
  const [unitTypes, setUnitTypes] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
 


  const token = localStorage.getItem('authToken'); // Get token from localStorage


 const [formData, setFormData] = useState({
   item_mode: 'inventory', // Default value
   name: '',
   code: '',
   under_group: '',
   category_id: '',
   subcategory_id: '',
   type: 'FinishProduct',
   unit_option: 'StockUnit',
   stock_unit: '',
   pack_unit: '',
   packvsstock: '',
   gpackunit: '',
   gpack_pack: '',
   bar_code_prefix: '',
   packet_barcode: '',
   subItem_applicable: false,
   gst_input_not_applicable: false,
   print_barcode: true,
   gst_classification: '',
   item_description: '',
   sales_description: '',
   sales_gl: '',
   sales_mrp: 0,
   sales_minimum_price: 0,
   sales_rate: 0,
   wholesale_Rate: 0,
   dealer_rate: 0,
   rate_factor: 0,
   sales_discount: 0,
   purchase_description: '',
   purchase_gl: '',
   purchase_rate: 0,
   purchase_rate_factor: 0,
   purchase_discount: 0,
   item_workflow: 'Regular',
   procurement_type: 'Purchase',
   minimum_level: 0,
   maximum_level: 0,
   weighscale_mapping_code: '',
   rack_bin_no: '',
   item_set_template: '',
   cvss_app_list: '',
   udf_in_document: false,
 });




 const handleChange = (e) => {
   const { name, value, type, checked } = e.target;
   setFormData({
     ...formData,
     [name]: type === 'checkbox' ? checked : value
   });
 };




 const handleSave = async () => {
   if (!formData.under_group || !formData.name || !formData.code) {
     console.error('Please fill all required fields.');
     alert('Please fill all required fields: Name, Code, and Under Group.');
     return;
   }
    try {
     const token = localStorage.getItem('authToken');
     const response = await fetch('https://api.myntask.com/product/save', {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${token}`
       },
       body: JSON.stringify(formData)
     });
      const result = await response.json();
     console.log('API Response:', result);
   
     // Show success toast
     toast.success('Product saved successfully!');
   } catch (error) {
     console.error('Error saving product:', error);
   
     // Show error toast
     toast.error('Error saving product. Please try again.');
   }
 };


  // Fetch categories when the component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.myntask.com/product/category', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Send token in Authorization header
            'Content-Type': 'application/json',
          },
        });


        if (response.ok) {
          const data = await response.json();
          setCategories(data); // Assuming the data is an array of categories
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };


    const fetchUnitTypes = async () => {
        try {
          const response = await fetch('https://api.myntask.com/product/unit_types', {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
 
          if (response.ok) {
            const data = await response.json();
            setUnitTypes(data); // Assuming the response is an array of unit types
          } else {
            console.error('Failed to fetch unit types');
          }
        } catch (error) {
          console.error('Error fetching unit types:', error);
        }
      };
 
      fetchUnitTypes();
    fetchCategories();


  }, [token]);


   // Function to fetch subcategories based on selected category using fetch
   useEffect(() => {
    if (formData.category_id) {
      const fetchSubcategories = async () => {
        try {
          const response = await fetch(
            `https://api.myntask.com/product/sub_category/${formData.category_id}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`, // Replace with actual token
              },
            }
          );


          if (!response.ok) {
            throw new Error('Error fetching subcategories');
          }


          const data = await response.json();
          setSubcategories(data);
        } catch (error) {
          console.error('Error fetching subcategories:', error);
        }
      };


      fetchSubcategories();
    }
  }, [formData.category_id,token]);


   // Handle new category form submission
   const handleNewCategorySubmit = async (e) => {
    e.preventDefault();
 
    try {
      const response = await fetch('https://api.myntask.com/product/category/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // ensure the token is correct
        },
        body: JSON.stringify({
          name: newCategory.name,
          code: newCategory.code,
        }),
      });
 
      if (response.ok) {
        const data = await response.json();
        console.log('Category added successfully:', data);
        setShowModal(false); // Close modal on success
      } else {
        console.error('Failed to add category');
        alert(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error while adding category:', error);
      alert('Failed to add category, please try again later.');
    }
  };
 


  return (
   <div className="container">
     {/* Basic Information */}
     <section className="section">
 <h3 className="sectionTitle">Basic Information</h3>
 <div className="formRow">
   {/* First Column */}
   <div className="formColumn">
     <div className="formGroup">
       <label className="label">Item Mode *</label>
       <input
         name="item_mode"
         value={formData.item_mode}
         onChange={handleChange}
         className="input"
         disabled
       />
     </div>
     <div className="formGroup">
       <label className="label">Name *</label>
       <input
         name="name"
         value={formData.name}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Code *</label>
       <input
         name="code"
         value={formData.code}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Under Group *</label>
       <input
         name="under_group"
         value={formData.under_group}
         onChange={handleChange}
         className="input"
       />
     </div>


     <div className="formGroup">
  <label className="label">Category</label>
 
  <div className="categoryDropdownContainer">
    <select
      name="category_id"
      value={formData.category_id}
      onChange={handleChange}
      className="inputadd"
    >
      <option value="">Select a category</option>
      {categories.map((category) => (
        <option key={category.id} value={category.id}>
          {category.category_name}
        </option>
      ))}
    </select>
   
    <button
          type="button"
          className="addCategoryButton"
          onClick={() => setShowModal(true)} // Show modal when clicked
        >
          Add
        </button>
  </div>
</div>


 {/* Subcategory dropdown */}
 <div className="formGroup">
 <label className="label">Sub Category</label>
        <select
          name="subcategory_id"
          value={formData.subcategory_id}
          onChange={handleChange}
          className="input"
          disabled={!formData.category_id} // Disable if no category selected
        >
          <option value="">Select a subcategory</option>
          {subcategories.map((subcategory) => (
            <option key={subcategory.id} value={subcategory.id}>
              {subcategory.category_name}
            </option>
          ))}
        </select>
      </div>




     <div className="formGroup">
<label className="label">Type</label>
<select
    name="type"
    value={formData.type}
    onChange={handleChange}
    className="input"
>
    <option value="FinishProduct">Finish Product</option>
    <option value="Consumable">Consumable</option>
    <option value="RawMaterial">Raw Material</option>
    <option value="PackingMaterial">Packing Material</option>
    <option value="Scrap">Scrap</option>
    <option value="SemiFinished">Semi Finished</option>
    <option value="TradeGood">Trade Good</option>
    <option value="ImportedAssets">Imported Assets</option>
    <option value="ServiceWithBOQ">K Service with BOQ</option>
    <option value="BOMBasedItem">BOM Based Item</option>
</select>
</div>


     <div className="formGroup">
       <label className="label">Unit Option</label>
       <input
         name="unit_option"
         value={formData.unit_option}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
        <label className="label">Stock Unit *</label>
        <select
          name="stock_unit"
          value={formData.stock_unit}
          onChange={handleChange}
          className="input"
          required
        >
          <option value="">Select a stock unit</option>
          {unitTypes.map((unitType) => (
            <option key={unitType.id} value={unitType.id}>
              {unitType.unit_type}
            </option>
          ))}
        </select>
      </div>
   </div>




   {/* Second Column */}
   <div className="formColumn">
     <div className="formGroup">
       <label className="label">Pack Unit</label>
       <input
         name="pack_unit"
         value={formData.pack_unit}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Pack vs Stock</label>
       <input
         name="packvsstock"
         value={formData.packvsstock}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Gpack Unit</label>
       <input
         name="gpackunit"
         value={formData.gpackunit}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Gpack Pack</label>
       <input
         name="gpack_pack"
         value={formData.gpack_pack}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Barcode Prefix</label>
       <input
         name="bar_code_prefix"
         value={formData.bar_code_prefix}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Packet Barcode</label>
       <input
         name="packet_barcode"
         value={formData.packet_barcode}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Sub Item Applicable</label>
       <input
         type="checkbox"
         name="subItem_applicable"
         checked={formData.subItem_applicable}
         onChange={handleChange}
         className="checkboxInput"
       />
     </div>
     <div className="formGroup">
       <label className="label">GST Input Not Applicable</label>
       <input
         type="checkbox"
         name="gst_input_not_applicable"
         checked={formData.gst_input_not_applicable}
         onChange={handleChange}
         className="checkboxInput"
       />
     </div>
   </div>
 </div>
</section>








     {/* Sales Information */}
     <section className="section">
       <h3 className="sectionTitle">Sales</h3>
       <div className="formRow">
   {/* First Column */}
   <div className="formColumn">
       <div className="formGroup">
         <label className="label">Sales Description</label>
         <input name="sales_description" value={formData.sales_description} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Sales GL *</label>
         <input name="sales_gl" value={formData.sales_gl} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Sales MRP</label>
         <input name="sales_mrp" value={formData.sales_mrp} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Sales Minimum Price</label>
         <input name="sales_minimum_price" value={formData.sales_minimum_price} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Sales Rate</label>
         <input name="sales_rate" value={formData.sales_rate} onChange={handleChange} className="input" />
       </div>
    </div>


    {/* Second Column */}
   <div className="formColumn">
       <div className="formGroup">
         <label className="label">Wholesale Rate</label>
         <input name="wholesale_Rate" value={formData.wholesale_Rate} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Dealer Rate</label>
         <input name="dealer_rate" value={formData.dealer_rate} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Rate Factor</label>
         <input name="rate_factor" value={formData.rate_factor} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Sales Discount</label>
         <input name="sales_discount" value={formData.sales_discount} onChange={handleChange} className="input" />
       </div>
       </div>
       </div>
     </section>




     {/* Purchase Information */}
     <section className="section">
       <h3 className="sectionTitle">Purchase</h3>
       <div className="formRow">
   {/* First Column */}
   <div className="formColumn">
       <div className="formGroup">
         <label className="label">Purchase Description</label>
         <input name="purchase_description" value={formData.purchase_description} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Purchase GL *</label>
         <input name="purchase_gl" value={formData.purchase_gl} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Purchase Rate</label>
         <input name="purchase_rate" value={formData.purchase_rate} onChange={handleChange} className="input" />
       </div>
       </div>


        {/* Second Column */}
   <div className="formColumn">
       <div className="formGroup">
         <label className="label">Purchase Rate Factor</label>
         <input name="purchase_rate_factor" value={formData.purchase_rate_factor} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Purchase Discount</label>
         <input name="purchase_discount" value={formData.purchase_discount} onChange={handleChange} className="input" />
       </div>
       </div>
       </div>
     </section>




     {/* Inventory Information */}
     <section className="section">
       <h3 className="sectionTitle">Inventory</h3>
       <div className="formRow">
   {/* First Column */}
   <div className="formColumn">
       <div className="formGroup">
         <label className="label">Item Workflow</label>
         <input name="item_workflow" value={formData.item_workflow} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Procurement Type</label>
         <input name="procurement_type" value={formData.procurement_type} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Minimum Level</label>
         <input name="minimum_level" value={formData.minimum_level} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Maximum Level</label>
         <input name="maximum_level" value={formData.maximum_level} onChange={handleChange} className="input" />
       </div>
       </div>


        {/* Second Column */}
   <div className="formColumn">
       <div className="formGroup">
         <label className="label">Weighscale Mapping Code</label>
         <input name="weighscale_mapping_code" value={formData.weighscale_mapping_code} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Rack Bin No</label>
         <input name="rack_bin_no" value={formData.rack_bin_no} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Item Set Template</label>
         <input name="item_set_template" value={formData.item_set_template} onChange={handleChange} className="input" />
       </div>
       <div className="formGroup">
         <label className="label">Exclude in CVSS App List</label>
         <input name="cvss_app_list" value={formData.cvss_app_list} onChange={handleChange} className="input" />
       </div>
       </div>
       </div>
     </section>




     {/* Custom Fields */}
     <section className="section">
       <h3 className="sectionTitle">Custom Field</h3>
       <div className="formGroup">
         <label className="label">Ask UDF In Document</label>
         <input type="checkbox" name="udf_in_document" checked={formData.udf_in_document} onChange={handleChange} className="checkboxInput" />
       </div>
     </section>




     {/* Save Button */}
     <button onClick={handleSave} className="saveButton">Save</button>
     <ToastContainer />


     {showModal && (
      <div className="modal">
        <div className="modalContent">
          <h2>Add New Category</h2>
          <form onSubmit={handleNewCategorySubmit}>
            <div className="formGroup">
              <label>Name</label>
              <input
                type="text"
                name="name"
                className="input"
                value={newCategory.name}
                onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
                required
              />
            </div>


            <div className="formGroup">
              <label>Code</label>
              <input
                type="text"
                name="code"
                className="input"
                value={newCategory.code}
                onChange={(e) => setNewCategory({ ...newCategory, code: e.target.value })}
               
              />
            </div>


            <div className="formActions">
              <button type="submit" className="color-button">Save</button>
              <button
                type="button"
                className="color-button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    )}




   </div>


 );
}




export default InventoryForm;
















