import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Modal } from "@mui/material";
import './css/Event.css'; // Add your custom CSS


function Event() {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newEvent, setNewEvent] = useState({
    event_name: '',
    label_color: '',
    where: '',
    description: '',
    start_date_time: '',
    end_date_time: '',
    status: 'pending',
    repeat: 'no',
    repeat_every: 1,
    repeat_cycles: null,
    repeat_type: 'day',
    send_reminder: 'no',
    remind_time: 1,
    remind_type: 'day',
    event_link: '',
  });




  useEffect(() => {
    const fetchEvents = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error("Auth token is missing or invalid");
        return;
      }
      try {
        const response = await fetch('https://api.myntask.com/event/list', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch events');
        }
        const data = await response.json();


        console.log('API Response:', data);


        const formattedEvents = data.map(event => {
          // Convert the start_date_time to a Date object
          const dateParts = event.start_date_time.split(' ');
          const month = dateParts[0];
          const day = parseInt(dateParts[1]); // removes "th", "st", etc.
          const year = parseInt(dateParts[2]);


          // Correct date construction
          const startDate = new Date(`${month} ${day}, ${year}`);


          console.log('Formatted Date:', startDate);


          return {
            id: event.id,
            title: event.event_name,
            start: startDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
            allDay: true,
          };
        });


        console.log('Formatted Events:', formattedEvents);
        setEvents(formattedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };


    fetchEvents();
  }, []);


  const handleDateClick = (info) => {
    alert(`Clicked on: ${info.dateStr}`);
  };


  // .......................................................................................
  const handleAddEventClick = () => {
    setShowModal(true);
  };


  const handleModalClose = () => {
    setShowModal(false);
    setNewEvent({
      event_name: '',
      label_color: '#000000',
      where: '',
      description: '',
      start_date_time: '',
      end_date_time: '',
      status: 'pending',
      repeat: 'no',
      repeat_every: 1,
      repeat_cycles: null,
      repeat_type: 'day',
      send_reminder: 'no',
      remind_time: 1,
      remind_type: 'day',
      event_link: '',


    });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent(prevEvent => ({ ...prevEvent, [name]: value }));
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();


    console.log("Submitting event :", newEvent);


    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error("Auth token is missing or invalid");
      return;
    }
    try {
      console.log('Submitting event:', newEvent);
      const response = await fetch('https://api.myntask.com/event/save', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEvent),
      });


      if (!response.ok) {
        const error = await response.json();
        console.error("Error response from server:", error);
        alert(`Failed to add the event: ${error.message || 'Unknown error'}`);
        return;
      }


      const savedEvent = await response.json();
      console.log('Event saved:', savedEvent);


      if (savedEvent.success) {
        const addedEvent = {
          id: savedEvent.id || new Date().getTime(), // Fallback if no ID is returned
          title: newEvent.event_name,
          start: newEvent.start_date_time.split('T')[0],
          allDay: true,
        };


        setEvents((prevEvents) => [...prevEvents, addedEvent]);
        handleModalClose();


      } else {
        console.error('Unexpected success response:', savedEvent);
        alert('Event saved, but unable to update calendar.');
      }


    } catch (error) {
      console.error('Error adding event:', error);
      alert("An error occurred while adding the event. Please try again.");
    }
  };


  // .................................................................................................


  return (
    <>


      <div className="main-container">
       
        <div className="top-controls">
      <h1 className='title'>Events
        <div className="underline"></div>
      </h1>
      <div className="buttons-row">
      <button className="color-button" onClick={handleAddEventClick}>+ Add Event</button>
     
      </div>
    </div>
        <style>
          {`
            .fc-daygrid-day-number {
            color: #000;
            font-weight: 300;
            font-size: 14px;
            }
          `}
        </style>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView="dayGridMonth" // Default view (week-based format)
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek', // Available views
          }}
          views={{
            timeGridWeek: {
              titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }, // Custom title format for the week view
              slotLabelFormat: { hour: '2-digit', minute: '2-digit', hour12: true }, // Custom time slots
            },
            dayGridMonth: {
              titleFormat: { year: 'numeric', month: 'short' }, // Month view title
            },
          }}
          events={events}
          dateClick={handleDateClick}
          editable={true}
          selectable={true}
        />


      </div>


      {/* .......................................... */}
      <Modal open={showModal} onClose={handleModalClose}>
        <div className="modal">
          <div onClick={handleModalClose} className='overlay'></div>
          <div className="modal-content">


            <h2>Add New Event</h2>
            <form onSubmit={handleFormSubmit} className='event-form'>
              <label>
                Event Name:
                <div></div>
                <input
                  type="text"
                  name="event_name"
                  className='input-field'
                  value={newEvent.event_name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Label Color:
                <div></div>
                <input
                  type="color"
                  name="label_color"
                  value={newEvent.label_color}
                  onChange={handleInputChange}
className='input-color'

                />
              </label>
              <label>
                Location:
                <div></div>
                <input
                  type="text"
                  name="where"
                  value={newEvent.where}
                  className='input-field'
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Description:
                <div></div>
                <textarea
                  name="description"
                  value={newEvent.description}
                  onChange={handleInputChange}
                  className='input-field'
                />
              </label>
              <label>
                Start Date & Time:
                <div></div>
                <input
                  type="datetime-local"
                  name="start_date_time"
                  value={newEvent.start_date_time}
                  onChange={handleInputChange}
                  className='input-field'
                  required
                />
              </label>
              <label>
                End Date & Time:
                <div></div>
                <input
                  type="datetime-local"
                  name="end_date_time"
                  value={newEvent.end_date_time}
                  onChange={handleInputChange}
                  className='input-field'
                  required
                />
              </label>
              <label>
                Event Link:
                <div></div>
                <input
                  type="url"
                  name="event_link"
                  value={newEvent.event_link}
                  onChange={handleInputChange}
                  className='input-field'

                />
              </label>
              <label>
                Status :&nbsp;
                <select
                  name="status"
                  value={newEvent.status}
                  onChange={handleInputChange}
                  className='input-field'
                  required
                >
                  <option value="pending">Pending</option>
                  <option value="completed">Accepted</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </label>
              <div className='form-btn'>
                <button type="submit" className="color-button">Add</button>
                <button type="button" className="color-button" onClick={handleModalClose}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}


export default Event;
