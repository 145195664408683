// src/SimpleScreen.js
import React, { useState } from 'react';
import SettingsSidebar from '../../Components/SettingDrawer';

const SuperSetting = () => {



  return (
    <div style={styles.container}>
        <SettingsSidebar />
    </div>
  );
};

const styles = {
  container: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // marginTop: '50px',
  },
  
  
};

export default SuperSetting;
