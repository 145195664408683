import React, { useState, useEffect } from 'react';
import './EstimateForm.css';

const EstimateForm = () => {
    const [items, setItems] = useState([{ id: Date.now(), name: '', hsn: '', quantity: 0, unitPrice: 0, selectedTaxes: [], taxPercent: 0, amount: 0, file: null }]);
    const [currency, setCurrency] = useState('');
    const [client, setClient] = useState('');
    const [validTill, setValidTill] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [clients, setClients] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [products, setProducts] = useState([]);
    const [taxType, setTaxType] = useState('after_discount');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [taxData, setTaxData] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({ client: false, currency: false, product: false, tax: null });

    const [estimateNumber, setEstimateNumber] = useState('');
    const [description, setDescription] = useState('');
    const [note, setNote] = useState('');
    const [discount, setDiscount] = useState('');
    const [discountType, setDiscountType] = useState('percent');

    const toggleDropdown = (type, index = null) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [type]: type === 'tax' ? (prevState[type] === index ? null : index) : !prevState[type],
        }));
    };

    const handleSelect = (type, value) => {
        switch (type) {
            case 'client':
                setClient(value);
                break;
            case 'currency':
                setCurrency(value);
                break;
            case 'product':
                setSelectedProduct(value);
                break;
            case 'taxType':
                setTaxType(value);
                break;
            default:
                break;
        }
        setDropdownOpen(prevState => ({ ...prevState, [type]: false }));
    };

    const handleTaxSelect = (index, taxValue) => {
        const updatedItems = [...items];
        const taxPercentage = parseFloat(taxValue.match(/\d+(\.\d+)?/)[0]);

        if (!updatedItems[index].selectedTaxes.includes(taxValue)) {
            updatedItems[index].selectedTaxes.push(taxValue);
        } else {
            updatedItems[index].selectedTaxes = updatedItems[index].selectedTaxes.filter(tax => tax !== taxValue);
        }

        updatedItems[index].taxPercent = updatedItems[index].selectedTaxes.reduce((sum, tax) => {
            return sum + parseFloat(tax.match(/\d+(\.\d+)?/)[0]);
        }, 0);

        setItems(updatedItems);
        calculateItemAmount(index, updatedItems);
    };

    const calculateItemAmount = (index, updatedItems) => {
        const item = updatedItems[index];
        const quantity = parseFloat(item.quantity || 0);
        const unitPrice = parseFloat(item.unitPrice || 0);
        const baseAmount = quantity * unitPrice;
        const taxAmount = (baseAmount * item.taxPercent) / 100;
        item.amount = baseAmount + taxAmount;

        setItems(updatedItems);
        calculateTotals(updatedItems);
    };

    useEffect(() => {
        const fetchData = async (url, setData) => {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (Array.isArray(data)) {
                    setData(data);
                }
            } catch (error) {
                console.error(`Error fetching data from ${url}:`, error);
            }
        };

        fetchData('https://api.myntask.com/project/client', setClients);
        fetchData('https://api.myntask.com/company/currency', setCurrencies);
        fetchData('https://api.myntask.com/purchase_order/product_dropdown', setProducts);
        fetchData('https://api.myntask.com/product/taxes', setTaxData);
    }, []);

    const addItem = () => {
        setItems([...items, { id: Date.now(), name: '', hsn: '', quantity: 0, unitPrice: 0, selectedTaxes: [], taxPercent: 0, amount: 0, file: null }]);
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;

        if (['quantity', 'unitPrice', 'taxPercent'].includes(field)) {
            calculateItemAmount(index, updatedItems);
        }

        setItems(updatedItems);
    };

    const calculateTotals = (updatedItems) => {
        const subTotal = updatedItems.reduce((sum, item) => sum + parseFloat(item.quantity * item.unitPrice || 0), 0);
        const totalAmount = updatedItems.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
        setSubTotal(subTotal);
        setTotalAmount(totalAmount);
    };

    const handleFileChange = (index, file) => {
        const updatedItems = [...items];
        updatedItems[index].file = file;
        setItems(updatedItems);
    };

    const handleSave = async () => {
        const token = localStorage.getItem('authToken');
    
        const currencyId = currencies.find(c => c.currency_code === currency)?.id;
        const clientId = clients.find(c => c.client_name === client)?.client_id;
    
        const requestData = {
            estimate_number: estimateNumber,
            currency_id: currencyId,
            client_id: clientId,
            valid_till: validTill,
            description: description,
            calculate_tax: taxType,
            note: note,
            sub_total: subTotal.toFixed(2),
            total: totalAmount.toFixed(2),
            discount: discount,
            discount_type: discountType,
            items: items.map(item => ({
                item_name: item.name,
                quantity: item.quantity,
                product_id: products.find(p => p.name === selectedProduct)?.id || null,
                hsn_code: item.hsn,
                unit_price: item.unitPrice,
                unit_id: 3, // assuming a static unit_id; adjust as needed
                item_summary: description,
                amount: item.amount,
                tax_id: item.selectedTaxes.map(tax => taxData.find(td => td.tax_name === tax)?.id.toString())
            }))
        };
    
        // Log each field
        console.log('Estimate Number:', requestData.estimate_number);
        console.log('Currency ID:', requestData.currency_id);
        console.log('Client ID:', requestData.client_id);
        console.log('Valid Till:', requestData.valid_till);
        console.log('Description:', requestData.description);
        console.log('Calculate Tax:', requestData.calculate_tax);
        console.log('Note:', requestData.note);
        console.log('Sub Total:', requestData.sub_total);
        console.log('Total:', requestData.total);
        console.log('Discount:', requestData.discount);
        console.log('Discount Type:', requestData.discount_type);
        console.log('Items:', requestData.items);
    
        try {
            const response = await fetch('https://api.myntask.com/estimate/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(requestData)
            });
    
            if (response.ok) {
                alert('Estimate saved successfully!');
            } else {
                alert('Failed to save the estimate.');
            }
        } catch (error) {
            console.error('Error saving estimate:', error);
            alert('An error occurred while saving the estimate.');
        }
    };
    

    const handleCancel = () => {
        console.log('Form canceled');
    };

    return (
        <div className="form-container">
            <h2>Estimate Form</h2>

            <div className="field">
                <label>Estimate No:</label>
                <input type="text" value={estimateNumber} onChange={(e) => setEstimateNumber(e.target.value)} />
            </div>

            <div className="field">
                <label>Valid Till:</label>
                <input
                    type="date"
                    value={validTill}
                    onChange={(e) => setValidTill(e.target.value)}
                />
            </div>

            <div className="field">
                <label>Currency:</label>
                <div className="dropdown-container">
                    <input
                        type="text"
                        value={currency}
                        onClick={() => toggleDropdown('currency')}
                        readOnly
                    />
                    {dropdownOpen.currency && (
                        <ul className="dropdown">
                            {currencies.length > 0 ? (
                                currencies.map((currencyItem) => (
                                    <li
                                        key={currencyItem.id}
                                        onClick={() => handleSelect('currency', currencyItem.currency_code)}
                                    >
                                        {currencyItem.currency_code} ({currencyItem.currency_symbol})
                                    </li>
                                ))
                            ) : (
                                <li>No currencies available</li>
                            )}
                        </ul>
                    )}
                </div>
            </div>

            <div className="field">
                <label>Client:</label>
                <div className="dropdown-container">
                    <input
                        type="text"
                        value={client}
                        onClick={() => toggleDropdown('client')}
                        readOnly
                    />
                    {dropdownOpen.client && (
                        <ul className="dropdown">
                            {clients.length > 0 ? (
                                clients.map((clientItem) => (
                                    <li
                                        key={clientItem.client_id}
                                        onClick={() => handleSelect('client', clientItem.client_name)}
                                    >
                                        {clientItem.client_name}
                                    </li>
                                ))
                            ) : (
                                <li>No clients available</li>
                            )}
                        </ul>
                    )}
                </div>
            </div>

            <div className="field">
                <label>Calculated Tax:</label>
                <div className="dropdown-container">
                    <input
                        type="text"
                        value={taxType}
                        onClick={() => toggleDropdown('taxType')}
                        readOnly
                    />
                    {dropdownOpen.taxType && (
                        <ul className="dropdown">
                            <li onClick={() => handleSelect('taxType', 'before_discount')}>Before Discount</li>
                            <li onClick={() => handleSelect('taxType', 'after_discount')}>After Discount</li>
                        </ul>
                    )}
                </div>
            </div>

            <div className="field">
                <label>Product:</label>
                <div className="dropdown-container">
                    <input
                        type="text"
                        value={selectedProduct}
                        onClick={() => toggleDropdown('product')}
                        readOnly
                    />
                    {dropdownOpen.product && (
                        <ul className="dropdown">
                            {products.length > 0 ? (
                                products.map((productItem) => (
                                    <li
                                        key={productItem.id}
                                        onClick={() => handleSelect('product', productItem.name)}
                                    >
                                        {productItem.name}
                                    </li>
                                ))
                            ) : (
                                <li>No products available</li>
                            )}
                        </ul>
                    )}
                </div>
            </div>

            <div className="field">
                <label>Description:</label>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>

            <div className="field">
                <label>Note:</label>
                <textarea value={note} onChange={(e) => setNote(e.target.value)}></textarea>
            </div>

            <div className="field">
                <label>Discount:</label>
                <input type="text" value={discount} onChange={(e) => setDiscount(e.target.value)} />
            </div>

            <div className="field">
                <label>Discount Type:</label>
                <div className="dropdown-container">
                    <input
                        type="text"
                        value={discountType}
                        onClick={() => toggleDropdown('discountType')}
                        readOnly
                    />
                    {dropdownOpen.discountType && (
                        <ul className="dropdown">
                            <li onClick={() => handleSelect('discountType', 'percent')}>Percent</li>
                            <li onClick={() => handleSelect('discountType', 'flat')}>Flat</li>
                        </ul>
                    )}
                </div>
            </div>

            {items.map((item, index) => (
                <div key={item.id} className="item-row">
                    <div className="field">
                        <label>Name:</label>
                        <input
                            type="text"
                            value={item.name}
                            onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                        />
                    </div>
                    <div className="field">
                        <label>HSN/SAC:</label>
                        <input
                            type="text"
                            value={item.hsn}
                            onChange={(e) => handleItemChange(index, 'hsn', e.target.value)}
                        />
                    </div>
                    <div className="field">
                        <label>Quantity:</label>
                        <input
                            type="number"
                            value={item.quantity}
                            onChange={(e) => handleItemChange(index, 'quantity', parseFloat(e.target.value))}
                        />
                    </div>
                    <div className="field">
                        <label>Unit Price:</label>
                        <input
                            type="number"
                            value={item.unitPrice}
                            onChange={(e) => handleItemChange(index, 'unitPrice', parseFloat(e.target.value))}
                        />
                    </div>
                    <div className="field">
                        <label>Tax:</label>
                        <div className="dropdown-container">
                            <input
                                type="text"
                                value={item.selectedTaxes.length ? item.selectedTaxes.join(', ') : 'Select Tax'}
                                onClick={() => toggleDropdown('tax', index)}
                                readOnly
                            />
                            {dropdownOpen.tax === index && (
                                <div className="dropdown">
                                    <ul className="dropdown-list">
                                        {taxData.length > 0 ? (
                                            taxData.map((taxItem) => (
                                                <li
                                                    key={taxItem.id}
                                                    onClick={() => handleTaxSelect(index, taxItem.tax_name)}
                                                >
                                                    {taxItem.tax_name}
                                                </li>
                                            ))
                                        ) : (
                                            <li>No tax rates available</li>
                                        )}
                                    </ul>
                                    <button
                                        type="button"
                                        className="done-button"
                                        onClick={() => toggleDropdown('tax', index)}
                                    >
                                        Done
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="field">
                        <label>Amount:</label>
                        <input
                            type="number"
                            value={item.amount || 0}
                            readOnly
                        />
                    </div>
                    <div className="field">
                        <label>File:</label>
                        <input
                            type="file"
                            onChange={(e) => handleFileChange(index, e.target.files[0])}
                        />
                    </div>
                </div>
            ))}

            <button type="button" onClick={addItem}>Add Item</button>

            <div className="field">
                <label>Sub Total:</label>
                <input
                    type="number"
                    value={subTotal || 0}
                    readOnly
                />
            </div>

            <div className="field">
                <label>Total Amount:</label>
                <input
                    type="number"
                    value={totalAmount || 0}
                    readOnly
                />
            </div>

            <div className="form-actions">
                <button type="button" onClick={handleSave}>Save</button>
                <button type="button" onClick={handleCancel}>Cancel</button>
            </div>
        </div>
    );
};

export default EstimateForm;
