import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress'; // Add this line


const Projects = ({ userId }) => {
  const [projectsData, setProjectsData] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      const token = localStorage.getItem('authToken'); // Get token from local storage

      if (!token) {
        setError('Authentication token not found. Please log in again.');
        setLoading(false);
        return;
      }

      try {
        // Construct URL with the userId
        const response = await fetch(`https://api.myntask.com/project/list/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Pass token in Authorization header
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Projects Data:', data); // Log response data to console
        setProjectsData(data); // Assuming `data` is an array of projects
        setFilteredProjects(data); // Initialize filtered projects with all projects
      } catch (err) {
        console.error('Error fetching projects data:', err);
        setError('Failed to fetch projects data. Please try again later.');
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchProjects();
  }, [userId]);

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = projectsData.filter((project) =>
      project.project_name.toLowerCase().includes(term) ||
      project.code.toLowerCase().includes(term) ||
      (project.clients && project.clients.some(client => client.client_name.toLowerCase().includes(term)))
    );

    setFilteredProjects(filtered);
  };

  const handleViewDetails = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  if (loading) {
    return <div className="projects-container"><p>Loading projects...</p></div>;
  }

  if (error) {
    return <div className="projects-container"><p className="error-message">{error}</p></div>;
  }

  return (
    <div className="projects-container" style={styles.container}>
      <div style={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search projects..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={styles.searchInput}
        />
      </div>
      {filteredProjects && filteredProjects.length > 0 ? (
        <div style={styles.projectsGrid}>
          {filteredProjects.map((project, index) => (
            <div key={index} style={styles.projectBox}>
              <div style={styles.header}>
                <div style={styles.badge}>{project.code || 'No Code Available'}</div>
                <button style={styles.viewDetailButton} onClick={() => handleViewDetails(project)}>View Details</button>
              </div>
              <h3 style={styles.projectTitle}>{project.project_name}</h3>
              <p style={styles.projectDescription}>Here you can see the details of {project.project_name}.</p>

              {project.clients && project.clients.length > 0 && (
                <div style={styles.clientDetails}>
                  <img src={project.clients[0].client_image} alt={project.clients[0].client_name} style={styles.clientImage} />
                  <span>{project.clients[0].client_name}</span>
                </div>
              )}

              <div style={styles.footer}>
                <div style={styles.members}>
                  {project.members?.slice(0, 3).map((member, idx) => (
                    <img key={idx} src={member.member_image} alt={member.member_name} style={styles.memberImage} />
                  ))}
                  {project.members?.length > 3 && <span style={styles.moreMembers}>+{project.members.length - 3}</span>}
                </div>
                <div style={styles.dateContainer}>
                  {new Date(project.deadline).toLocaleDateString()}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No projects found for this user.</p>
      )}

      {selectedProject && (
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box sx={styles.modalBox}>
            <button onClick={handleCloseModal} style={styles.closeButton}>×</button>
            <div style={styles.modalContent}>
              <h2 style={styles.modalTitle}>{selectedProject.project_name}</h2>
              <div style={styles.progressContainer}>
                <CircularProgress
                  variant="determinate"
                  value={selectedProject.completion_percentage}
                  style={styles.circularProgress}
                  size={100}
                />
                <div style={styles.percentageText}>{selectedProject.completion_percentage}%</div>
              </div>
              <p><strong>Code:</strong> {selectedProject.code || 'N/A'}</p>
              <p><strong>Start Date:</strong> {new Date(selectedProject.start_date).toLocaleDateString()}</p>
              <p><strong>Deadline:</strong> {new Date(selectedProject.deadline).toLocaleDateString()}</p>
              <p><strong>Status:</strong> {selectedProject.status}</p>

              <h3>Client:</h3>
              {selectedProject.clients && selectedProject.clients.length > 0 && (
                <div style={styles.clientDetails}>
                  <img src={selectedProject.clients[0].client_image} alt={selectedProject.clients[0].client_name} style={styles.clientImage} />
                  <p>{selectedProject.clients[0].client_name}</p>
                </div>
              )}

              <h3>Team Members:</h3>
              <div style={styles.membersGrid}>
                {selectedProject.members?.map((member, idx) => (
                  <div key={idx} style={styles.member}>
                    <img src={member.member_image} alt={member.member_name} style={styles.memberImage} />
                    <p>{member.member_name}</p>
                  </div>
                ))}
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

// CSS-in-JS styles for the projects component
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  searchContainer: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  searchInput: {
    padding: '10px',
    width: '80%',
    maxWidth: '400px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    fontSize: '1em',
  },
  projectsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
    justifyItems: 'center',
  },
  projectBox: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '20px',
    width: '100%',
    maxWidth: '300px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s, box-shadow 0.3s',
    textAlign: 'left',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  badge: {
    backgroundColor: '#e0e7ff',
    color: '#3730a3',
    padding: '5px 10px',
    borderRadius: '20px',
    fontSize: '0.8em',
  },
  viewDetailButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  projectTitle: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    color: '#333',
    margin: '10px 0',
  },
  projectDescription: {
    fontSize: '0.9em',
    color: '#666',
  },
  clientDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '15px 0',
    fontSize: '0.9em', // Ensure consistent font size
  },
  clientImage: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  members: {
    display: 'flex',
    gap: '5px',
  },
  memberImage: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  moreMembers: {
    fontSize: '0.9em',
    color: '#555',
  },
  dateContainer: {
    fontSize: '0.85em',
    color: '#999',
    display: 'flex',
    alignItems: 'center',
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: 24,
    padding: '20px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  modalContent: {
    fontFamily: 'Arial, sans-serif',
  },
  modalTitle: {
    fontSize: '1.6em',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '20px',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '1.5em',
    cursor: 'pointer',
    color: '#333',
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    position: 'relative',
  },
  circularProgress: {
    color: '#28a745',
  },
  percentageText: {
    position: 'absolute',
    fontSize: '1.2em',
    fontWeight: 'bold',
    color: '#333',
  },
  membersGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '10px',
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
};

export default Projects;
