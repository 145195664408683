import React, { useState, useEffect } from 'react';

function EmployeeSalary() {
  const [salaries, setSalaries] = useState([]);
  const [error, setError] = useState(null);
  const [showActions, setShowActions] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // To store selected user data

  useEffect(() => {
    const fetchSalaries = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }

      try {
        const response = await fetch('https://api.myntask.com/payroll/employee_salary', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch salaries');
        }

        const data = await response.json();
        console.log('Salaries fetched:', data);
        setSalaries(data);
      } catch (error) {
        console.error('Error fetching salaries:', error);
        setError('Failed to fetch salaries');
      }
    };

    fetchSalaries();
  }, []);

  const handleActionClick = (user) => {
    setSelectedUser(user); // Store the selected user's data
    setShowActions(true); // Show the action buttons and image
  };

  return (
    <div>
      <h1>Employee Salary</h1>
      {showActions && selectedUser && (
        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          {selectedUser.image ? (
            <img
              src={selectedUser.image}
              alt={selectedUser.user_name}
              style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
            />
          ) : (
            <div
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: '#ccc',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              No Image
            </div>
          )}
          <div>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#1976d2',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                marginRight: '10px',
                cursor: 'pointer',
              }}
            >
              Edit
            </button>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#388e3c',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                marginRight: '10px',
                cursor: 'pointer',
              }}
            >
              +Increment
            </button>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#f57c00',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Salary History
            </button>
          </div>
        </div>
      )}
      {error && <p>{error}</p>}
      {salaries.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Employee Name</th>
              <th>Designation</th>
              <th>Salary</th>
              <th>Salary Circle</th>
              <th>Invoice</th>
              <th>Allow Generate Payroll</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {salaries.map((salary, index) => (
              <tr key={index}>
                <td>{salary.user_id}</td>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {salary.image ? (
                      <img
                        src={salary.image}
                        alt={salary.user_name}
                        style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
                      />
                    ) : (
                      <div
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          backgroundColor: '#ccc',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: '10px',
                        }}
                      >
                        No Image
                      </div>
                    )}
                    <span>{salary.user_name}</span>
                  </div>
                </td>
                <td>{salary.designation}</td>
                <td>
                  <div
                    style={{
                      width: '80px',
                      height: '30px',
                      borderRadius: '15px',
                      backgroundColor: '#4caf50',
                      color: '#fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    ₹{salary.salary || 'N/A'}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      padding: '5px 10px',
                      backgroundColor: '#ff5722',
                      color: '#fff',
                      borderRadius: '15px',
                      display: 'inline-block',
                    }}
                  >
                    {salary.employee_salary_circle || 'N/A'}
                  </div>
                </td>
                <td>
                  <button
                    style={{
                      padding: '5px 10px',
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    Invoice
                  </button>
                </td>
                <td>
                  <div
                    style={{
                      padding: '5px 10px',
                      backgroundColor: salary.allow_generate_payroll ? '#4caf50' : '#ccc',
                      color: '#fff',
                      borderRadius: '15px',
                      display: 'inline-block',
                    }}
                  >
                    {salary.allow_generate_payroll ? 'Yes' : 'No'}
                  </div>
                </td>
                <td>
                  <button
                    onClick={() => handleActionClick(salary)}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    Action
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No salary data available</p>
      )}
    </div>
  );
}

export default EmployeeSalary;
