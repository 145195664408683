import React from 'react';

function DashboardClient() {
  return (
    <div className='main-container'>
      <h1>Dashboard - Client List</h1>
      <p>Here you can view and manage your clients.</p>
    </div>
  );
}

export default DashboardClient;
