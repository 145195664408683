import React, { useState, useEffect } from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Modal, Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import clockinImage from '../assets/clockin.jpg'; // Import the clock-in image
import clockoutImage from '../assets/clockout.jpg'; // Import the clock-out image


const ClockIn = () => {
 const [isClockedIn, setIsClockedIn] = useState(false);
 const [loading, setLoading] = useState(false);
 const [showClockInModal, setShowClockInModal] = useState(false); // State to manage clock-in modal visibility
 const [showClockOutModal, setShowClockOutModal] = useState(false); // State to manage clock-out modal visibility


  // Fetch attendance status from the API when the component mounts
  const fetchAttendanceStatus = async () => {
    try {
      const token = localStorage.getItem('authToken'); // Use the correct key for your token

      const response = await fetch('https://api.myntask.com/auth/attendance_status', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setIsClockedIn(data.clockInStatus === 'Yes');
    } catch (error) {
      console.error('Error fetching attendance status:', error);
    }
  };

  // Fetch attendance status when the component mounts
  useEffect(() => {
    fetchAttendanceStatus();
  }, []);

  const getUserLocationAndSaveAttendance = async () => {
    setLoading(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const success = await handleSaveAttendance(latitude, longitude);
          if (success) {
            setIsClockedIn(true);
            setShowClockInModal(true); // Show clock-in modal
            await fetchAttendanceStatus(); // Refetch attendance status after clocking in
          }
          setLoading(false);
        },
        (error) => {
          console.error('Error getting user location:', error);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const getUserLocationAndClockOut = async () => {
    setLoading(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const success = await handleClockOut(latitude, longitude);
          if (success) {
            setIsClockedIn(false);
            setShowClockOutModal(true); // Show clock-out modal
            await fetchAttendanceStatus(); // Refetch attendance status after clocking out
          }
          setLoading(false);
        },
        (error) => {
          console.error('Error getting user location for clock out:', error);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const handleSaveAttendance = async (latitude, longitude) => {
    const url = 'https://api.myntask.com/attendance/save';
    const data = { latitude, longitude };

    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        return true;
      } else {
        console.error('Failed to save attendance');
        return false;
      }
    } catch (error) {
      console.error('Error saving attendance:', error);
      return false;
    }
  };

  const handleClockOut = async (latitude, longitude) => {
    const clockOutTimestamp = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const url = 'https://api.myntask.com/attendance/save';
    const data = {
      latitude,
      longitude,
      clock_out: clockOutTimestamp,
    };

    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        return true;
      } else {
        console.error('Failed to clock out');
        return false;
      }
    } catch (error) {
      console.error('Error clocking out:', error);
      return false;
    }
  };


 // Handle modal close
 const handleCloseClockInModal = () => {
   setShowClockInModal(false);
 };


 const handleCloseClockOutModal = () => {
   setShowClockOutModal(false);
 };


 return (
   <>
     <Button
       variant="contained"
       color="primary"
       onClick={() => {
         if (!loading) {
           isClockedIn ? getUserLocationAndClockOut() : getUserLocationAndSaveAttendance();
         }
       }}
       sx={{
         backgroundColor: 'white',
         color: isClockedIn ? 'red' : 'green',
         padding: '4px 10px',
         fontSize: '12px',
         border: '1px solid #ccc',
         boxShadow: 'none',
         fontWeight: '600',
         display: 'flex',
         alignItems: 'center',
         marginRight: '10px',
         gap: '5px',
         '&:hover': {
           backgroundColor: '#f0f0f0',
           boxShadow: 'none',
         },
       }}
       disabled={loading} // Disable button during loading
       startIcon={<ExitToAppIcon />}
     >
       {isClockedIn ? 'Clock Out' : 'Clock In'}
       {loading && <CircularProgress size={20} sx={{ marginLeft: '10px' }} />}
     </Button>


     {/* Modal for successful clock-in */}
     <Modal
       open={showClockInModal}
       onClose={handleCloseClockInModal}
       aria-labelledby="clockin-modal-title"
       aria-describedby="clockin-modal-description"
       sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
     >
       <Box
         sx={{
           backgroundColor: 'white',
           padding: '20px',
           borderRadius: '8px',
           boxShadow: 24,
           textAlign: 'center',
           width: '300px',
         }}
       >
         <Typography
           id="clockin-modal-title"
           variant="h6"
           component="h2"
           sx={{ color: 'green' }} // Green text for clock-in
         >
           Successfully Clocked In!
         </Typography>
         <img
           src={clockinImage}
           alt="Clock In"
           style={{ width: '100%', marginTop: '10px', borderRadius: '8px' }}
         />
         <Button
           variant="contained"
           color="primary"
           onClick={handleCloseClockInModal}
           sx={{ marginTop: '15px' }}
         >
           OK
         </Button>
       </Box>
     </Modal>


     {/* Modal for successful clock-out */}
     <Modal
       open={showClockOutModal}
       onClose={handleCloseClockOutModal}
       aria-labelledby="clockout-modal-title"
       aria-describedby="clockout-modal-description"
       sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
     >
       <Box
         sx={{
           backgroundColor: 'white',
           padding: '20px',
           borderRadius: '8px',
           boxShadow: 24,
           textAlign: 'center',
           width: '300px',
         }}
       >
         <Typography
           id="clockout-modal-title"
           variant="h6"
           component="h2"
           sx={{ color: 'red' }} // Red text for clock-out
         >
           Successfully Clocked Out!
         </Typography>
         <img
           src={clockoutImage}
           alt="Clock Out"
           style={{ width: '100%', marginTop: '10px', borderRadius: '8px' }}
         />
         <Button
           variant="contained"
           color="primary"
           onClick={handleCloseClockOutModal}
           sx={{ marginTop: '15px' }}
         >
           OK
         </Button>
       </Box>
     </Modal>
   </>
 );
};


export default ClockIn;






