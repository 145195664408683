import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';


// Custom marker icon using L.divIcon
const createCustomIcon = (image) => {
    return L.divIcon({
      className: 'custom-div-icon',
      html: `
        <div class="custom-marker-icon" style="position: relative; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center;">
          <div style="background-color: red; border-radius: 50% 50% 50% 0; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; border: 2px solid white; transform: rotate(-45deg); position: absolute; top: 0;">
            <img src="${image}" style="width: 20px; height: 20px; border-radius: 50%; transform: rotate(45deg);" />
          </div>
          <div style="width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 15px solid red; position: absolute; bottom: -5px; left: 9px;"></div>
        </div>
      `,
      iconSize: [40, 40], // Size of the custom marker
      iconAnchor: [20, 40], // Anchor point of the icon
      popupAnchor: [0, -40], // Position of the popup relative to the icon
    });
  };
 


// Tooltip styling
const tooltipStyle = {
  backgroundColor: '#fff',
  borderRadius: '5px',
  fontSize: '10px',
  maxWidth: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
};


const MapScreen = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await fetch('https://api.myntask.com/attendance/today', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Network response was not ok');
        }


        const data = await response.json();
        const validUsers = data
          .filter(user => user.latitude && user.longitude) // Ensure latitude and longitude are not null
          .map(user => ({
            name: user.name,
            image: user.user_image || 'https://via.placeholder.com/30', // Fallback image if none provided
            position: [user.latitude, user.longitude],
          }));
        setUsers(validUsers);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };


    fetchData();
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }


  return (
    <div style={{ height: '100vh', width: '100vw', margin: '0', padding: '0' }}>
      <MapContainer center={[27, 76]} zoom={6} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {users.map((user, index) => (
          <Marker
            key={index}
            position={user.position}
            icon={createCustomIcon(user.image)} // Use custom icon
          >
            <Tooltip
              direction="top"
              offset={[0, -30]}
              opacity={1}
              permanent
              className="user-tooltip"
            >
              <div style={tooltipStyle}>{user.name}</div>
            </Tooltip>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};


export default MapScreen;




