import React, { useEffect, useState } from 'react';
import './Form.css'; // Import the CSS file for styling
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer




import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS




function Bundle() {
 const [bundleDetails, setBundleDetails] = useState([
   { id: 1, item: '', quantity: 0, costAllocationPercentage: 0 },
   { id: 2, item: '', quantity: 0, costAllocationPercentage: 0 },
   { id: 3, item: '', quantity: 0, costAllocationPercentage: 0 },
 ]);




 const [formData, setFormData] = useState({
   name: '',
   code: '',
   under_group: '',
   category_id: '',
   subcategory_id: '',
   unit_option: '',
   stock_unit: '',
   bar_code_prefix: '',
   gst_classification: '',
   item_description: '',
   sales_description: '',
   sales_gl: '',
   sales_mrp: 0,
   sales_minimum_price: 0,
   sales_rate: 0,
   wholesale_rate: 0,
   dealer_rate: 0,
   rate_factor: 0,
   sales_discount: 0,
   purchase_description: '',
   subItem_applicable: false,
   purchase_gl: '',
   purchase_rate: 0,
   purchase_rate_factor: 0,
   purchase_discount: 0,
   gst_input_not_applicable: false,
   print_barcode: true,
   udf_in_document: 0
 });




 const [categories, setCategories] = useState([]); // To hold the fetched categories
 const [showModal, setShowModal] = useState(false); // State to control modal visibility
const [newCategory, setNewCategory] = useState({ name: '', code: '' }); // For new category form data
const [unitTypes, setUnitTypes] = useState([]);
const [subcategories, setSubcategories] = useState([]);


const token = localStorage.getItem('authToken'); // Get token from localStorage


// Fetch categories when the component mounts
useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.myntask.com/product/category', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Send token in Authorization header
            'Content-Type': 'application/json',
          },
        });


        if (response.ok) {
          const data = await response.json();
          setCategories(data); // Assuming the data is an array of categories
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };


    const fetchUnitTypes = async () => {
        try {
          const response = await fetch('https://api.myntask.com/product/unit_types', {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (response.ok) {
            const data = await response.json();
            setUnitTypes(data); // Assuming the response is an array of unit types
          } else {
            console.error('Failed to fetch unit types');
          }
        } catch (error) {
          console.error('Error fetching unit types:', error);
        }
      };
  
      fetchUnitTypes();
    fetchCategories();
    
  }, [token]);


  // Function to fetch subcategories based on selected category using fetch
  useEffect(() => {
    if (formData.category_id) {
      const fetchSubcategories = async () => {
        try {
          const response = await fetch(
            `https://api.myntask.com/product/sub_category/${formData.category_id}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`, // Replace with actual token
              },
            }
          );


          if (!response.ok) {
            throw new Error('Error fetching subcategories');
          }


          const data = await response.json();
          setSubcategories(data);
        } catch (error) {
          console.error('Error fetching subcategories:', error);
        }
      };


      fetchSubcategories();
    }
  }, [formData.category_id,token]);


   // Handle new category form submission
   const handleNewCategorySubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('https://api.myntask.com/product/category/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // ensure the token is correct
        },
        body: JSON.stringify({
          name: newCategory.name,
          code: newCategory.code,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Category added successfully:', data);
        setShowModal(false); // Close modal on success
      } else {
        console.error('Failed to add category');
        alert(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error while adding category:', error);
      alert('Failed to add category, please try again later.');
    }
  }; 




 const handleChange = (e) => {
   const { name, value, type, checked } = e.target;
   setFormData({
     ...formData,
     [name]: type === 'checkbox' ? checked : value,
   });
 };




 const handleInputChange = (index, event) => {
   const { name, value } = event.target;
   const newBundleDetails = [...bundleDetails];
   newBundleDetails[index][name] = value;
   setBundleDetails(newBundleDetails);
 };




 const addLine = () => {
   setBundleDetails([
     ...bundleDetails,
     { id: bundleDetails.length + 1, item: '', quantity: 0, costAllocationPercentage: 0 },
   ]);
 };




 const removeLine = (index) => {
   setBundleDetails(bundleDetails.filter((_, i) => i !== index));
 };
 const handleSave = async () => {
   try {
     const token = localStorage.getItem('authToken'); // Get token from localStorage
     const dataToSend = {
       ...formData,
       item_mode: 'bundle',
       bundle: bundleDetails.map(detail => ({
         item: detail.item,
         qty: detail.quantity,
         costallocationpercentage: detail.costAllocationPercentage,
       })),
     };
      console.log('Data to be sent:', dataToSend); // Log the data to be sent
      const response = await fetch('https://api.myntask.com/product/save', {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${token}`,
       },
       body: JSON.stringify(dataToSend),
     });
      const result = await response.json();
     console.log('API Response:', result); // Log the API response
      // Show a toast notification on successful save
     if (response.ok) {
       toast.success('Data saved successfully!');
     } else {
       toast.error('Error saving data.');
     }
   } catch (error) {
     console.error('Error saving product:', error);
     toast.error('Error saving data.');
   }
 };








 return (
   <div className="container">
     {/* Basic Information Section */}
    
   {/* Basic Information Section */}
   <ToastContainer />
<section className="section">
 <h3 className="sectionTitle">Basic Information</h3>
 <div className="formRow">
   <div className="formColumn">
     <div className="formGroup">
       <label className="label">Name *</label>
       <input
         name="name"
         value={formData.name}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Code *</label>
       <input
         name="code"
         value={formData.code}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Under Group *</label>
       <input
         name="under_group"
         value={formData.under_group}
         onChange={handleChange}
         className="input"
       />
     </div>


     <div className="formGroup">
  <label className="label">Category</label>
  <div className="categoryDropdownContainer">
    <select
      name="category_id"
      value={formData.category_id}
      onChange={handleChange}
      className="inputadd"
    >
      <option value="">Select a category</option>
      {categories.map((category) => (
        <option key={category.id} value={category.id}>
          {category.category_name}
        </option>
      ))}
    </select>
    
    <button
          type="button"
          className="addCategoryButton"
          onClick={() => setShowModal(true)} // Show modal when clicked
        >
          Add
        </button>
  </div>
         </div>


         {/* Subcategory dropdown */}
 <div className="formGroup">
 <label className="label">Sub Category</label>
        <select
          name="subcategory_id"
          value={formData.subcategory_id}
          onChange={handleChange}
          className="input"
          disabled={!formData.category_id} // Disable if no category selected
        >
          <option value="">Select a subcategory</option>
          {subcategories.map((subcategory) => (
            <option key={subcategory.id} value={subcategory.id}>
              {subcategory.category_name}
            </option>
          ))}
        </select>
      </div>


     <div className="formGroup">
       <label className="label">Unit Option</label>
       <input
         name="unit_option"
         value={formData.unit_option}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
        <label className="label">Stock Unit *</label>
        <select
          name="stock_unit"
          value={formData.stock_unit}
          onChange={handleChange}
          className="input"
          required
        >
          <option value="">Select a stock unit</option>
          {unitTypes.map((unitType) => (
            <option key={unitType.id} value={unitType.id}>
              {unitType.unit_type}
            </option>
          ))}
        </select>
      </div>
   </div>




   <div className="formColumn">
     <div className="formGroup">
       <label className="label">BarCode/BarCode Prefix</label>
       <input
         name="bar_code_prefix"
         value={formData.bar_code_prefix}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">Gst Input Not Applicable</label>
       <input
         type="checkbox"
         name="gst_input_not_applicable"
         checked={formData.gst_input_not_applicable}
         onChange={handleChange}
         className="checkboxInput"
       />
     </div>
     <div className="formGroup">
       <label className="label">Print Barcode</label>
       <input
         type="checkbox"
         name="print_barcode"
         checked={formData.print_barcode}
         onChange={handleChange}
         className="checkboxInput"
       />
     </div>
     <div className="formGroup">
       <label className="label">GST Classification (HSN/SAC)</label>
       <input
         name="gst_classification"
         value={formData.gst_classification}
         onChange={handleChange}
         className="input"
       />
     </div>
     <div className="formGroup">
       <label className="label">eInvoice / eWay Item Description</label>
       <input
         name="item_description"
         value={formData.item_description}
         onChange={handleChange}
         className="input"
       />
     </div>
   </div>
 </div>
</section>




     {/* Sales Section */}
     <section className="section">
       <h3 className="sectionTitle">Sales</h3>
       <div className="formRow">
         <div className="formColumn">
           <div className="formGroup">
             <label className="label">Sales Description</label>
             <input
               name="sales_description"
               value={formData.sales_description}
               onChange={handleChange}
               className="input"
             />
           </div>
           <div className="formGroup">
             <label className="label">Sales GL *</label>
             <input
               name="sales_gl"
               value={formData.sales_gl}
               onChange={handleChange}
               className="input"
             />
           </div>
           <div className="formGroup">
             <label className="label">MRP</label>
             <input
               name="sales_mrp"
               value={formData.sales_mrp}
               onChange={handleChange}
               className="input"
               type="number"
             />
           </div>
         </div>
         <div className="formColumn">
           <div className="formGroup">
             <label className="label">Minimum Price</label>
             <input
               name="sales_minimum_price"
               value={formData.sales_minimum_price}
               onChange={handleChange}
               className="input"
               type="number"
             />
           </div>
           <div className="formGroup">
             <label className="label">Sales Rate</label>
             <input
               name="sales_rate"
               value={formData.sales_rate}
               onChange={handleChange}
               className="input"
               type="number"
             />
           </div>
           <div className="formGroup">
             <label className="label">Wholesale Rate</label>
             <input
               name="wholesale_rate"
               value={formData.wholesale_rate}
               onChange={handleChange}
               className="input"
               type="number"
             />
           </div>
         </div>
       </div>
     </section>




     {/* Purchase Section */}
     <section className="section">
       <h3 className="sectionTitle">Purchase</h3>
       <div className="formRow">
         <div className="formColumn">
           <div className="formGroup">
             <label className="label">Purchase Description</label>
             <input
               name="purchase_description"
               value={formData.purchase_description}
               onChange={handleChange}
               className="input"
             />
           </div>
           <div className="formGroup">
             <label className="label">Purchase GL *</label>
             <input
               name="purchase_gl"
               value={formData.purchase_gl}
               onChange={handleChange}
               className="input"
             />
           </div>
         </div>
         <div className="formColumn">
           <div className="formGroup">
             <label className="label">Purchase Rate</label>
             <input
               name="purchase_rate"
               value={formData.purchase_rate}
               onChange={handleChange}
               className="input"
               type="number"
             />
           </div>
           <div className="formGroup">
             <label className="label">Purchase Rate Factor</label>
             <input
               name="purchase_rate_factor"
               value={formData.purchase_rate_factor}
               onChange={handleChange}
               className="input"
               type="number"
             />
           </div>
         </div>
       </div>
     </section>




     {/* Bundle Detail Section */}
     <section className="section">
       <h3 className="sectionTitle">Bundle Detail</h3>
       <table className="bundleTable">
         <thead>
           <tr>
             <th>Action</th>
             <th>Item</th>
             <th>Qty</th>
             <th>Cost Allocation Percentage</th>
           </tr>
         </thead>
         <tbody>
           {bundleDetails.map((detail, index) => (
             <tr key={detail.id}>
               <td>
                 <button className="removeButton" onClick={() => removeLine(index)}>
                   ❌
                 </button>
               </td>
               <td>
                 <input
                   type="text"
                   name="item"
                   value={detail.item}
                   onChange={(e) => handleInputChange(index, e)}
                   className="input"
                 />
               </td>
               <td>
                 <input
                   type="number"
                   name="quantity"
                   value={detail.quantity}
                   onChange={(e) => handleInputChange(index, e)}
                   className="input"
                 />
               </td>
               <td>
                 <input
                   type="number"
                   name="costAllocationPercentage"
                   value={detail.costAllocationPercentage}
                   onChange={(e) => handleInputChange(index, e)}
                   className="input"
                 />
               </td>
             </tr>
           ))}
         </tbody>
       </table>
       <button className="addLineButton" onClick={addLine}>Add Lines</button>
     </section>




     {/* Action Buttons */}
     <div className="actionButtons">
     <button className="saveButton" onClick={handleSave}>Save</button>
       <button className="clearButton">Clear</button>
       <button className="printButton">Print</button>
       <button className="deleteButton">Delete</button>
     </div>


     {showModal && (
      <div className="modal">
        <div className="modalContent">
          <h2>Add New Category</h2>
          <form onSubmit={handleNewCategorySubmit}>
            <div className="formGroup">
              <label>Name</label>
              <input
                type="text"
                name="name"
                className="input"
                value={newCategory.name}
                onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
                required
              />
            </div>


            <div className="formGroup">
              <label>Code</label>
              <input
                type="text"
                name="code"
                className="input"
                value={newCategory.code}
                onChange={(e) => setNewCategory({ ...newCategory, code: e.target.value })}
               
              />
            </div>


            <div className="formActions">
              <button type="submit" className="color-button">Save</button>
              <button
                type="button"
                className="color-button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    )}




   </div>
 );
}




export default Bundle;














