import React, { useState } from 'react';

const Addbom = () => {
  const [formData, setFormData] = useState({
    name: '',
    finishItem: '',
    baseQty: 0,
    copyFromBOM: '',
    byProductItem: '',
    byProductQty: 0,
    overheadExpense: 0,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // Handle form submission logic
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Basic Information</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        {/* name */}
        <div style={styles.row}>
          <div style={styles.field}>
            <label style={styles.label}>name *</label>
            <input
              type="text"
              name="name"
              value={formData.description}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>
        </div>

        {/* Finish Item */}
        <div style={styles.row}>
          <div style={styles.field}>
            <label style={styles.label}>Finish Item *</label>
            <select
              name="finishItem"
              value={formData.finishItem}
              onChange={handleChange}
              required
              style={styles.input}
            >
              <option value="">Select...</option>
              {/* Add options dynamically */}
            </select>
          </div>
        </div>

        {/* Base Quantity */}
        <div style={styles.row}>
          <div style={styles.field}>
            <label style={styles.label}>Base Qty</label>
            <input
              type="number"
              name="baseQty"
              value={formData.baseQty}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
        </div>

        {/* Copy From BOM, By Product Item, By Product Qty, Overhead Expense */}
        <div style={styles.row}>
          <div style={styles.field}>
            <label style={styles.label}>Copy From BOM</label>
            <select
              name="copyFromBOM"
              value={formData.copyFromBOM}
              onChange={handleChange}
              style={styles.input}
            >
              <option value="">Select...</option>
              {/* Add options dynamically */}
            </select>
            <button type="button" style={styles.okButton}>
              OK
            </button>
          </div>

          <div style={styles.field}>
            <label style={styles.label}>By Product Item</label>
            <select
              name="byProductItem"
              value={formData.byProductItem}
              onChange={handleChange}
              style={styles.input}
            >
              <option value="">Select...</option>
              {/* Add options dynamically */}
            </select>
            <button type="button" style={styles.okButton}>
              OK
            </button>
          </div>
        </div>

        <div style={styles.row}>
          <div style={styles.field}>
            <label style={styles.label}>By Product Qty</label>
            <input
              type="number"
              name="byProductQty"
              value={formData.byProductQty}
              onChange={handleChange}
              style={styles.input}
            />
          </div>

          <div style={styles.field}>
            <label style={styles.label}>OverHead Expense For Base Qty</label>
            <input
              type="number"
              name="overheadExpense"
              value={formData.overheadExpense}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
        </div>

        <button type="submit" style={styles.submitButton}>
          Submit
        </button>
      </form>

      {/* Retain existing table */}
      <div style={styles.tableContainer}>
        <h2>Item Details</h2>
        <table style={styles.table}>
          <thead>
            <tr>
              <th>SNo</th>
              <th>Select Item</th>
              <th>Description</th>
              <th>Unit</th>
              <th>Quantity</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Net Rate</th>
              <th>Barcode</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((row, index) => (
              <tr key={index}>
                <td>{row}</td>
                <td><button style={styles.tableButton}>+</button></td>
                <td><button style={styles.tableButton}>x</button></td>
                <td><button style={styles.tableButton}>🔍</button></td>
                <td><input type="text" style={styles.input} /></td>
                <td><input type="text" style={styles.input} /></td>
                <td><input type="text" style={styles.input} /></td>
                <td><input type="text" style={styles.input} /></td>
                <td><input type="text" style={styles.input} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// CSS styles as JavaScript objects
const styles = {
  container: {
    padding: '20px',
  },
  heading: {
    fontSize: '24px',
    color: '#0046ad',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  row: {
    display: 'flex',
    gap: '10px',
  },
  field: {
    flex: 1,
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  okButton: {
    padding: '8px 12px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    alignSelf: 'flex-start',
  },
  tableContainer: {
    marginTop: '30px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableButton: {
    border: 'none',
    background: 'none',
    color: '#007bff',
    cursor: 'pointer',
  },
};

export default Addbom;
