

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const NotificationScreen = () => {
 const [notifications, setNotifications] = useState([]);
 const [error, setError] = useState(null);
 const navigate = useNavigate(); // Ensure useNavigate is correctly called


// Separate function to fetch notifications
const fetchNotifications = () => {
  const authToken = localStorage.getItem('authToken');

  // Fetch notifications
  fetch('https://api.myntask.com/notification/list', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      console.log('Fetched Notifications:', data);

      if (Array.isArray(data)) {
        setNotifications(data);
      } else if (Array.isArray(data.notifications)) {
        setNotifications(data.notifications);
      } else {
        setError('Unexpected response structure.');
      }
    })
    .catch((error) => {
      console.error('Error fetching notifications:', error);
      setError('Failed to fetch notifications.');
    });
};

// Call fetchNotifications in useEffect to run it on component mount
useEffect(() => {
  fetchNotifications();
}, []);

const handleNotificationClick = (notification) => {
  // Get token from local storage
  const token = localStorage.getItem('authToken'); // Replace 'authToken' with the actual key if needed

  // Send PUT request to update notification status
  fetch('https://api.myntask.com/notification/status_update', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`, // Add token to Authorization header
    },
    body: JSON.stringify({ notification_id: notification.notification_id }), // Send notification ID
  })
  .then((response) => {
    if (response.ok) {
      console.log('Notification status updated successfully.');
      
      // Fetch updated notifications list after successful status update
      fetchNotifications();
    } else {
      console.error('Failed to update notification status.');
    }
  })
  .catch((error) => {
    console.error('Error updating notification status:', error);
  });

  // Navigate to the appropriate page based on the notification type
  if (notification.type === 'expense') {
    console.log(`Navigating to ViewExpense with ID: ${notification.reference_id}`);
    navigate(`/view-expense/${notification.reference_id}`); // Navigate to expense detail page
  } else if (notification.type === 'task') {
    console.log(`Navigating to TaskDetail with ID: ${notification.reference_id}`);
    navigate(`/task-detail/${notification.reference_id}`); // Navigate to task detail page
  }

};




 return (
   <div style={{ width: '100vw', height: '100vh', padding: '20px', position: 'relative' }}>
     <h2 style={{ position: 'absolute', top: '20px', left: '20px', fontSize: '1.5rem', margin: 0 }}>
       Notifications
     </h2>


     {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}


     <div style={{ marginTop: '60px' }}>
       {notifications.length > 0 ? (
         <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
           {notifications.map((notification, index) => (
             <li
               key={index}
               style={{
                 borderBottom: '1px solid #ccc',
                 padding: '10px 5px',
                 display: 'flex',
                 alignItems: 'center',
                 width: '100%',
                 cursor: 'pointer',
                 transition: 'background-color 0.2s',
               }}
               onClick={() => handleNotificationClick(notification)}
             >
               <img
                 src={notification.user_image || 'default-avatar.png'}
                 alt="User Avatar"
                 style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
               />
               <div style={{ flex: 1 }}>
                 <p style={{ margin: '0', fontWeight: 'bold', fontSize: '0.9rem' }}>
                   {notification.heading}
                 </p>
                 <p style={{ margin: '0', color: '#555', fontSize: '0.8rem' }}>
                   {notification.message}
                 </p>
                 <p style={{ margin: '0', fontSize: '0.7rem', color: '#888' }}>
                   {notification.time_ago}
                 </p>
               </div>
             </li>
           ))}
         </ul>
       ) : (
         <p style={{ textAlign: 'center', fontSize: '0.9rem' }}>No notifications found.</p>
       )}
     </div>
   </div>
 );
};


export default NotificationScreen;
