import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SuperAdminList = () => {
  const [superAdmins, setSuperAdmins] = useState([]);
  const [showActionMenu, setShowActionMenu] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from the API
    fetch('http://api.myntask.com/superadmin/list')
      .then(response => response.json())
      .then(data => setSuperAdmins(data))
      .catch(error => console.error('There was an error fetching the super admin list!', error));
  }, []);

  const handleDelete = (userId) => {
    const updatedList = superAdmins.filter(admin => admin.user_id !== userId);
    setSuperAdmins(updatedList);
    setShowActionMenu(null);
  };

  const handleAddSuperAdmin = () => {
    navigate('/AddSuperAdminBill'); // Navigate to the AddSuperAdminBill screen
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <h1 style={styles.header}>Super Admin List</h1>
        <button style={styles.button} onClick={handleAddSuperAdmin}>Add Super Admin</button>
      </div>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>User ID</th>
            <th style={styles.th}>Username</th>
            <th style={styles.th}>Email</th>
            <th style={styles.th}>Status</th>
            <th style={styles.th}>Role Name</th>
            <th style={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {superAdmins.map(admin => (
            <tr key={admin.user_id}>
              <td style={styles.td}>{admin.user_id}</td>
              <td style={styles.td}>{admin.username}</td>
              <td style={styles.td}>{admin.email}</td>
              <td style={styles.td}>
                <span
                  style={{
                    ...styles.statusDot,
                    backgroundColor: admin.status === 'active' ? 'green' : 'red'
                  }}
                ></span>
                {admin.status}
              </td>
              <td style={styles.td}>{admin.rolename}</td>
              <td style={styles.td}>
                <div style={styles.actions}>
                  <span
                    style={styles.actionDots}
                    onClick={() => setShowActionMenu(admin.user_id)}
                  >&#x22EE;</span>
                  {showActionMenu === admin.user_id && (
                    <div style={styles.actionMenu}>
                      <button style={styles.actionButton} onClick={() => handleDelete(admin.user_id)}>Delete</button>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f0f0f0',
    height: '100vh',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  header: {
    fontSize: '2em',
    color: '#333',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1em',
    cursor: 'pointer',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: 'white',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #ccc',
    padding: '10px',
    backgroundColor: '#eee',
    textAlign: 'left',
  },
  td: {
    border: '1px solid #ccc',
    padding: '10px',
    textAlign: 'left',
  },
  statusDot: {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '8px',
  },
  actions: {
    position: 'relative',
  },
  actionDots: {
    cursor: 'pointer',
  },
  actionMenu: {
    position: 'absolute',
    top: '20px',
    right: '0',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
    zIndex: 1,
  },
  actionButton: {
    padding: '10px 20px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'left',
  },
};

export default SuperAdminList;
