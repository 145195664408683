import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const AddLeadPage = () => {
  const [newLead, setNewLead] = useState({
    name: '',
    email: '',
    source_id: '',
    status: 'new',
    lead_value :10,
    industry :'retail',
    company_name: '',
    website: '',
    mobile: '',
    office_phone_number: '',
    country: '',
    state: '',
    city: '',
    postal_code: '',
    address: '',
  });

  const [countries, setCountries] = useState([]);
  const [sources, setSources] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLead((prevLead) => ({ ...prevLead, [name]: value }));
  };

 

  const handleSaveLead = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No token found');
      }
  
      const response = await fetch('https://api.myntask.com/leads/add_lead', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newLead),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error('Failed to add lead');
      }
  
      const data = await response.json();
  
      // SweetAlert success popup without confirmation, auto-dismiss after 2 seconds
      Swal.fire({
        icon: 'success',
        title: 'Lead added successfully!',
        timer: 2000,  // Auto-dismiss after 2 seconds
        showConfirmButton: false,  // No confirmation button
      });
  
    } catch (err) {
      // SweetAlert error popup without confirmation, auto-dismiss after 2 seconds
      Swal.fire({
        icon: 'error',
        title: 'Failed to add lead.',
        text: 'Please try again.',
        timer: 2000,  // Auto-dismiss after 2 seconds
        showConfirmButton: false,  // No confirmation button
      });
    }
  };
  

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://api.myntask.com/home/country_list");
        if (!response.ok) {
          throw new Error("Failed to fetch country list");
        }
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    const fetchSources = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("https://api.myntask.com/leads/lead_source", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch Sources");
        }
        const data = await response.json();
        setSources(data);
      } catch (error) {
        console.error("Error fetching Sources:", error);
      }
    };

    fetchCountries();
    fetchSources();
  }, []);

  return (
    <div style={formStyles.body}>
      <div style={formStyles.addLeadForm}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Lead</h1>

        {/* Lead Information Section */}
        <div style={formStyles.section}>
          <div style={formStyles.sectionHeading}>Lead Information</div>
          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Source ID:</label>
            <select name="source_id" value={newLead.source_id} onChange={handleInputChange} style={formStyles.formInput}>
              <option value="">Select source</option>
              {sources.map(source => (
                <option key={source.id} value={source.id}>{source.type}</option>
              ))}
            </select>
          </div>

          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Status:</label>
            <select name="status" value={newLead.status} onChange={handleInputChange} style={formStyles.formInput}>
              <option value="new">New</option>
              <option value="contacted">Contacted</option>
              <option value="qualified">Qualified</option>
              <option value="lost">Lost</option>
              <option value="closed">Closed</option>
            </select>
          </div>
        </div>

        {/* Contact Details Section */}
        <div style={formStyles.section}>
          <div style={formStyles.sectionHeading}>Contact Details</div>
          <div style={formStyles.formRow}>
            <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>Name:</label>
              <input type="text" name="name" value={newLead.name} onChange={handleInputChange} style={formStyles.formInput} />
            </div>
            <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Email:</label>
            <input type="email" name="email" value={newLead.email} onChange={handleInputChange} style={formStyles.formInput} />
          </div>
          </div>
       
          <div style={formStyles.formRow}>
            <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>Mobile:</label>
              <input type="text" name="mobile" value={newLead.mobile} onChange={handleInputChange} style={formStyles.formInput} />
            </div>
            <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>Phone Number:</label>
              <input type="text" name="office_phone_number" value={newLead.office_phone_number} onChange={handleInputChange} style={formStyles.formInput} />
            </div>
          </div>
        </div>

        {/* Company Information Section */}
        <div style={formStyles.section}>
          <div style={formStyles.sectionHeading}>Company Information</div>
          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Company Name:</label>
            <input type="text" name="company_name" value={newLead.company_name} onChange={handleInputChange} style={formStyles.formInput} />
          </div>
          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Industry:</label>
            <input type="text" name="industry" value={newLead.industry} onChange={handleInputChange} style={formStyles.formInput} />
          </div>
          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Website:</label>
            <input type="text" name="website" value={newLead.website} onChange={handleInputChange} style={formStyles.formInput} />
          </div>
        </div>

        {/* Address Details Section */}
        <div style={formStyles.section}>
          <div style={formStyles.sectionHeading}>Address Details</div>
          <div style={formStyles.formGroup}>
            <label style={formStyles.formLabel}>Address:</label>
            <input type="text" name="address" value={newLead.address} onChange={handleInputChange} style={formStyles.formInput} />
          </div>
          <div style={formStyles.formRow}>
            <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>City:</label>
              <input type="text" name="city" value={newLead.city} onChange={handleInputChange} style={formStyles.formInput} />
            </div>
            <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>State:</label>
              <input type="text" name="state" value={newLead.state} onChange={handleInputChange} style={formStyles.formInput} />
            </div>
          </div>
          <div style={formStyles.formRow}>
            <div style={formStyles.formGroup}>
              <label style={formStyles.formLabel}>Postal Code:</label>
              <input type="text" name="postal_code" value={newLead.postal_code} onChange={handleInputChange} style={formStyles.formInput} />
            </div>
            <div style={formStyles.formGroup}>
  <label style={formStyles.formLabel}>Country:</label>
  <select
    name="country"
    value={newLead.country}  // newLead.country should contain the selected country name
    onChange={handleInputChange}
    style={formStyles.formInput}
  >
    <option value="">Select Country</option>
    {countries.map(country => (
      <option key={country.id} value={country.name}>{country.name}</option>
    ))}
  </select>
</div>


          </div>
        </div>

        {/* Action Buttons */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', gap: '20px' }}>
          <button
            onClick={handleSaveLead}
            className='color-button'
          >
            Save Lead
          </button>
          <button
            onClick={() => window.history.back()}
           className='color-button'
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const formStyles = {
  addLeadForm: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: '15px 40px',
    width: '100%',
    maxWidth: '850px',
    borderRadius: '15px',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
    margin: 'auto',
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  
  },
  formGroup: {
    marginBottom: '20px',
    width :'100%'
  },
  formRow: {
    display: 'flex',
    gap: '20px',
  },
  formLabel: {
    display: 'block',
    fontWeight: '500',
    marginBottom: '8px',
    color: '#333',
  },
  formInput: {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: 'inset 0 3px 6px rgba(0, 0, 0, 0.05)',
    transition: 'border 0.3s, box-shadow 0.3s',
  },

  formRow: {
    display: 'flex',
    gap: '20px',          // space between the two input fields
    justifyContent: 'space-between',  // distribute them evenly
    width: '100%',
  },
  

  section: {
    marginBottom: '20px',
  },
  sectionHeading: {
    fontSize: '20px',
    color: '#007bff',
    borderBottom: '2px solid #eee',
    paddingBottom: '5px',
    marginBottom: '15px',
  },
  submitButton: {
    background: 'linear-gradient(135deg, #6b73ff, #000dff)',
    color: 'white',
    padding: '15px 20px',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '10px',
    width: '100%',
    fontSize: '18px',
    transition: 'background 0.3s, transform 0.2s',
  },
};



export default AddLeadPage;
