// src/Components/NewScreen.js
import React, { useEffect, useState } from 'react';

const FinanceSetting = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.myntask.com/setting/finance_setting');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', padding: '20px' }}>
      <h1>Welcome to the New Screen</h1>
      <p>This is a simple screen component.</p>
      {data && (
        <div style={{ maxWidth: '600px', width: '100%' }}>
          <h2>Finance Settings</h2>
          <div style={{ marginBottom: '20px' }}>
            <h3>Logo:</h3>
            <img src={data.logo} alt="Logo" style={{ maxWidth: '100px' }} />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h3>Authorized Signatory:</h3>
            <p>{data.show_authorised_signatory ? data.authorised_signatory || 'Not Provided' : 'Not Provided'}</p>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h3>Invoice Templates:</h3>
            {[data.invoice_1, data.invoice_2, data.invoice_3, data.invoice_4, data.invoice_5].map((invoice, index) => (
              invoice ? <img key={index} src={invoice} alt={`Invoice ${index + 1}`} style={{ maxWidth: '100px', margin: '5px' }} /> : null
            ))}
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h3>Billing Name:</h3>
            <p>{data.billing_name || 'Not Provided'}</p>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h3>Billing Address:</h3>
            <p>{data.billing_address || 'Not Provided'}</p>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h3>Tax Name:</h3>
            <p>{data.tax_name || 'Not Provided'}</p>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h3>Tax ID:</h3>
            <p>{data.tax_id || 'Not Provided'}</p>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h3>Terms and Conditions:</h3>
            <p>{data.terms_and_condition || 'Not Provided'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FinanceSetting;
