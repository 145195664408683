import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Holiday.css';
import { Modal, Button, IconButton } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';

function HolidayCalendar() {
  const [holidays, setHolidays] = useState([]);
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false); // Delete modal state
  const navigate = useNavigate(); 

  // Function to fetch holidays from the API
  const fetchHolidays = async (month) => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    const apiUrl = `https://api.myntask.com/holiday/list?month=${month}`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const data = await response.json();
      const formattedHolidays = data.map(holiday => ({
        id: holiday.id,
        title: holiday.occassion,
        date: holiday.date.split('-').reverse().join('-'),
        allDay: true,
      }));

      setHolidays(formattedHolidays);
    } catch (error) {
      console.error('Failed to fetch holidays:', error);
    }
  };

  useEffect(() => {
    fetchHolidays(new Date().getMonth() + 1);
  }, []);

  const handleOpenDeleteModal = () => {
    setOpenDeleteCategoryModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteCategoryModal(false);
  };

  const handleDeleteHoliday = async (holidayId) => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      const apiUrl = `https://api.myntask.com/holiday/delete/${holidayId}`;
      try {
        const response = await fetch(apiUrl, {
          method: 'DELETE',
          headers: { 'Authorization': `Bearer ${token}` }
        });

        if (response.ok) {
          Swal.fire('Deleted!', 'Your holiday has been deleted.', 'success');
          fetchHolidays(new Date().getMonth() + 1);
        } else {
          Swal.fire('Error!', 'There was an error deleting your holiday.', 'error');
        }
      } catch (error) {
        Swal.fire('Error!', 'There was an error deleting your holiday.', 'error');
      }
      handleCloseDeleteModal();
    }
  };

  const handleMonthChange = (arg) => {
    fetchHolidays(arg.view.currentStart.getMonth() + 1);
  };

  const handleShowAddHoliday = () => navigate('/add-holiday');

  if (!holidays.length) return <div>Loading...</div>;

  return (
    <div className="main-container">
      <ToastContainer />
      <div className="top-controls">
        <h1 className='title'>Holiday Calendar
        <div className="underline"></div>
        </h1>

        <div className="buttons-row">
          <button className="color-button" onClick={handleShowAddHoliday}>Add Holiday</button>
          <button className="color-button" onClick={handleOpenDeleteModal}>Delete Holiday</button>
        </div>
      </div>

      <div className="holiday">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={holidays}
          datesSet={handleMonthChange}
          height="parent"
          contentHeight="auto"
        />
      </div>

      {/* Delete Holiday Modal */}
      <Modal open={openDeleteCategoryModal} onClose={handleCloseDeleteModal}>
  <div style={{
      padding: '20px',
      maxWidth: '400px', // Set a max width for the modal
      margin: 'auto',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)' // Center the modal
    }}>
    <h2 style={{ margin: '0 0 10px', fontSize: '16px' }}>Delete Holiday</h2>
    {holidays.map((holiday) => (
      <div key={holiday.id} style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
        <span style={{ marginRight: '10px' }}>{holiday.title}</span>
        <IconButton onClick={() => handleDeleteHoliday(holiday.id)} color="secondary">
          <DeleteIcon />
        </IconButton>
      </div>
    ))}
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
      <Button onClick={handleCloseDeleteModal}>Close</Button>
    </div>
  </div>
</Modal>

    </div>
  );
}

export default HolidayCalendar;
