// src/ProjectRoadmap.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Grid,
} from '@mui/material';

const ProjectRoadmap = () => {
  const [milestones, setMilestones] = useState([]);
  const [newMilestone, setNewMilestone] = useState('');

  const handleAddMilestone = () => {
    if (newMilestone.trim() !== '') {
      setMilestones([...milestones, newMilestone]);
      setNewMilestone('');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Project Roadmap
      </Typography>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Add New Milestone
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              label="Milestone Name"
              value={newMilestone}
              onChange={(e) => setNewMilestone(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: { xs: 2, md: 0 } }}
              onClick={handleAddMilestone}
            >
              Add Milestone
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Roadmap
        </Typography>
        <Stepper activeStep={milestones.length} orientation="vertical">
          {milestones.map((milestone, index) => (
            <Step key={index}>
              <StepLabel>{milestone}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>
    </Box>
  );
};

export default ProjectRoadmap;
