import React from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook

function VendorDetail() {
  const { id } = useParams(); // Get the 'id' parameter from the URL

  return (
    <div>
      <h2>Vendor Details</h2>
      <p>Vendor ID: {id}</p> {/* Display the vendor's ID */}
    </div>
  );
}

export default VendorDetail;
