import React, { useState, useEffect } from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dailyImage from '../assets/daily.jpg';








const DailyUpdate = ({ open, handleClose, userProfile }) => {
const [projects, setProjects] = useState([{ projectName: '' }]);
const [projectList, setProjectList] = useState([]);
const [summary, setSummary] = useState('');
const [challenges, setChallenges] = useState('');
const [mood, setMood] = useState('Happy');
const [images, setImages] = useState([]);
const [alreadySubmitted, setAlreadySubmitted] = useState(false);








// Fetch project list and check if the user has already submitted data today
useEffect(() => {
  if (open) {
    const token = localStorage.getItem('authToken');
    const today = new Date().toLocaleDateString();
    const lastSubmitDate = localStorage.getItem('lastDailyUpdate');








    if (lastSubmitDate === today) {
      setAlreadySubmitted(true);
    } else {
      setAlreadySubmitted(false);
    }








    if (token) {
      fetch('https://api.myntask.com/task/project_list', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => setProjectList(data || []))
        .catch(error => console.error('Error fetching project list:', error));
    } else {
      console.error('No auth token found.');
    }
  }
}, [open]);








const handleProjectChange = (index, value) => {
  const updatedProjects = [...projects];
  updatedProjects[index].projectName = value;
  setProjects(updatedProjects);
};








const addAnotherProject = () => {
  setProjects([...projects, { projectName: '', summary: '', tomorrowWork: '' }]);
};








const handleImageChange = (e) => {
  setImages([...e.target.files]);
};








const handleSubmit = async () => {
  if (alreadySubmitted) {
    toast.error("Your daily update data is already updated.");
    return;
  }








  if (projects.length === 0 || projects.some(project => !project.projectName)) {
    toast.error("Please fill in all required fields.");
    return;
  }








  const token = localStorage.getItem('authToken');
  if (!token) {
    toast.error("Authentication token is missing.");
    return;
  }








  const formData = new FormData();
  const projectIds = [];
  const summaries = [];
  const tomorrowWorks = [];








  projects.forEach(project => {
    if (project.projectName) {
      projectIds.push(project.projectName);
      summaries.push(project.summary);
      tomorrowWorks.push(project.tomorrowWork);
    }
  });








  projectIds.forEach(projectId => formData.append('project[]', projectId));
  summaries.forEach(summary => formData.append('summary[]', summary));
  tomorrowWorks.forEach(task => formData.append('tomorrow_work[]', task));
  formData.append('mood', mood.toLowerCase());
  formData.append('challenges', challenges);








  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        formData.append('latitude', latitude);
        formData.append('longitude', longitude);








        images.forEach(image => {
          formData.append('reports', image);
        });








        try {
          const response = await fetch('https://api.myntask.com/home/daily_report', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });








          if (!response.ok) {
            throw new Error(`Network response was not ok. Status code: ${response.status}`);
          }








          toast.success("Your daily update is updated successfully");
          localStorage.setItem('lastDailyUpdate', new Date().toLocaleDateString());
          handleClose();
        } catch (error) {
          console.error('Error submitting daily update:', error);
          toast.error("Failed to update daily report. Please try again.");
        }
      },
      (error) => {
        console.error('Error getting user location:', error);
        toast.error('Error getting user location');
      }
    );
  } else {
    toast.error('Geolocation is not supported by this browser.');
  }
};








return (
  <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
    <div style={styles.dialogHeader}>
      Submit Daily Report
    </div>








    <div style={styles.container}>
      <div style={styles.formContainer}>
        {projects.map((project, index) => (
          <div key={index} style={styles.projectSection}>
            <label>
              Project
              <select
                value={project.projectName}
                onChange={(e) => handleProjectChange(index, e.target.value)}
                style={styles.dailyinput}
              >
                <option value="" disabled>Select a project</option>
                {projectList.map(projectItem => (
                  <option key={projectItem.id} value={projectItem.id}>
                    {projectItem.project_name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Summary
              <textarea
                value={project.summary}
                onChange={(e) => {
                  const updatedProjects = [...projects];
                  updatedProjects[index].summary = e.target.value;
                  setProjects(updatedProjects);
                }}
                placeholder="Describe your main activities..."
                rows="1"
                style={styles.dailyinput}
              />
            </label>
            <label>
              Tomorrow's Work
              <textarea
                value={project.tomorrowWork}
                onChange={(e) => {
                  const updatedProjects = [...projects];
                  updatedProjects[index].tomorrowWork = e.target.value;
                  setProjects(updatedProjects);
                }}
                placeholder="What you plan to work on tomorrow..."
                rows="1"
                style={styles.dailyinput}
              />
            </label>
          </div>
        ))}
        <Button variant="outlined" onClick={addAnotherProject} style={styles.addButton}>
          ADD ANOTHER PROJECT
        </Button>
        <label>
          Challenges
          <textarea
            value={challenges}
            onChange={(e) => setChallenges(e.target.value)}
            placeholder="Blockers or challenges faced today..."
            rows="1"
            style={styles.dailyinput}
          />
        </label>
        <label>
          Mood
          <select
            value={mood}
            onChange={(e) => setMood(e.target.value)}
            style={styles.dailyinput}
          >
            <option value="happy">😊 Happy</option>
            <option value="neutral">😐 Neutral</option>
            <option value="unhappy">😞 Unhappy</option>
          </select>
        </label>
        <Button variant="contained" component="label" style={styles.uploadButton}>
          UPLOAD IMAGES
          <input type="file" hidden multiple accept="image/*" onChange={handleImageChange} />
        </Button>
        <Typography variant="caption" style={{ marginTop: '4px', fontSize: '0.75rem' }}>
          {images.length > 0 ? `${images.length} image(s) selected` : "No images selected"}
        </Typography>
      </div>








      <div style={styles.helpSection}>
        <Typography variant="h6" style={styles.helpHeader}>
          Need Help?
        </Typography>
        <ul style={styles.helpList}>
          <li><strong>Project Name:</strong> Select the project you're working on.</li>
          <li><strong>Today's Work Update:</strong> Provide bullet-point updates for tasks completed today.</li>
          <li><strong>Plan for Tomorrow:</strong> List tasks planned for tomorrow in bullet points.</li>
          <li><strong>File Upload:</strong> Attach relevant files or images for each project.</li>
          <li><strong>Mood:</strong> Select your current mood from the dropdown.</li>
        </ul>
        <img
          src={dailyImage}
          alt="Daily Report Help"
          style={styles.helpImage}
        />
      </div>
    </div>








    <div style={styles.actionContainer}>
      <Button
        variant="contained"
        onClick={handleSubmit}
        style={styles.saveButton}
      >
        SAVE
      </Button>
      <Button
        variant="outlined"
        onClick={handleClose}
        style={styles.cancelButton}
      >
        CANCEL
      </Button>
    </div>
  </Dialog>
);
};








export default DailyUpdate;




const styles = {
 dialogHeader: {
   textAlign: 'center',
   padding: '8px',
   fontWeight: 'bold',
   fontSize: '1.1rem',
   borderRadius: '10px 10px 0 0',
   backgroundColor: '#ffffff',
 },
 container: {
   display: 'flex',
   padding: '20px 40px',
   flexWrap: 'wrap',
   backgroundColor: '#ffffff',
   flexDirection: 'row',
   overflowY: 'auto', // Make the container scrollable
   maxHeight: '70vh', // Limit height on smaller screens
   '@media (max-width: 600px)': {
     padding: '10px',
     flexDirection: 'column',
   },
 },
 formContainer: {
   flex: 2,
   display: 'flex',
   flexDirection: 'column',
   gap: '8px',
   '@media (max-width: 600px)': {
     width: '100%',
   },
 },
 projectSection: {
   display: 'flex',
   flexDirection: 'column',
   gap: '4px',
 },
 dailyinput: {
   width: '100%',
   padding: '6px',
   borderRadius: '6px',
   fontSize: '0.8rem',
 },
 addButton: {
   alignSelf: 'center',
   padding: '4px 8px',
   fontWeight: 'bold',
   fontSize: '0.8rem',
   '@media (max-width: 600px)': {
     width: '100%',
   },
 },
 uploadButton: {
   marginTop: '8px',
   fontWeight: '600',
   fontSize: '0.8rem',
   '@media (max-width: 600px)': {
     width: '100%',
   },
 },
 helpSection: {
   flex: 1,
   padding: '8px',
   backgroundColor: '#ffffff',
   borderRadius: '8px',
   marginLeft: '8px',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   alignItems: 'center',
   '@media (max-width: 600px)': {
     marginLeft: '0',
     marginTop: '16px',
     width: '100%',
   },
 },
 helpHeader: {
   fontWeight: 'bold',
   color: '#007BFF',
   fontSize: '1rem',
 },
 helpList: {
   paddingLeft: '16px',
   fontSize: '0.9rem',
   marginBottom: '16px',
   lineHeight: '1.5',
 },
 helpImage: {
   marginTop: '8px',
   width: '80%',
   height: 'auto',
   borderRadius: '6px',
 },
 actionContainer: {
   display: 'flex',
   justifyContent: 'center',
   padding: '16px 0',
   gap: '16px',
   backgroundColor: '#ffffff',
   position: 'sticky', // Keeps buttons visible at the bottom
   bottom: '0',
   '@media (max-width: 600px)': {
     flexDirection: 'column',
     gap: '8px',
     padding: '8px',
   },
 },
 saveButton: {
   fontWeight: 'bold',
   minWidth: '120px',
   fontSize: '0.9rem',
   padding: '8px 16px',
   borderRadius: '6px',
   backgroundColor: '#007BFF',
   color: '#fff',
   '@media (max-width: 600px)': {
     width: '100%',
   },
 },
 cancelButton: {
   fontWeight: 'bold',
   minWidth: '120px',
   fontSize: '0.9rem',
   padding: '8px 16px',
   borderRadius: '6px',
   backgroundColor: 'transparent',
   border: '1px solid #007BFF',
   color: '#007BFF',
   '@media (max-width: 600px)': {
     width: '100%',
   },
 },
};


























