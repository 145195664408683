

 import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './../Components/Main.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Swal from 'sweetalert2'; // Import SweetAlert
import { BiSortAlt2 } from 'react-icons/bi';






const Leave = () => {
  const [leaves, setLeaves] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [actionMenuVisible, setActionMenuVisible] = useState(null); // Control action menu per leaveId
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedLeaves, setSelectedLeaves] = useState({});
  const [action, setAction] = useState('');
  const [permissions, setPermissions] = useState(null); // Store permissions from API
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [sortField, setSortField] = useState(null);
const [sortDirection, setSortDirection] = useState('asc'); // 'asc' for ascending, 'desc' for descending
const actionMenuRef = useRef(null);





const fetchLeaveData = async (startDate, endDate) => {
  const token = localStorage.getItem('authToken');
  const apiUrl = `https://api.myntask.com/leave/list?start_date=${startDate}&end_date=${endDate}`;




  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });




    if (!response.ok) {
      throw new Error('Network response was not ok');
    }




    const data = await response.json();
    console.log("API Response: ", data); // Log the response data
    setLeaves(data.data); // Set the leaves from response data
    setFilteredLeaves(data.data); // Set filtered leaves as well




    // Check and log permissions
    console.log("Permissions: ", data.leave_permission); // Log the permissions object
    setPermissions(data.leave_permission || {}); // Ensure permissions is an object




  } catch (error) {
    console.error('Error fetching leave data:', error);
    setError('Failed to fetch leave data.');
  }
};




  useEffect(() => {
    const lowercasedTerm = searchTerm.toLowerCase();
    const filtered = leaves.filter(
      (leave) =>
        leave.name.toLowerCase().includes(lowercasedTerm) ||
        leave.designation.toLowerCase().includes(lowercasedTerm)
    );
    setFilteredLeaves(filtered);
  }, [searchTerm, leaves]);


  // Sorting function
  const handleSort = (field) => {
    const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newDirection);
 
    const sortedLeaves = [...filteredLeaves].sort((a, b) => {
      if (newDirection === 'asc') {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });
 
    setFilteredLeaves(sortedLeaves);
  };




  const navigate = useNavigate();




  const handleCheckboxChange = (leaveId) => {
    setSelectedLeaves((prevState) => ({
      ...prevState,
      [leaveId]: !prevState[leaveId], // Toggle the selected state
    }));
 
    setSelectedLeaveId(leaveId); // Set the selected leave ID
    setDropdownVisible(true); // Show the dropdown
  };


  useEffect(() => {
    fetchLeaveData(startDate, endDate);
  }, [startDate, endDate]);




  const toggleActionMenu = (leaveId) => {
    if (actionMenuVisible === leaveId) {
      setActionMenuVisible(null); // Close the menu if it's already open for the same row
    } else {
      setActionMenuVisible(leaveId); // Set the current leaveId to display the action menu
    }
    setSelectedLeaveId(leaveId); // Set the selected leave ID
  };




  const handleDropdownClose = () => {
    setActionMenuVisible(null); // Close the action menu
    setSelectedLeaveId(null);
    setAction(''); // Reset the action after handling it
  };



  const handleDelete = async (leaveId) => {
    const token = localStorage.getItem('authToken');
  
    // Show confirmation dialog using SweetAlert
    const result = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });
  
    // If the user confirmed the deletion
    if (result.isConfirmed) {
      try {
        const response = await fetch(`https://api.myntask.com/leave/leave_delete`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ leave_id: leaveId }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to delete leave');
        }
  
        // Remove the deleted leave from the state
        setLeaves(leaves.filter((leave) => leave.leave_id !== leaveId));
        setFilteredLeaves(filteredLeaves.filter((leave) => leave.leave_id !== leaveId));
  
        // Show success message using SweetAlert
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'Leave deleted successfully!',
          timer: 2000, // Auto-close after 2 seconds
          showConfirmButton: false, // Hide the confirm button
        });
      } catch (error) {
        console.error('Error deleting leave:', error);
        
        // Show error message using SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to delete leave. Please try again.',
        });
      }
    }
  };
  

  const updateLeaveStatus = async (leaveId, status) => {
    const token = localStorage.getItem('authToken');
    
    try {
      const response = await fetch(
        'https://api.myntask.com/leave/leave_status_update',
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            leave_id: leaveId,
            status_name: status,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error(`Failed to update leave status to ${status}`);
      }
  
      // Update the leaves state
      const updatedLeaves = leaves.map((leave) =>
        leave.leave_id === leaveId ? { ...leave, status: status } : leave
      );
  
      setLeaves(updatedLeaves);
      setFilteredLeaves(updatedLeaves);
  
      // Show success message using SweetAlert
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: `Leave status updated to ${status}`,
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false, // Hide the confirm button
      });
      
    } catch (error) {
      console.error(`Error updating leave status to ${status}:`, error);
  
      // Show error message using SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Failed to update leave status to ${status}.`,
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false, // Hide the confirm button
      });
  
      setError(`Failed to update leave status to ${status}.`);
    }
  };


  const handleApprove = () => {
    updateLeaveStatus(selectedLeaveId, 'approved');
    handleDropdownClose();
  };




  const handleReject = () => {
    updateLeaveStatus(selectedLeaveId, 'rejected');
    handleDropdownClose();
    console.log('updating status to rejected of ',selectedLeaveId);
  };




  const handleAction = (action) => {
    switch (action) {
      case 'Edit':
        if (selectedLeaveId) {
          navigate(`/edit-leave/${selectedLeaveId}`); // Use the correct ID for navigation
        } else {
          alert('Please select a leave to edit.');
        }
        break;
      case 'Delete':
        handleDelete(selectedLeaveId); // Call delete function with ID
        break;
      case 'Approve':
        handleApprove(); // Approve function
        break;
      case 'Rejected':
        handleReject(); // Reject function
        break;
      default:
        break;
    }
     setActionMenuVisible(null); // Close the menu after action
  };


  // Close action menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
        setActionMenuVisible(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionMenuRef]);



  const handleClearFilters = () => {
    setSearchTerm('');
    setStartDate('');
    setEndDate('');
    fetchLeaveData('', '');
  };




  return (
    <div className="main-container">
   
    <div className="top-controls">
      <h1 className='title'>Leave
        <div className="underline"></div>
      </h1>
 
      <div className="buttons-row">
        {dropdownVisible && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <select onChange={(e) => setAction(e.target.value)} value={action} className="color-button">
              <option value="">Select Action</option>
              {permissions?.edit_leave && <option value="Edit">Edit</option>}
              {permissions?.delete_leave && <option value="Delete">Delete</option>}
              {permissions?.approve_or_reject_leaves && <option value="Approve">Approve</option>}
              {permissions?.approve_or_reject_leaves && <option value="Rejected">Reject</option>}
            </select>
            <button onClick={() => handleAction(action)} className="color-button">
              Apply
            </button>
          </div>
        )}
        {permissions?.add_leave && (
          <button className="color-button" onClick={() => navigate('/new-leave')}>
            New Leave
          </button>
        )}
      </div>
    </div>
 
    <div className="top-controls">
      <div className="filters-and-search">
        <button className="border-button" onClick={handleClearFilters}>
          Clear Filters
        </button>
        <input
          id="start-date"
          className="border-button"
          style={{ width: '150px' }}
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          id="end-date"
          className="border-button"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          style={{ width: '150px' }}
        />
        <input
          id="search"
          className="searchbar"
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search"
        />
      </div>
    </div>
 
    {error && <p className="error-message">{error}</p>}
   
      <table >
        <thead>
        <tr>
      <th>Select</th>
      <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
        Name <BiSortAlt2 />
        {sortField === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
      </th>
      <th onClick={() => handleSort('leave_date')} style={{ cursor: 'pointer' }}>
        Leave Date <BiSortAlt2 />
        {sortField === 'leave_date' && (sortDirection === 'asc' ? '▲' : '▼')}
      </th>
      <th onClick={() => handleSort('duration')} style={{ cursor: 'pointer' }}>
        Duration <BiSortAlt2 />
        {sortField === 'duration' && (sortDirection === 'asc' ? '▲' : '▼')}
      </th>
      <th onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
        Leave Status <BiSortAlt2 />
        {sortField === 'status' && (sortDirection === 'asc' ? '▲' : '▼')}
      </th>
      <th onClick={() => handleSort('type_name')} style={{ cursor: 'pointer' }}>
        Leave Type <BiSortAlt2 />
        {sortField === 'type_name' && (sortDirection === 'asc' ? '▲' : '▼')}
      </th>
      <th onClick={() => handleSort('paid')} style={{ cursor: 'pointer' }}>
        Paid <BiSortAlt2 />
        {sortField === 'paid' && (sortDirection === 'asc' ? '▲' : '▼')}
      </th>
      <th>Actions</th>
    </tr>
        </thead>
        <tbody>
          {filteredLeaves.map((leave, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={!!selectedLeaves[leave.leave_id]}
                  onChange={() => handleCheckboxChange(leave.leave_id)}
                />
              </td>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={leave.image}
                    alt={leave.name}
                    className="image"
                    style={{ marginRight: '10px' }}
                  />
                  <div>
                  <span style={{fontWeight:'bold'}}>
  {leave.name.charAt(0).toUpperCase() + leave.name.slice(1).toLowerCase()}
</span>
<p style={{margin:0}}>
  {leave.designation.charAt(0).toUpperCase() + leave.designation.slice(1).toLowerCase()}
</p>

                  </div>
                 
                </div>
              </td>
              <td>{new Date(leave.leave_date).toLocaleDateString()}</td>
              <td>{leave.duration.charAt(0).toUpperCase() + leave.duration.slice(1).toLowerCase()}
              </td>
              <td>
  <span className="status-container">
    <span
      className="status-dot"
      style={{
        backgroundColor: leave.status.toLowerCase() === 'approved'
          ? 'green'    // Color for approved
          : leave.status.toLowerCase() === 'rejected'
          ? 'red'      // Color for rejected
          : leave.status.toLowerCase() === 'pending'
          ? 'yellow'   // Color for pending
          : 'gray'     // Default color for unknown status
      }}
    ></span>
    {leave.status.charAt(0).toUpperCase() + leave.status.slice(1)}
  </span>
</td>




              <td>{leave.type_name.charAt(0).toUpperCase() + leave.type_name.slice(1).toLowerCase()}
              </td>
              <td>{leave.paid ? 'Yes' : 'No'}</td>
              <td>
                <div className="action-container" style={{ position: 'relative' }}>
                  <div
                    className="action-dots"
                    onClick={() => toggleActionMenu(leave.leave_id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <MoreVertIcon />
                  </div>
                  {actionMenuVisible === leave.leave_id && (
                    <div className="action-menu" ref={actionMenuRef}>
                      {permissions?.edit_leave && (
                        <div className="action-item" onClick={() => handleAction('Edit')}>
                          Edit
                        </div>
                      )}
                      {permissions?.delete_leave && (
                        <div className="action-item" onClick={() => handleAction('Delete')}>
                          Delete
                        </div>
                      )}
                      {permissions?.approve_or_reject_leaves && (
                        <>
                          <div className="action-item" onClick={() => handleAction('Approve')}>
                            Approve
                          </div>
                          <div className="action-item" onClick={() => handleAction('Rejected')}>
                            Reject
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
 
  </div>
  );
};




export default Leave;












    
 
 
