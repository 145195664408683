import React, { useEffect, useState } from 'react';
import AddDealModal from '../Components/AddDealModal';

const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchDealsList = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }

      try {
        const response = await fetch('https://api.myntask.com/leads/deals_list', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch deals');
        }

        const data = await response.json();
        setDeals(data);
      } catch (err) {
        console.error('Error fetching deals:', err);
        setError('Failed to load deals.');
      }
    };

    fetchDealsList();
  }, []);

  const handleAddDealClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveDeal = (newDeal) => {
    setDeals((prevDeals) => [...prevDeals, newDeal]);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredDeals = deals.filter((deal) => {
    return Object.keys(deal).some((key) =>
      String(deal[key]).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div style={{ padding: '20px', backgroundColor: 'var(--background-color)' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <h3 style={{ textAlign: 'left', color: 'var(--text-color)', margin: 0 }}>Deals</h3>
        <div>
          <button
            style={{
              padding: '10px 16px',
              fontSize: '14px',
              color: '#ffffff',
              backgroundColor: '#007bff', // Default blue color
              borderRadius: '4px',
              border: 'none',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
            onClick={handleAddDealClick}
          >
            Add Deal
          </button>
        </div>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <input
          type="text"
          placeholder="Search by name or email..."
          value={searchQuery}
          onChange={handleSearch}
          style={{
            width: '100%',
            padding: '8px 10px',
            fontSize: '14px',
            border: '1px solid var(--border-color)',
            borderRadius: '4px',
            backgroundColor: 'var(--secondary-color)',
            color: 'var(--text-color)',
          }}
        />
      </div>
      {error && <p style={{ color: 'var(--accent-color-3)' }}>{error}</p>}
      {filteredDeals.length > 0 ? (
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            backgroundColor: 'var(--card-background)',
            boxShadow: '0 2px 4px var(--box-shadow-color)',
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Name</th>
              <th style={tableHeaderStyle}>Company Name</th>
              <th style={tableHeaderStyle}>Client Name</th>
              <th style={tableHeaderStyle}>Client Email</th>
              <th style={tableHeaderStyle}>Mobile</th>
              <th style={tableHeaderStyle}>Value</th>
              <th style={tableHeaderStyle}>Close Date</th>
              <th style={tableHeaderStyle}>Next Follow Up Date</th>
              <th style={tableHeaderStyle}>Stage</th>
              <th style={tableHeaderStyle}>Agent</th>
              <th style={tableHeaderStyle}>Deal Watcher</th>
              <th style={tableHeaderStyle}>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredDeals.map((deal, index) => (
              <tr key={deal.id} style={index % 2 === 0 ? tableRowStyleEven : tableRowStyleOdd}>
                <td style={tableCellStyle}>{deal.name}</td>
                <td style={tableCellStyle}>{deal.company_name}</td>
                <td style={tableCellStyle}>{deal.client_name}</td>
                <td style={tableCellStyle}>{deal.client_email}</td>
                <td style={tableCellStyle}>{deal.mobile}</td>
                <td style={tableCellStyle}>{deal.value}</td>
                <td style={tableCellStyle}>{deal.close_date}</td>
                <td style={tableCellStyle}>{deal.next_follow_up_date}</td>
                <td style={tableCellStyle}>{deal.stage}</td>
                <td style={tableCellStyle}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img
                      src={deal.agentImage}
                      alt={deal.agent}
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        backgroundColor: 'var(--icon-background-color)',
                      }}
                    />
                    <span>{deal.agent || 'N/A'}</span>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img
                      src={deal.dealWatcherImage}
                      alt={deal.deal_watcher}
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        backgroundColor: 'var(--icon-background-color)',
                      }}
                    />
                    <span>{deal.deal_watcher || 'N/A'}</span>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <button
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        cursor: 'pointer',
                      }}
                    >
                      &#8942; {/* Three-dot menu */}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No deals found for this lead.</p>
      )}

      {/* Use the AddDealModal component */}
      <AddDealModal isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleSaveDeal} />
    </div>
  );
};

export default Deals;

const tableHeaderStyle = {
  padding: '8px',
  border: '1px solid var(--border-color)',
  backgroundColor: '#007bff', // Default blue color
  color: '#ffffff',
  textAlign: 'left',
  fontWeight: 'bold',
};

const tableRowStyleEven = {
  backgroundColor: '#ffffff', // White background for even rows
  borderBottom: '1px solid var(--border-color)',
};

const tableRowStyleOdd = {
  backgroundColor: '#f7f7f7', // Light gray background for odd rows
  borderBottom: '1px solid var(--border-color)',
};

const tableCellStyle = {
  padding: '8px',
  border: '1px solid var(--border-color)',
};
