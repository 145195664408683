// src/Timesheet.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const Timesheet = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [newTimesheet, setNewTimesheet] = useState({
    date: '',
    hoursWorked: '',
    project: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTimesheet((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (newTimesheet.date && newTimesheet.hoursWorked && newTimesheet.project) {
      setTimesheets((prev) => [...prev, newTimesheet]);
      setNewTimesheet({
        date: '',
        hoursWorked: '',
        project: '',
        description: '',
      });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Timesheet
      </Typography>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Add Timesheet Entry
        </Typography>
        <TextField
          fullWidth
          label="Date"
          name="date"
          type="date"
          value={newTimesheet.date}
          onChange={handleChange}
          sx={{ mb: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          label="Hours Worked"
          name="hoursWorked"
          type="number"
          value={newTimesheet.hoursWorked}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Project"
          name="project"
          value={newTimesheet.project}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={newTimesheet.description}
          onChange={handleChange}
          multiline
          rows={3}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Paper>

      <Typography variant="h6" gutterBottom>
        Timesheet Entries
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Hours Worked</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timesheets.map((timesheet, index) => (
              <TableRow key={index}>
                <TableCell>{timesheet.date}</TableCell>
                <TableCell>{timesheet.hoursWorked}</TableCell>
                <TableCell>{timesheet.project}</TableCell>
                <TableCell>{timesheet.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Timesheet;
